<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GDropdown } from "@/components";

const { t } = useI18n();
const options = [
    { label: t("reports.tbl_col_course_started"), value: 101 },
    {
      label: t("courseMilestonesReport.course_completed"),
      value: 201,
    },
    {
      label: t("courseMilestonesReport.course_test_passed"),
      value: 301,
    },
  ] 

const selectedMilestone = defineModel<number>({
  default: null,
  required: true,
});
</script>
<template>
  <GDropdown
    :options="options"
    :value="selectedMilestone"
    :label="t('courseMilestonesReport.milestone')"
    :hasDefault="true"
    @change="selectedMilestone = $event as number"
  />
</template>
