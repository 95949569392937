import { nextTick } from "vue";
import { createI18n, type Composer, type I18n } from "vue-i18n";

import sources from "@/i18n/sources";

import {
  downloadTranslations,
  getStartingLocale,
  supportedLocales,
} from "@/utils/i18n";
const locale = getStartingLocale();
const fallbackLocale = import.meta.env.VITE_I18N_FALLBACK_LOCALE || locale;

interface TranslationCache {
  locale: string;
  messages: Record<string, string>;
}

let i18n: I18n;
let cache: TranslationCache;

function setupI18n(
  options = {
    legacy: false,
    locale,
    fallbackLocale,
    availableLocales: Object.keys(supportedLocales),
    messages: {
      [locale]: sources,
    },
  }
) {
  const newI18n = createI18n(options);
  setI18nLanguage(newI18n, options.locale);

  i18n = newI18n;
  return i18n;
}

function setI18nLanguage(i18n: I18n, languageCode: string) {
  (i18n.global as Composer).locale.value = languageCode;
  document.querySelector("html")?.setAttribute("lang", languageCode);

  return languageCode;
}

function isCached(languageCode: string) {
  return cache && cache.locale === languageCode;
}

function setCache(locale: string, messages: Record<string, string>) {
  cache = { locale, messages };
}

/**
 * Use source files on local development, otherwise download the files via CrowdIn CDN.
 * @param i18n
 * @param languageCode
 */
async function loadMessages(i18n: I18n, languageCode: string) {
  if (isCached(languageCode)) {
    return nextTick();
  }
  const messages =
    import.meta.env.DEV || languageCode === "en-gb"
      ? sources
      : await downloadTranslations(languageCode);

  i18n.global.setLocaleMessage(languageCode, messages);

  setCache(languageCode, messages);

  return nextTick();
}

export { i18n, setupI18n, setI18nLanguage, loadMessages };
