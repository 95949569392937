export const formatPascalCaseToSnake = (pascalCaseString: string) => {
  const result = pascalCaseString
    .split(/\.?(?=[A-Z])/)
    .join("_")
    .toLowerCase();
  return result;
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const parseStringToJSON = (str: string) => {
  const searchRegExp = /\n/g;
  str = str.replace(searchRegExp, "\\n");
  try {
    return JSON.parse(str);
  } catch (err) {
    return false;
  }
};

// Capitalises the last two characters, eg. "nl-NL"
export const formatLanguageRegion = (region: string) => {
  const lastTwoChars = /([a-z]{2})$/i;
  return region.replace(lastTwoChars, (match) => match.toUpperCase());
};

export const formatSecondsToHHMMSS = (seconds: number) => {
  const padZero = (num: number) => String(num).padStart(2, "0");

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
};
