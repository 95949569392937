<script setup lang="ts">
import { useSlots } from "vue";

type GEmptyStateProps = {
  title: string;
  description: string;
  imageUrl: string;
};

const props = defineProps<GEmptyStateProps>();
const slots = useSlots();
</script>
<template>
  <div class="grid max-w-xl place-items-center">
    <img
      :src="props.imageUrl"
      role="presentation"
      class="mb-4 max-w-xs md:mb-14 md:max-w-sm"
    />
    <p class="mb-4 text-xl font-bold text-primary md:text-3xl">
      {{ props.title }}
    </p>
    <p
      class="text-center text-sm md:text-base"
      :class="{
        'mb-10': slots.actions,
      }"
    >
      {{ props.description }}
    </p>
    <slot name="actions"></slot>
  </div>
</template>
