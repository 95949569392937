<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { GButton, GStateHandlerWrapper } from "@/components";
import { FilterPanel, LanguageSelect, GTable } from "@/patterns";
import {
  type GoodScanCourseTableDataRow,
  useGoodScanTab,
} from "./useGoodScanTab";
import ExportModal from "../components/ExportModal.vue";
import { useDownloadScorm } from "../components/useDownloadScorm";
import { useToastStore, useWebSessionStore } from "@/stores";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import CopyIcon from "@/assets/images/icons/copy.svg";
import type { LanguageCodeWithoutRegion } from "@/utils/i18n";

type Filters = {
  language: LanguageCodeWithoutRegion[] | null[];
  search: string;
};

const initialFilters: Filters = {
  language: [null],
  search: "",
};

const isExportModalOpen = ref(false);
const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
  }
};

const { t } = useI18n();

const selectedRows = ref<GoodScanCourseTableDataRow[]>([]);
const filters = ref<Filters>({ ...initialFilters });
const toastStore = useToastStore();
const defaultSort = [{ desc: false, id: "language" }];

const columnFilters = computed(() => [
  {
    id: "language",
    value: filters.value.language.filter(Boolean).map((l) => l.toUpperCase()),
  },
  {
    id: "name",
    value: filters.value.search,
  },
]);

const {
  isFetching,
  goodScanList,
  goodScanTableDefinition,
  error,
  trackExportGoodscan,
} = useGoodScanTab();

const { downloadScormPackages } = useDownloadScorm();
const { getLanguageRegionByLanguageCode } = useWebSessionStore();
const downloadRemoteScorm = async () => {
  await downloadScormPackages(
    "GOODSCAN",
    selectedRows.value.map((row) => ({
      id: row.courseCode,
      title: row.name,
      name: row.name,
      language: row.language,
      locale: getLanguageRegionByLanguageCode(row.language),
    })),
  );
  trackExportGoodscan(selectedRows.value);
  selectedRows.value = [];
};

const handleLTIDownloaded = () => {
  trackExportGoodscan(selectedRows.value);
  selectedRows.value = [];
};

type HandleCopyToClipboardInput = {
  link: string;
  goodscan: string;
  version: "LTI 1.1" | "LTI 1.3" | "web";
};

const transformLti13ToWebLink = (link: string) => {
  const currentUrl = new URL(window.location.href);
  const lti13Url = new URL(link);
  const locale = lti13Url.pathname.match(/..-../);
  return `${currentUrl.origin}/${locale}/goodscan.html`;
};

const handleCopyToClipboard = ({
  link,
  goodscan,
  version,
}: HandleCopyToClipboardInput) => {
  navigator.clipboard.writeText(link);
  toastStore.addToast({
    header: "success",
    body: t("contentSelection.goodscan.copy_success_message", {
      ltiVersion: version,
      goodscan,
    }),
  });
};
</script>
<template>
  <GStateHandlerWrapper :isLoading="isFetching" :error="error ? {} : null">
    <GButton
      class="mb-4 ml-auto lg:px-12"
      @click="onStartExport"
      :disabled="selectedRows.length === 0"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>

    <FilterPanel
      v-model:search="filters.search"
      @reset="filters = { ...initialFilters }"
    >
      <template #primaryFilters>
        <LanguageSelect
          :modelValue="filters.language"
          @update:modelValue="filters.language = $event"
          :isMultiChoice="true"
          :excludeRegion="true"
        />
      </template>
    </FilterPanel>

    <GTable
      :columns="goodScanTableDefinition"
      :data="goodScanList"
      :filter="{
        columns: columnFilters,
      }"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :default-sort="defaultSort"
    >
      <template v-slot:name="cellProps">
        <p class="w-56 overflow-hidden text-ellipsis">
          {{ cellProps.value }}
        </p>
      </template>
      <template v-slot:dateOfPublication="cellProps">
        {{ (cellProps.value as Date).toLocaleDateString() }}
      </template>
      <template v-slot:actions="cellProps">
        <div class="flex flex-col flex-wrap gap-2 md:flex-row">
          <GButton
            variant="secondary"
            size="small"
            @click="
              handleCopyToClipboard({
                link: transformLti13ToWebLink(cellProps.row.lti13Link),
                goodscan: cellProps.row.name,
                version: 'web',
              })
            "
          >
            <template #prefixIcon><CopyIcon class="text-primary" /></template>
            {{
              t("contentSelection.goodscan.copy_button", {
                ltiVersion: "Web",
              })
            }}
          </GButton>
          <GButton
            variant="secondary"
            size="small"
            @click="
              handleCopyToClipboard({
                link: cellProps.row.lti11Link,
                goodscan: cellProps.row.name,
                version: 'LTI 1.1',
              })
            "
          >
            <template #prefixIcon><CopyIcon class="text-primary" /></template>
            {{
              t("contentSelection.goodscan.copy_button", {
                ltiVersion: "LTI 1.1",
              })
            }}
          </GButton>
          <GButton
            variant="secondary"
            size="small"
            @click="
              handleCopyToClipboard({
                link: cellProps.row.lti13Link,
                goodscan: cellProps.row.name,
                version: 'LTI 1.3',
              })
            "
          >
            <template #prefixIcon>
              <CopyIcon class="fill-primary" />
            </template>
            {{
              t("contentSelection.goodscan.copy_button", {
                ltiVersion: "LTI 1.3",
              })
            }}
          </GButton>
        </div>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <ExportModal
    :isOpen="isExportModalOpen"
    ltiFileName="gh-goodscan-export"
    :data="{
      dataSource: selectedRows,
      columns: [
        {
          id: 'name',
          header: t('common.name'),
        },
        {
          id: 'lti11Link',
          header: t('contentSelection.txt_xls_lti11_link'),
        },
        {
          id: 'lti13Link',
          header: t('contentSelection.txt_xls_lti13_link'),
        },
      ],
    }"
    :exportTypes="['lti', 'scorm']"
    @close="isExportModalOpen = false"
    @exportToScorm="downloadRemoteScorm"
    @exportToLtiSuccess="handleLTIDownloaded"
  />
</template>
