<script setup lang="ts">
type Props = {
  progress: number;
  variant: "primary" | "success" | "danger";
};

const props = defineProps<Props>();
</script>
<template>
  <div
    role="progressbar"
    class="h-3 w-full overflow-hidden rounded-full"
    :class="{
      'bg-blue-20': props.variant === 'primary',
      'bg-category-management-10': props.variant === 'success',
      'bg-category-productivity-10': props.variant === 'danger',
    }"
  >
    <div
      class="h-full rounded-full bg-primary"
      :class="{
        'bg-primary': props.variant === 'primary',
        'bg-category-management-100': props.variant === 'success',
        'bg-category-productivity-100': props.variant === 'danger',
      }"
      :style="{ width: `${props.progress}%` }"
    ></div>
  </div>
</template>
