<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCourseStore } from "@/stores";
import { GDropdown } from "@/components";
import { computed } from "vue";

const { t } = useI18n();
const { exportableCourses } = storeToRefs(useCourseStore());
const { getCategoryOptions } = useCourseStore();

const options = computed(() => {
  return getCategoryOptions(exportableCourses.value);
});

const selectedCategories = defineModel<number[]>({
  default: [],
  required: true,
});
</script>
<template>
  <GDropdown
    :options="options"
    :value="selectedCategories"
    :label="t('common.select_category')"
    isMultiChoice
    searchable
    @change="selectedCategories = $event as number[]"
  />
</template>
