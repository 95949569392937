<script setup lang="ts">
import { ListboxButton } from "@headlessui/vue";

import ChevronDownIcon from "../../assets/images/icons/chevron-down.svg";

const props = withDefaults(defineProps<{ icon?: string | object }>(), {
  icon: ChevronDownIcon,
});
</script>
<template>
  <ListboxButton
    class="grid place-content-center rounded-lg border border-transparent bg-white px-4 py-[0.64rem] text-start text-primary hover:border-primary focus:border-primary"
    :class="{ 'border-primary': false }"
  >
    <component :is="props.icon" />
  </ListboxButton>
</template>
