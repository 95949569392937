<script lang="ts" setup>
import { ref } from "vue";
import GrowthAreaButtons from "./GrowthAreaButtons.vue";
// import GrowthAreaInfo from "./GrowthAreaInfo.vue"; // This is not used
import DonutChart from "./DonutChart.vue";
import type { GrowthAreaWithRecommendations } from "../types";
import GrowthAreaSection from "../components/GrowthAreaSection.vue";

const props = defineProps<{
  growthAreas: Array<GrowthAreaWithRecommendations>;
}>();

const visibleGrowthArea = ref(
  props.growthAreas
    ? props.growthAreas[0].name.replace(/[^A-Za-z0-9]/g, "")
    : "Overview",
);

function updateVisibleGrowthArea(growthArea: string) {
  visibleGrowthArea.value = growthArea;
}
</script>

<template>
  <!-- OScan first release was for only one language -->
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <div v-if="growthAreas" class="w-full">
    <GrowthAreaButtons
      :growth-areas="growthAreas"
      v-model="visibleGrowthArea"
      @update:model-value="updateVisibleGrowthArea(visibleGrowthArea)"
    />

    <div
      class="fle-col mx-auto flex h-full w-full items-center gap-12 md:grid md:max-w-[1200px] md:grid-cols-[1fr_2fr] md:gap-20"
    >
      <div class="sticky top-20 z-0 flex w-full self-start">
        <DonutChart
          :growth-areas="growthAreas"
          v-model="visibleGrowthArea"
          @update:model-value="updateVisibleGrowthArea(visibleGrowthArea)"
        />
      </div>
      <div>
        <div id="Overview" class="flex h-[400px] flex-col justify-center gap-4">
          <p class="text-[26px] font-bold">
            See where your organisation’s
            <span class="text-primary">potential</span> lies in this
            <span class="text-primary">score breakdown</span>
          </p>
          <div class="grid gap-4">
            <p class="text-sm">
              The GoodHabitz growth model is a model and philosophy that
              highlights and outlines the key focus areas that organisations and
              their workforce need, or can grow in, to succeed and excel in the
              modern workplace of today, and in the future. The model is the
              base of all educational content built and delivered through
              GoodHabitz, and groups content into core development areas that
              your organisations may need to focus on.
            </p>
            <p>
              Insights include <b>beliefs</b> of the submitters, along with
              <b>challenges</b> and <b>recommendations</b>.
            </p>
          </div>
        </div>
        <GrowthAreaSection
          v-for="growthArea of growthAreas"
          :key="growthArea.id"
          :growthArea="growthArea"
          v-model="visibleGrowthArea"
          @update:model-value="updateVisibleGrowthArea(visibleGrowthArea)"
        />
      </div>
    </div>
  </div>
</template>
