<script setup lang="ts">
import { GDropdown } from "@/components";
import { useFetch } from "@/composables";
import { COURSE_PUBLICATION_STATUSES, NODE_TYPE_IDS } from "@/constants/course";
import { useCourseStore } from "@/stores";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

type CourseListResponse = {
  CourseId: number;
  CourseName: string;
}[];

const props = defineProps<{
  language: string;
}>();

const emit = defineEmits<{
  (e: "fetchingCompleted"): void;
}>();

const { t } = useI18n();
const courseStore = useCourseStore();

const { data, isFetching, isFinished } = useFetch(
  () => `api/courses?languageRegion=${props.language}`,
  { refetch: true },
).json<CourseListResponse>();

const publishedCourses = computed(() => {
  const publishedCourses = data.value?.filter((lngCourse) => {
    const course = courseStore.courses.find(
      (c) => c.CourseId === lngCourse?.CourseId,
    );
    return (
      course?.PublicationStatus === COURSE_PUBLICATION_STATUSES.PUBLISHED &&
      course.NodeTypeId === NODE_TYPE_IDS.COURSE
    );
  });

  if (!publishedCourses) return [];

  return publishedCourses.map((course) => ({
    label: course.CourseName,
    value: course.CourseId,
  }));
});

const selectedCourse = defineModel<number>({
  required: true,
});

watch(
  publishedCourses,
  (courses) => {
    if (courses.length > 0) {
      selectedCourse.value = courses[0].value;
    }
  },
  { immediate: true, deep: true },
);

watch(isFinished, (updatedIsFinished) => {
  if (updatedIsFinished) {
    emit("fetchingCompleted");
  }
});

</script>
<template>
  <GDropdown
    :options="publishedCourses"
    :value="selectedCourse"
    :label="t('common.select_course')"
    :isDisabled="isFetching"
    searchable
    @change="selectedCourse = $event as number"
  />
</template>
