<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { OnboardingStep } from "@/components/Onboarding/OnboardingWizard";
import StepContent from "./StepContent.vue";
import RoleCard from "./RoleCard.vue";

const emit = defineEmits<{
  (event: "goNext"): void;
}>();

const { t } = useI18n();
const selectedRoles = ref<string[]>([]);
const isCardWithInputChecked = ref(false);

const checkDisabled = (role: string) => {
  return selectedRoles.value.length > 2 && !selectedRoles.value.includes(role);
};

const handleToggleRole = (role: string) => {
  const isCardWithInput = roles.find((r) => r.id === role)?.withInput;
  if (selectedRoles.value.includes(role)) {
    selectedRoles.value = selectedRoles.value.filter((r) => r !== role);
  } else {
    selectedRoles.value = [...selectedRoles.value, role];
  }

  if (isCardWithInput) {
    isCardWithInputChecked.value = !isCardWithInputChecked.value;
  }
};
const roles = [
  {
    id: "1",
    title: "Human Resources",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "2",
    title: "Sales",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "3",
    title: "Marketing",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "4",
    title: "Finance",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "5",
    title: "Operations",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "6",
    title: "Customer Service",
    icon: "https://i.pravatar.cc/300",
  },
  {
    id: "7",
    title: "Other",
    icon: "https://i.pravatar.cc/300",
    withInput: true,
  },
];
</script>
<template>
  <OnboardingStep>
    <StepContent
      :heading="t('onboarding.about_you.role.heading')"
      :description="t('onboarding.about_you.role.description')"
      :actionLabel="t('onboarding.about_you.action_label')"
      @goNext="emit('goNext')"
      skippable
      contentClassName="md:col-span-12"
    >
      <template #additionalContent>
        <div
          class="grid grid-cols-[repeat(auto-fill,_minmax(240px,_1fr))] gap-x-8 gap-y-4 pb-4 pt-9"
        >
          <RoleCard
            v-for="role of roles"
            :key="role.id"
            :id="role.id"
            :isDisabled="checkDisabled(role.id)"
            :isSelected="selectedRoles.includes(role.id)"
            @select="handleToggleRole"
          >
            <div class="grid w-full justify-items-center">
              <div
                class="h-20 w-20 overflow-hidden rounded-full"
                v-if="role.withInput ? !isCardWithInputChecked : true"
              >
                <img :src="role.icon" />
              </div>
              <span class="mt-4 text-primary">
                {{ role.title }}
              </span>
              <textarea
                class="focus-visible:border-primary! mt-2.5 w-full resize-none rounded-[4px] border-2 border-grey-30 p-2 transition-all duration-100 ease-in-out"
                v-if="role.withInput && isCardWithInputChecked"
                placeholder="Describe your role(s)"
              />
            </div>
          </RoleCard>
        </div>
      </template>
    </StepContent>
  </OnboardingStep>
</template>
