<script setup lang="ts">
import { toRef } from "vue";
import { InsightCard } from "@/components";
import { useSectionFetch } from "./useSectionFetch";
import { useI18n } from "vue-i18n";

type ActiveUsersResponse = {
  totalUsersCount: number;
  activeUsersCount: number;
};

const props = defineProps<{
  startDate: number;
  endDate: number;
}>();

const startDateRef = toRef(props, "startDate");
const endDateRef = toRef(props, "endDate");
const { t } = useI18n();
const { execute, data, isFetching, error } =
  useSectionFetch<ActiveUsersResponse>(
    "ActiveUsersReport",
    startDateRef,
    endDateRef,
  );
</script>
<template>
  <InsightCard
    :title="$t('assessments.active_users_title')"
    :subtitle="$t('assessments.active_users_subtitle')"
    :isLoading="isFetching"
    :error="error"
    @retry="execute"
  >
    <div class="w-full h-full grid place-content-center text-center">
      <p class="text-3xl font-bold">
        {{ data?.activeUsersCount || "N/A" }}
      </p>
      <p class="text-grey-600 whitespace-nowrap">
        {{ t("assessments.active_users_chart_label") }}
      </p>
    </div>
  </InsightCard>
</template>
