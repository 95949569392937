<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GCard, GButton } from "@/components";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import GlobeIcon from "@/assets/images/icons/globe.svg";
import { downloadFile } from "@/utils/downloadFile";
import { watch } from "vue";
import { useFetch } from "@vueuse/core";
import { getToken } from "@/utils/getToken";

const props = withDefaults(
  defineProps<{
    title: string;
    content: string;
    imageSrc: string;
    resourceLink: string;
    hasBorder?: boolean;
  }>(),
  {
    hasBorder: true,
  }
);

const { t } = useI18n();
const { execute, data: blob } = useFetch(props.resourceLink, {
  immediate: false,
  beforeFetch: async ({ options }) => {
    options.headers = {
      Authorization: `Bearer ${getToken()}`,
    };
  },
}).blob();

watch(blob, () => {
  if (blob.value) {
    downloadFile(blob.value, props.content);
  }
});
</script>
<template>
  <GCard class="overflow-hidden p-0">
    <div class="grid h-full grid-flow-col grid-cols-5">
      <div class="col-span-2">
        <img :src="props.imageSrc" alt="" class="h-full w-full object-cover" />
      </div>
      <div
        class="col-span-3 rounded-e-lg p-3"
        :class="{ 'border-2 border-l-0 border-[#F7F6F9]': props.hasBorder }"
      >
        <div class="flex gap-2 text-sm text-primary">
          <GlobeIcon class="text-primary" />
          <span>{{ props.title }}</span>
        </div>
        <p class="my-2 text-xl font-medium">{{ content }}</p>
        <GButton variant="secondary" @click="execute()">
          <template #prefixIcon>
            <ArrowDownToLineIcon />
          </template>
          <span>{{ t("common.download") }}</span>
        </GButton>
      </div>
    </div>
  </GCard>
</template>
