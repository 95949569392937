import { defineStore } from "pinia";
import { ref } from "vue";

export type Toast = {
  id: number;
  header: string;
  body: string;
};

export const useToastStore = defineStore("toasts", () => {
  const toasts = ref<Toast[]>([]);

  function addToast(toastText: Pick<Toast, "header" | "body">) {
    toasts.value.push({ id: Date.now(), ...toastText });
  }

  function removeToast(toastId: number) {
    toasts.value = toasts.value.filter((t) => t.id !== toastId);
  }

  return {
    toasts,
    addToast,
    removeToast,
  };
});
