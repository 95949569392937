<script setup lang="ts">
import { computed, onMounted, useSlots } from "vue";

import { useI18n } from "vue-i18n";
import { GButton } from "../../GButton";

import CloseIcon from "@/assets/images/icons/close.svg";
import ArrowLeftIcon from "@/assets/images/icons/arrow-left.svg";
import Logo from "@/assets/images/logo.svg";

const props = defineProps<{
  activeStep: number;
  stepsCount: number;
}>();

const emit = defineEmits<{
  (event: "goBack", step: number): void;
  (event: "close"): void;
}>();

const slots = useSlots();
const { t } = useI18n();

const StepsComponents = [
  "OnboardingStep",
  "RolesStep",
  "AchievementsStep",
] as const;

onMounted(() => {
  const HasNonStepsChildren = slots.default?.().some((child) => {
    StepsComponents.includes((child.type as any).name);
  });
  if (HasNonStepsChildren) {
    throw new Error(
      `OnboardingWizard component should only have these components as children:\n${StepsComponents.join(
        "\n"
      )}`
    );
  }
});

const onboardingProgress = computed(() => {
  return ((props.activeStep + 1) / props.stepsCount) * 100;
});
</script>
<template>
  <div class="absolute inset-0 z-50 flex flex-col bg-white">
    <div class="flex justify-between p-8 pb-2">
      <Logo />
      <GButton variant="link" @click="emit('close')">
        {{ t("common.close") }}
        <template #suffixIcon>
          <CloseIcon class="grid- h-4 w-4 fill-primary" />
        </template>
      </GButton>
    </div>
    <div class="relative h-[10px] w-full bg-grey-20">
      <span
        class="absolute inset-y-0 left-0 z-10 bg-primary transition-all duration-300 ease-in-out"
        :style="{ width: `${onboardingProgress}%` }"
      ></span>
    </div>

    <div
      class="ml-12 mt-2 transition-opacity duration-300 ease-in-out"
      :class="[
        activeStep === 0 ? 'invisible opacity-0' : 'visible opacity-100',
      ]"
    >
      <GButton
        variant="link"
        @click="emit('goBack', activeStep - 1 < 0 ? 0 : activeStep - 1)"
      >
        {{ t("common.back") }}
        <template #prefixIcon>
          <ArrowLeftIcon class="h-4 w-4 fill-primary" />
        </template>
      </GButton>
    </div>

    <div class="onboarding-step-container">
      <slot></slot>
    </div>
    <div class="absolute inset-x-0 bottom-0 z-10 h-2 bg-primary"></div>
  </div>
</template>
<style scoped>
.onboarding-step-container {
  display: grid;
  grid-auto-flow: column;
  overflow-x: hidden;
  grid-template-columns: repeat(v-bind("stepsCount"), 100vw);
  margin-left: calc(-100vw * v-bind("props.activeStep"));
  transition: margin-left 0.3s ease-in-out;
  flex: 1 1 0%;
}
</style>
