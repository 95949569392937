import type { LanguageCode as SharedLanguageCode } from "@gh-dashboard/types";
import { isFulfilled } from "./typeGuards";

export const supportedLocales: Record<SharedLanguageCode, string> = {
  "en-gb": "English",
  "en-au": "English (AU)",
  "en-in": "English (IN)",
  "de-de": "Deutsch",
  "de-at": "Deutsch (AT)",
  "de-ch": "Deutsch (CH)",
  "nl-nl": "Nederlands",
  "nl-be": "Nederlands (BE)",
  "fr-fr": "Français",
  "fr-be": "Français (BE)",
  "es-es": "Español",
  "es-ar": "Español (AR)",
  "es-mx": "Español (MX)",
  "it-it": "Italiano",
  "sv-se": "Svenska",
  "pl-pl": "Polski",
  "da-dk": "Dansk",
  "pt-pt": "Português",
  "pt-br": "Português (BR)",
  "tr-tr": "Türkçe",
  "id-id": "Indonesian (ID)",
  "zh-cn": "中文 (简体)",
  "ca-es": "Catalan (CA)",
};

export type LanguageCode = SharedLanguageCode;
export type LanguageCodeWithoutRegion =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- We need to use this type to infer the type of the language code
  LanguageCode extends `${infer L}-${infer _}` ? L : never;

export const defaultLocale: LanguageCode = "en-gb";

export const getLocaleWithUpperCaseRegion = (languageRegion: LanguageCode) => {
  const [language, region] = languageRegion.split("-");
  return `${language}-${region.toUpperCase()}`;
};

export const getLocaleFromUrl = () => {
  const urlLocale = window.location.pathname.split("/")[1] as LanguageCode;
  const isUrlLocaleSupported = urlLocale in supportedLocales;

  return isUrlLocaleSupported ? urlLocale : defaultLocale;
};

const getLocaleFromBrowser = (options = {}) => {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return defaultLocale;
  }

  const trimmedLocale = (
    opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim()
  ).toLowerCase() as LanguageCode;

  return trimmedLocale;
};

/**
 * Getting localization string
 * If window.location.pathname = '/' then get it from browser
 * else get it from pathname
 * Also check if it is supported. If not return the default one.
 */
export const getStartingLocale = () => {
  const locale =
    window.location.pathname === "/"
      ? getLocaleFromBrowser()
      : getLocaleFromUrl();
  const isLocaleSupported = Object.keys(supportedLocales).includes(locale);

  return isLocaleSupported ? locale : defaultLocale;
};

/**
 * Returns the translations for requested language via CrowdIn CDN
 * @param {string} languageCode
 */
export const downloadTranslations = async (languageCode: string) => {
  const crowdInDistributionBaseUrl = `${import.meta.env.VITE_CROWDIN_PROXY}/${
    import.meta.env.VITE_CROWDIN_DISTRIBUTION_HASH
  }`;

  const manifestRequest = await fetch(
    `${crowdInDistributionBaseUrl}/manifest.json`,
  );
  const manifestResponse = await manifestRequest.json();

  const urls = [
    "assessments",
    "common",
    "connection-methods",
    "content-selection",
    "launch",
    "navigation",
    "onboarding",
    "organisation",
    "reports",
    "resources",
    "validation-messages",
  ].map(
    (type) =>
      `${crowdInDistributionBaseUrl}/content/src/i18n/locales/${type}/${languageCode}.json?timestamp=${manifestResponse.timestamp}`,
  );

  const allTranslations = await Promise.allSettled(
    urls.map((url) => fetch(url)),
  )
    .then((responses) => {
      const successResponses: PromiseFulfilledResult<Response>[] = [];
      responses.forEach(
        (res) => isFulfilled(res) && res.value.ok && successResponses.push(res),
      );
      return Promise.all(
        successResponses.map((response) => response.value.json()),
      );
    })
    .then((jsons) => {
      const translations = {};
      jsons.map((json) => {
        Object.assign(translations, { ...json });
      });
      return translations;
    })
    .catch((error) => {
      throw error;
    });

  return allTranslations;
};
