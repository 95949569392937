import type { TableColumn } from "@/patterns";

/**
 * Creates a table definition based on the provided columns and conditions.
 *
 * @param cols - A readonly array of TableColumn objects representing the columns of the table.
 * @param conds - An optional object specifying the conditions for filtering the columns.
 * @returns An array of TableColumn objects that satisfy the provided conditions, or the original array if no conditions are provided.
 */
export const createTableDef = <T extends string>(
  cols: TableColumn<T>[],
  conds?: Partial<Record<T, boolean>>,
) => {
  if (!conds) return cols;

  return cols.filter((col) => {
    if (conds[col.accessor] === undefined) return true;

    return conds[col.accessor];
  });
};
