<script setup lang="ts">
import { computed, type ComputedRef } from "vue";
import { Bar } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { formatISO } from "date-fns";
import {
  Chart as ChartJS,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  TimeScale,
  type ChartDataset,
  type ChartOptions,
} from "chart.js";

ChartJS.register(
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  TimeScale
);

type DonutChartProps = {
  xValues: number[];
  yValues: number[];
  aspectRatio?: number;
};

const props = defineProps<DonutChartProps>();

const options: ChartOptions<"bar"> = {
  responsive: true,
  aspectRatio: props.aspectRatio || 3.2,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function (context) {
          const timestamp = context[0].parsed.x;
          return formatISO(timestamp, { representation: "date" });
        },
      },
    },
  },
  scales: {
    y: {
      border: { display: false },
      offset: false,
      suggestedMax: Math.max(...props.yValues) * 1.1,
      ticks: {
        callback: function (value) {
          if (!Number.isInteger(value)) return "";
          return (value as number) % 1 !== 0 ? "" : value;
        },
      },
    },
    x: {
      type: "time",
      border: { display: false },
      time: { minUnit: "day" },
      grid: { display: false },
      ticks: { source: "labels", font: { size: 8 } },
    },
  },
};

const dataSet: ComputedRef<ChartDataset<"bar", number[]>> = computed(() => {
  if (props.yValues)
    return {
      data: props.yValues,
      backgroundColor: "#7B4BF0",
      borderRadius: 4,
      minBarLength: 2,
      barThickness: 12,
    };
  return { data: [] };
});
</script>
<template>
  <div class="relative inline-block h-auto">
    <Bar
      style="{height: 100%, width: 'auto'}"
      :data="{ labels: xValues, datasets: [dataSet] }"
      :options="options"
    >
    </Bar>
  </div>
</template>
