<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { ref, toRef, watch } from "vue";
import { useField } from "vee-validate";

type TextAreaProps = {
  name: string;
  type?: string;
  size?: "small" | "large";
  modelValue: string | number;
  label: string;
  labelClasses?: string[];
  fullWidth?: boolean;
  containerClasses?: string;
  inputClasses?: string;
  disabled?: boolean;
  readonly?: boolean;
  hint?: string;
  maxlength?: number;
};

const props = withDefaults(defineProps<TextAreaProps>(), {
  type: "text",
  size: "large",
  inputClasses: "",
});
const emit = defineEmits<{
  (event: "update:modelValue", value: string | number): void;
}>();

const name = toRef(props, "name");

const { value, errorMessage, handleChange, handleBlur } = useField(
  name,
  (val) => (props.maxlength ? `${val}`.length <= props.maxlength : true),
  {
    initialValue: props.modelValue,
  }
);

watch(value, (newValue) => {
  handleChange(newValue);
  emit("update:modelValue", newValue);
});

const input = ref<HTMLInputElement>();
defineExpose({
  focus: () => input.value?.focus(),
});
</script>

<template>
  <div :class="[containerClasses, fullWidth && 'w-full']">
    <label :for="name" class="label inline-block w-full p-0">
      <span
        :class="[
          'label-text mb-2 inline-block font-medium capitalize text-black',
          labelClasses,
        ]"
      >
        {{ label }}
        <slot name="labelIcon"></slot>
      </span>
      <slot name="before"></slot>
      <textarea
        :id="name"
        ref="input"
        :name="name"
        :type="type"
        :maxlength="maxlength"
        :disabled="disabled"
        :readonly="readonly"
        class="textarea flex w-full items-center gap-2 rounded-lg border-2 border-grey-10 bg-white pr-3 focus-within:border-primary"
        :class="{
          'border-error focus-within:border-error': !!errorMessage,
          'cursor-not-allowed': disabled,
          'bg-grey-5': disabled || readonly,
          [inputClasses]: true,
        }"
        v-bind="$attrs"
        v-model="value"
        @blur="handleBlur"
      ></textarea>
    </label>
    <p v-if="errorMessage || hint" class="w-fit text-sm">
      <span class="text-error" v-if="errorMessage">{{ errorMessage }}</span>
      <span class="" v-else-if="hint">{{ hint }}</span>
    </p>
    <slot name="after"></slot>
  </div>
</template>
