import { useFetch } from "@/composables";
import { useWebSessionStore } from "@/stores";
import { ref } from "vue";
import type { Recommendation } from "./types";

export function useRecommendCourse(recommendations: Recommendation[]) {
  const RECOMMENDATION_DURATION = 180;

  const isModalOpen = ref(false);
  const webSessionStore = useWebSessionStore();

  const recommendCourseReqBody = ref({
    CompanyId: webSessionStore.webSession.CurrentUser.CompanyId,
    CourseId: undefined,
    Duration: RECOMMENDATION_DURATION,
    DurationUnit: "D",
    StartDate: new Date().toISOString(),
  });

  const { isFetching, execute } = useFetch("api/SpotlightCourse", {
    immediate: false,
  }).post(recommendCourseReqBody);

  const recommendCourse = (id: number) => {
    recommendCourseReqBody.value.CourseId = id;
    isModalOpen.value = true;
  };

  const confirmRecommendation = async () => {
    const recommendation = recommendations.find(
      (r) => r.id === recommendCourseReqBody.value.CourseId,
    );
    await execute();
    recommendation.isRecommended = true;
    isModalOpen.value = false;
  };

  const discardRecommendation = () => {
    isModalOpen.value = false;
  };

  return {
    RECOMMENDATION_DURATION,
    isConfirming: isModalOpen,
    isRecommending: isFetching,
    recommendCourse,
    confirmRecommendation,
    discardRecommendation,
  };
}
