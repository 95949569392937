import type { ValuesOf } from "./utils";

export type ExportTableData = {
  headers: string[];
  rows: (string | number | Date)[][];
};

export type ExportType = "scorm" | "lti" | "metadata";

export type ExportTypeOption = {
  label: string;
  value: ExportType;
};

export enum ContentMode {
  BOTH = 1,
  LESSONS,
  TEST,
}

export const CONTENT_MODE = {
  1: "lessons_and_test",
  2: "lessons_only",
  3: "test_only",
};

export enum LessonStatusSuccess {
  Completed = "completed",
  Passed = "passed",
}

export enum LessonStatusFail {
  Incomplete = "incomplete",
  Failed = "failed",
}

export type FormatLtiDataOptions<
  T = Record<string, string | number | boolean | Date>,
> = {
  dataSource: T[];
  columns: Array<{
    id: keyof T;
    header: string;
    formatValue?: (value: T[keyof T], row?: T) => string;
  }>;
};

const CONTENT_TYPE = {
  COURSE: "COURSE",
  ASSESSMENT: "ASSESSMENT",
  GOODSCAN: "GOODSCAN",
  SINGE_ACTIVITY: "SINGLE_ACTIVITY",
  LESSON: "LESSON",
} as const;

export type ContentType = ValuesOf<typeof CONTENT_TYPE>;

export type ContentRS = {
  id: string;
  name: string;
  title: string;
  language?: string;
  locale?: string;
};

export type MultiLanguageBundledContentRS = {
  contentListInContainer: ContentRS[];
  languageOptionList: { lang: string; label: string }[];
  containerName: string;
};

export type TemplateAttributes = {
  mode: number;
  threshold: number;
  mixedTrainingResultComplete: string;
  mixedTrainingResultIncomplete: string;
};

export type ContentRSPayload = {
  content: ContentRS | MultiLanguageBundledContentRS;
  templateAttributes: TemplateAttributes;
  companyId: number;
  contentType: ContentType;
};
