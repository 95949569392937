import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { differenceInDays, getMonth, getTime, sub } from "date-fns";

import { useCourseStore } from "@/stores";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export const useDetailsPage = () => {
  const isLoading = ref(true);
  const dateRange = ref<[Date, Date]>([
    sub(new Date(), { days: 7 }),
    new Date(),
  ]);
  const { courses, courseDetailsReport } = storeToRefs(useCourseStore());
  const { formatReport } = useCourseStore();
  const { params } = useRoute();
  const { t } = useI18n();

  watch([courses, dateRange], async () => {
    isLoading.value = true;
    if (courses.value.length === 0) {
      return;
    }
    await formatReport({
      containerId: Number(params.courseContainerId),
      startDate: dateRange.value[0],
      endDate: dateRange.value[1],
    });
    isLoading.value = false;
  });

  const groupCountPerMonthAndYear = (
    data: Array<{ date: Date; count: number }>,
  ): Record<string, number> => {
    const result: Record<string, number> = {};
    for (const dataPoint of data) {
      const date = dataPoint.date;
      const month = getMonth(date) + 1;
      const year = date.getFullYear();
      const key = `${year}-${month}`;
      if (result[key]) {
        result[key] += dataPoint.count;
      } else {
        result[key] = dataPoint.count;
      }
    }
    return result;
  };

  const formattedActivityVisitsData = computed(() => {
    if (!courseDetailsReport.value) {
      return { labels: [], data: [] };
    }

    // if less then 60 days, show days individually
    if (differenceInDays(dateRange.value[1], dateRange.value[0]) < 60) {
      return {
        labels: courseDetailsReport.value.activityVisits.map((dataPoint) =>
          getTime(dataPoint.date),
        ),
        data: courseDetailsReport.value.activityVisits.map(
          (dataPoint) => dataPoint.count,
        ),
      };
    }

    const groupedData = groupCountPerMonthAndYear(
      courseDetailsReport.value.activityVisits,
    );
    return {
      labels: Object.keys(groupedData).map((key) => getTime(new Date(key))),
      data: Object.values(groupedData),
    };
  });

  const statsData = computed(() => [
    {
      icon: "user",
      data: [
        {
          label: t("courseDetails.stats_total_students"),
          value:
            courseDetailsReport.value?.totalStudentsCount.toString(10) ?? "0",
        },
        {
          label: t("courseDetails.stats_new_students"),
          value:
            courseDetailsReport.value?.newStudentsCount.toString(10) ?? "0",
        },
      ],
    },
    {
      icon: "fileCertificate",
      data: [
        {
          label: t("courseDetails.stats_course_certificates"),
          value:
            courseDetailsReport.value?.courseCertificatesCount.toString(10) ??
            "0",
        },
        {
          label: t("courseDetails.stats_test_certificates"),
          value:
            courseDetailsReport.value?.testCertificatesCount.toString(10) ??
            "0",
        },
      ],
    },
    {
      icon: "clock",
      data: [
        {
          label: t("reports.tbl_col_study_time"),
          value: courseDetailsReport.value?.studyTime ?? "0",
        },
      ],
    },
  ]);

  return {
    isLoading,
    dateRange,
    courseDetailsReport,
    formattedActivityVisitsData,
    statsData,
  };
};
