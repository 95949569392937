<script lang="ts" setup>
type GCardProps = {
  isElevated?: boolean;
  isElevatedOnHover?: boolean;
  hasBorder?: boolean;
};

const props = defineProps<GCardProps>();
</script>

<template>
  <div
    class="rounded-lg border-solid bg-white p-5"
    :class="{
      [`drop-shadow-md`]: props.isElevated,
      [`hover:drop-shadow-lg`]: props.isElevatedOnHover,
      [`border border-grey-20`]: props.hasBorder,
    }"
  >
    <slot></slot>
  </div>
</template>
