<script setup lang="ts">
import { ref } from "vue";
import "@mux/mux-player";
import MuxPlayerElement from "@mux/mux-player";

const player = ref<MuxPlayerElement>();

const props = withDefaults(
  defineProps<{
    playbackId: string;
    withControls?: boolean;
    title?: string;
    primaryColor?: string;
    secondaryColor?: string;
  }>(),
  {
    withControls: true,
    primaryColor: "#FFFFFF",
    secondaryColor: "#000000",
  },
);

const play = () => {
  player.value?.play();
};
const pause = () => {
  player.value?.pause();
};
defineExpose({
  pause,
  play,
});
</script>

<template>
  <mux-player
    ref="player"
    stream-type="on-demand"
    :playback-id="props.playbackId"
    :metadata-video-title="props.title"
    :primary-color="props.primaryColor"
    :secondary-color="props.secondaryColor"
    :style="
      props.withControls
        ? ''
        : '--seek-backward-button: none; --seek-forward-button: none; --bottom-controls: none;'
    "
  ></mux-player>
</template>
