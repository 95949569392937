<script setup lang="ts">
import { computed } from "vue";
import { ListboxButton } from "@headlessui/vue";

import type { ValueType } from "./types";

import ChevronDownIcon from "../../assets/images/icons/chevron-down.svg";

const props = defineProps<{
  value?: ValueType | ValueType[];
  label?: string;
  isMenuOpen: boolean;
  singleSelectedOptionLabel?: string;
}>();

const displayValue = computed(() => {
  return Array.isArray(props.value) 
    ? props.label
    : props.singleSelectedOptionLabel;
});
</script>
<template>
  <ListboxButton
    class="flex w-full items-center justify-between whitespace-nowrap rounded-lg border border-grey-10 bg-white px-4 py-[0.64rem] text-start text-black hover:border-primary focus:border-primary"
    :class="{ 'border-primary': isMenuOpen }"
  >
    <span class="flex-1 me-2 overflow-hidden text-ellipsis">
      {{ displayValue }}
    </span>
    <div
      v-if="Array.isArray(value) && value.length > 0 && value[0] !== null"
      class="mx-3 flex min-w-[1.5rem] justify-center rounded-full bg-primary py-0.5 text-sm text-white"
    >
      {{ value.length }}
    </div>
    <ChevronDownIcon
      class="transition-transform"
      :class="{ 'rotate-180': isMenuOpen }"
    />
  </ListboxButton>
</template>
