<script lang="ts">
export default {
  name: "OnboardingStep",
};
</script>

<template>
  <div class="h-full px-4 md:px-24">
    <slot></slot>
  </div>
</template>
