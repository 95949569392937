import { type RouteLocationNormalized } from "vue-router";
import { useUserStore, useWebSessionStore, useUiStore } from "@/stores";
import {
  redirectToDashboardHomePage,
  redirectToLogin,
} from "@/utils/redirectToLogin";

export const authGuard = async (to: RouteLocationNormalized) => {
  try {
    const webSessionStore = useWebSessionStore();
    const uiStore = useUiStore();
    if (!webSessionStore.webSession) {
      uiStore.setIsLoading(true);
      await webSessionStore.getWebSession();
    }

    const isUnrestricted = to.matched.every(
      (record) => !record.meta.allowedRoles,
    );
    const userStore = useUserStore();
    const isAuthorised = userStore.isAuthorizedForRoute(to);

    if (isUnrestricted || isAuthorised) {
      return true;
    } else if (!isUnrestricted && !isAuthorised) {
      redirectToDashboardHomePage();
      return false;
    }
  } catch (error) {
    console.error(error);
    redirectToLogin();
    return false;
  }
};
