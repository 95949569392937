<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const props = defineProps<{
  title: string;
  subtitle?: string;
  icon: {
    name?: string;
    bgColor?: string;
  };
}>();

const Icon = defineAsyncComponent(
  /**
   * This url should be to the `@gh-dashboard/icons` package but this fails due to module resolution issues
   * So, I'll add a TODO: to change this to a path to `@gh-dashboard/icons` directly when this issue is resolved
   */
  () => import(`../../../../icons/${props.icon.name}.svg`),
);
</script>
<template>
  <div class="flex items-center justify-between px-8 py-[18px]">
    <div class="flex flex-1 gap-3.5">
      <div
        v-if="props.icon.name"
        class="grid place-content-center rounded-lg p-1.5 w-10 h-10"
        :style="`background-color: ${props.icon.bgColor};`"
      >
        <component :is="Icon" class="h-6 w-6 fill-current text-white" />
      </div>
      <div
        class="grid gap-1"
        :class="{ 'place-items-center': !props.subtitle }"
      >
        <p class="font-medium text-black">{{ props.title }}</p>
        <p v-if="props.subtitle" class="text-grey-70">
          {{ props.subtitle }}
        </p>
      </div>
    </div>
    <slot name="action"></slot>
  </div>
</template>
