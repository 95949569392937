<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useLocalStorage } from "@vueuse/core";
import { useRouter } from "vue-router";
import {
  AccordionPanelItem,
  OnboardingAccordionSection,
  GButton,
  GBadge,
} from "@/components";
import { getLocaleFromUrl } from "@/utils/i18n";
import { SME_ONBOARDING_STATE_STORAGE_KEY } from "@/constants";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";

import ClockIcon from "@/assets/images/icons/clock.svg";
import CheckIcon from "@/assets/images/icons/check.svg";
import RotateRightIcon from "@/assets/images/icons/rotate-right.svg";
import {
  fetchLaunchAccordion,
  type LaunchAccordion,
  type LaunchAccordionItemAction,
} from "@/sanity-queries/launch-accordion";

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    withActions?: boolean;
  }>(),
  {
    withActions: true,
  },
);

const { t } = useI18n();
const router = useRouter();
const smeOnboardingState = useLocalStorage(SME_ONBOARDING_STATE_STORAGE_KEY, {
  hasVisitedDashboardTour: false,
  hasVisitedUserGroups: false,
  hasVisitedCourseLibrary: false,
  hasVisitedAssessments: false,
});

const launchAccordionContent = ref<LaunchAccordion>();

onMounted(async () => {
  if (!smeOnboardingState.value.hasVisitedDashboardTour)
    actionMap.startDashboardTour();
  launchAccordionContent.value = await fetchLaunchAccordion();
});

const status = computed(() => {
  const completed = Object.values(smeOnboardingState.value).every(Boolean);
  const notStarted = Object.values(smeOnboardingState.value).every(
    (value) => !value,
  );
  const inProgress =
    !completed && Object.values(smeOnboardingState.value).some(Boolean);

  return {
    notStarted,
    inProgress,
    completed,
  };
});

const actionMap: Record<LaunchAccordionItemAction, () => void> = {
  startDashboardTour: () => {
    smeOnboardingState.value.hasVisitedDashboardTour = true;
    trackMixPanelEvent("launchSuccessPlanStep", {
      context: "Launch Page",
      success_plan_step: 1,
      success_plan_name: "Start Dashboard Tour",
    });
    router.push({
      name: "dashboardTour",
    });
  },
  exploreAssessments: () => {
    smeOnboardingState.value.hasVisitedAssessments = true;
    trackMixPanelEvent("launchSuccessPlanStep", {
      context: "Launch Page",
      success_plan_step: 5,
      success_plan_name: "Explore Assessments",
    });
    window.open(
      `${window.location.origin}/${getLocaleFromUrl()}/assessments`,
      "_blank",
      "noopener,noreferrer"
    );
  },
  exploreCourseLibrary: () => {
    smeOnboardingState.value.hasVisitedCourseLibrary = true;
    trackMixPanelEvent("launchSuccessPlanStep", {
      context: "Launch Page",
      success_plan_step: 4,
      success_plan_name: "Explore Course Library",
    });
    window.open(`${window.location.origin}`, "_blank", "noopener,noreferrer");
  },
  inviteTeamMembers: () => {
    smeOnboardingState.value.hasVisitedUserGroups = true;
    trackMixPanelEvent("launchSuccessPlanStep", {
      context: "Launch Page",
      success_plan_step: 3,
      success_plan_name: "Invite Team Members",
    });
    router.push({
      name: "teamActivation",
    });
  },
};

const checkIsDone = (action: LaunchAccordionItemAction) => {
  const actionToStatusMap: Record<LaunchAccordionItemAction, boolean> = {
    startDashboardTour: smeOnboardingState.value.hasVisitedDashboardTour,
    exploreAssessments: smeOnboardingState.value.hasVisitedAssessments,
    exploreCourseLibrary: smeOnboardingState.value.hasVisitedCourseLibrary,
    inviteTeamMembers: smeOnboardingState.value.hasVisitedUserGroups,
  };

  return actionToStatusMap[action];
};
</script>
<template>
  <div v-if="!launchAccordionContent"></div>
  <OnboardingAccordionSection
    v-else
    :title="launchAccordionContent.title"
    :subtitle="launchAccordionContent.subtitle"
    :isOpen="props.isOpen"
    :hasOpenIndicator="false"
    :alwaysOpen="launchAccordionContent.alwaysOpen"
  >
    <template #headerStatus>
      <GBadge
        v-if="props.withActions && !status.notStarted"
        :variant="status.inProgress ? 'beta' : 'new'"
        class="flex items-center gap-1"
      >
        <CheckIcon v-if="status.completed" />
        <ClockIcon v-else class="fill-black" />
        <span>{{
          status.completed
            ? t("launch.accordion.status_completed")
            : t("launch.accordion.status_in_progress")
        }}</span>
      </GBadge>
    </template>
    <AccordionPanelItem
      v-for="item in launchAccordionContent.items"
      :key="item.text"
      :icon="item.icon"
      :title="item.text"
      class="border-b border-grey-10 last:border-b-0"
    >
      <template v-if="props.withActions" #action>
        <GButton
          class="ml-2 w-[120px] font-medium"
          @click="actionMap[item.action]"
          :variant="checkIsDone(item.action) ? 'secondary' : 'primary'"
          size="small"
        >
          <template #prefixIcon v-if="checkIsDone(item.action)">
            <RotateRightIcon />
          </template>
          <span>
            {{
              checkIsDone(item.action)
                ? t("launch.accordion.item_btn_revisit")
                : t("launch.accordion.item_btn_start")
            }}
          </span>
        </GButton>
      </template>
    </AccordionPanelItem>
  </OnboardingAccordionSection>
</template>
