<script setup lang="ts">
import type { GrowthArea } from "../types";

defineProps<{
  growthAreas: Array<GrowthArea>;
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

function updateGrowthArea(growthArea: string) {
  emit("update:modelValue", growthArea);
  document
    .querySelector("#ga-" + growthArea)
    ?.scrollIntoView({ block: "start", inline: "start" });
  document
    .querySelector("#ga-button-" + growthArea)
    ?.scrollIntoView({ block: "start", inline: "start" });
}
</script>

<template>
  <!-- OScan first release was for only one language -->
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <div
    class="sticky top-0 z-10 mb-12 flex w-full justify-between gap-6 overflow-x-auto bg-white py-4 no-scrollbar after:sticky after:inset-y-0 after:bg-white after:[content:''] after:[mask-image:linear-gradient(to_left,_black_calc(100%_-_48px),_transparent_100%)]"
  >
    <!-- <div class="w-4 absolute inset-y-0 right-0 bg-[linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))] z-20"></div> -->
    <button class="whitespace-nowrap">Overview</button>
    <button
      v-for="(growthArea, index) of growthAreas"
      :key="index"
      :id="`ga-button-${growthArea.name.replace(/[^A-Za-z0-9]/g, '')}`"
      class="whitespace-nowrap text-left text-sm"
      :class="
        modelValue === growthArea.name.replace(/[^A-Za-z0-9]/g, '') &&
        'border-b-2 border-primary'
      "
      @click="updateGrowthArea(growthArea.name.replace(/[^A-Za-z0-9]/g, ''))"
    >
      {{ growthArea.name }}
    </button>
  </div>
</template>
