<script setup lang="ts">
import { computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { initializeAnalytics } from "../services/analytics";
import { useUiStore, useAssessmentsStore, useUserStore } from "../stores";
import type { LoggedInUser } from "../types/websession";

const uiStore = useUiStore();
const userStore = useUserStore();
const asmStore = useAssessmentsStore();

const { user } = storeToRefs(userStore);

watch(
  user,
  async (updatedUser) => {
    if (updatedUser) {
      try {
        const asmToken = await asmStore.getAsmToken();
        if (asmToken) await asmStore.fetchAsmRights();
        initializeAnalytics(updatedUser as unknown as LoggedInUser);
      } catch (error) {
        uiStore.initializationError = error as Error;
      }
    }
  },
  { immediate: true },
);

const isLayerReady = computed(() => user.value);
</script>
<template>
  <slot v-if="isLayerReady" :user="user as unknown as LoggedInUser"></slot>
</template>
