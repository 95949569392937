<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { GButton, GModal, GRadioGroup, GDropdown } from "@/components";
import type {
  ExportTableData,
  ExportType,
  FormatLtiDataOptions,
} from "@/types/export";
import { exportToCSV, exportToExcel, formatLtiData } from "@/utils/export";
import { useExportStore, useUserStore } from "@/stores";
import { GH_COMPANY_ID } from "@/constants";

const { t } = useI18n();
const exportStore = useExportStore();
const {
  contentType,
  selectedExportType,
  selectedFileFormat,
  fileFormatOptions,
  shouldIncludeMetadata,
} = storeToRefs(exportStore);
const { exportTypeOptions } = exportStore;

type ExportModalProps = {
  isOpen: boolean;
  ltiFileName: string;
  data: FormatLtiDataOptions;
  exportTypes: ExportType[];
};

const props = defineProps<ExportModalProps>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "exportToScorm"): void;
  (event: "exportToLtiSuccess"): void;
}>();

const ltiData = computed<ExportTableData>(() => {
  return formatLtiData(props.data);
});

const isExportButtonDisabledForGoodhabitzUser = computed(() => {
  const userStore = useUserStore();
  const companyId = userStore.user?.CompanyId ?? 0;
  return selectedExportType.value === "scorm" && companyId === GH_COMPANY_ID;
});

const exportContent = async () => {
  if (!ltiData.value.rows.length) return;
  if (selectedExportType.value === "scorm") {
    emit("exportToScorm");
  } else {
    if (selectedFileFormat.value === "csv") {
      exportToCSV(ltiData.value, props.ltiFileName);
    } else if (selectedFileFormat.value === "excel") {
      await exportToExcel(ltiData.value, props.ltiFileName);
    }
    emit("exportToLtiSuccess");
  }
  emit("close");
};

const router = useRouter();
const viewInBrowser = (name: string, data: ExportTableData) => {
  sessionStorage.setItem(name, JSON.stringify(data));
  const route = router.resolve({
    name: "exportDataTable",
    params: { type: name },
  });
  window.open(route.href, "_blank");
};
</script>
<template>
  <GModal
    :isOpen="isOpen"
    scrollBehaviour="modal"
    :title="t('contentSelection.export_modal_title')"
    class="md:w-[25rem]"
    @close="$emit('close')"
    data-testId="export-modal"
  >
    <GDropdown
      :options="exportTypeOptions(exportTypes)"
      :value="selectedExportType"
      @change="(value) => (selectedExportType = value as ExportType)"
      class="border-b pb-4"
      :label="t('contentSelection.export_type')"
    />

    <div
      v-if="isExportButtonDisabledForGoodhabitzUser"
      class="mt-2 w-full rounded-lg bg-yellow-400 px-4 py-3 align-middle"
    >
      <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
      <span> Remote SCORM is not available to goodhabitz employees </span>
    </div>
    <slot></slot>

    <GRadioGroup
      class="mb-6"
      v-model="selectedFileFormat"
      v-if="
        ((contentType === 'courses' || contentType === 'assessments') &&
          shouldIncludeMetadata) ||
        selectedExportType !== 'scorm'
      "
      :options="fileFormatOptions"
      name="selectedFileFormat"
      ><template #label>
        {{
          selectedExportType === "scorm"
            ? t("contentSelection.metadata_format")
            : t("contentSelection.format")
        }}
      </template>
    </GRadioGroup>

    <template #actions>
      <div class="flex flex-col items-center gap-2">
        <GButton
          class="w-full"
          @click="exportContent"
          :disabled="isExportButtonDisabledForGoodhabitzUser"
          >{{ t("contentSelection.export") }}</GButton
        >
        <template v-if="selectedExportType !== 'scorm'">
          {{ t("common.or").toUpperCase() }}
          <button
            class="text-primary underline"
            @click="() => viewInBrowser(ltiFileName, ltiData)"
          >
            {{ t("contentSelection.links_in_browser") }}
          </button>
        </template>
      </div>
    </template>
  </GModal>
</template>
