import { getStartingLocale } from "./i18n";

export const redirectToOldDashboardPage = (
  oldDashboardUrl: string | undefined | null
) => {
  const locale = getStartingLocale();
  window.location.href = oldDashboardUrl
    ? `/${locale}${oldDashboardUrl}`
    : `/${locale}/dashboard/dashboard.html`;
};
