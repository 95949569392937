<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GButton } from '@/components';
import GModal from "../GModal/GModal.vue";
import { useLocale } from "@/composables";

const { t } = useI18n();
const {locale} = useLocale();
</script>
<template>
  <div class="h-full w-full">
    <img src="@/assets/images/blurry-table.png" alt="" class="h-full w-full" />
  </div>
  <GModal :isOpen="true" :hasCloseButton="false" :closeOnOutsideClick="false" :closeOnEscape="false"
    :title="t('access_denied.modal.title')">
    {{ t('access_denied.modal.description') }}
    <template #actions>
      <GButton variant="link" :href="`/${locale}/dashboard/dashboard.html`">{{ t('access_denied.modal.secondary_action') }}</GButton>
      <GButton :href="`https://goodhabitz.com/${locale}/contact`">{{ t('access_denied.modal.primary_action') }}</GButton>
    </template>
  </GModal>
</template>