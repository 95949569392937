import { useLocale } from "@/composables/useLocale";
import { getToken } from "@/utils/getToken";
import { useFetch } from "@vueuse/core";
import { computed, watch, type Ref } from "vue";

export const useSectionFetch = <TResponse>(
  endpointName: string,
  startDate: Ref<number>,
  endDate: Ref<number>
) => {
  const { locale } = useLocale();

  const baseUrl = computed(
    () =>
      `${import.meta.env.VITE_GH_ASM_API}/api/${endpointName}/${
        startDate.value
      }/${endDate.value}`
  );

  const { execute, data, isFetching, error } = useFetch(baseUrl, {
    async beforeFetch({ url, options }) {
      url = baseUrl.value;
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${getToken()}`,
        locale: ["en-gb", "nl-nl"].includes(locale.value)
          ? locale.value
          : "en-GB",
      };
      return {
        options,
        url,
      };
    },
  }).json<TResponse>();

  watch([startDate, endDate], () => {
    execute();
  });

  return { execute, data, isFetching, error };
};
