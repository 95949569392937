<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { GButton, GCard } from "@/components";

import type { Lti11Key } from "./Lti11Tab.vue";
import type { Lti13Key } from "./Lti13Tab.vue";
import type { ScormKey } from "./ScormTab.vue";

import PenIcon from "@/assets/images/icons/pen.svg";

const { t } = useI18n();

const props = defineProps<{
  connectionKey?: Lti11Key | Lti13Key | ScormKey;
  isInEditMode: boolean;
  title: string;
  isSubmitting?: boolean;
}>();

const emit = defineEmits<{
  (e: "save"): void;
  (e: "delete"): void;
  (e: "edit"): void;
  (e: "cancel"): void;
}>();
</script>
<template>
  <GCard hasBorder>
    <form @submit.prevent="emit('save')" class="flex h-full flex-col">
      <h4 class="mb-4 flex-1 text-xl">
        {{ title }}
      </h4>
      <slot></slot>
      <div class="mt-6 flex justify-end gap-2">
        <template v-if="props.isInEditMode">
          <GButton @click.prevent="emit('delete')" variant="link_danger">{{
            t("connectionMethods.delete_key")
          }}</GButton>
          <GButton variant="link" class="mx-4" @click.prevent="emit('cancel')">
            {{ t("connectionMethods.cancel") }}
          </GButton>
          <GButton
            type="submit"
            :disabled="props.isSubmitting"
            :isLoading="props.isSubmitting"
          >
            {{ t("connectionMethods.save_key") }}
          </GButton>
        </template>
        <GButton v-else @click="emit('edit')" variant="link">
          <template #prefixIcon>
            <PenIcon class="fill-white" />
          </template>
          {{ t("common.edit") }}</GButton
        >
      </div>
    </form>
  </GCard>
</template>
