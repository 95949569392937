<template>
  <div class="bicolor-text font-bold">
    <slot />
  </div>
</template>

<style>
.bicolor-text em {
  color: var(--bicolor-em, #5a1eec);
  font-style: normal;
}

.bicolor-text > * {
  font-size: inherit;
}
</style>
