<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { GAlert, GButton, GModal, TextInput } from "@/components";
import { useUserActionsStore } from "../store";
import { storeToRefs } from "pinia";

const confirmationText = ref("");
const { selectedUsers, anonymiseUsersByIds, closeModal } =
  useUserActionsStore();
const { activeAction } = storeToRefs(useUserActionsStore());
const { t } = useI18n();
</script>
<template>
  <GModal
    :isOpen="activeAction === 'anonymiseUser'"
    scrollBehaviour="modal"
    :title="t('organisation.anonymise_user(s)', selectedUsers.length || 1)"
    @close="closeModal()"
    data-testId="anonymise-modal"
    class="max-w-[30rem]"
  >
    <div class="grid gap-6">
      <p class="text-grey-80">
        {{ t("organisation.modal_anonymise_description") }}
      </p>
      <GAlert
        :dismissible="false"
        variant="danger"
        :message="t('organisation.modal_anonymise_warning')"
      />
      <i18n-t
        keypath="organisation.modal_anonymise_confirm"
        tag="label"
        class="mb-2"
      >
        <template #input>
          <span class="font-medium uppercase">{{
            t("organisation.anonymise").toUpperCase()
          }}</span>
        </template>
      </i18n-t>
    </div>
    <TextInput
      name="anonymise-confirm-text"
      v-model="confirmationText"
      label=""
      aria-labelledby="anonymise_confirm"
    />
    <template #actions>
      <GButton variant="link" @click="closeModal">
        {{ t("common.cancel") }}
      </GButton>
      <GButton
        variant="danger"
        @click="anonymiseUsersByIds"
        :disabled="
          confirmationText.toLocaleLowerCase() !==
          t('organisation.anonymise').toLocaleLowerCase()
        "
      >
        {{ t("organisation.anonymise") }}
      </GButton>
    </template>
  </GModal>
</template>
