import type { ExportTableData, FormatLtiDataOptions } from "@/types/export";
import { getToken } from "./getToken";

export const exportToExcel = async (
  data: ExportTableData,
  fileName: string,
) => {
  const properties = {
    sheetname: "Export",
    title: "",
    author: "",
    comment: "",
    company: "",
    category: "",
  };

  const tableData = {
    general: properties,
    headerCollection: data.headers.map((header) => {
      return { name: header };
    }),
    rowCollection: data.rows.map((row) => {
      return {
        cell: row.map((value) => {
          return { value: value ?? "" };
        }),
      };
    }),
  };

  const response = await fetch(
    `${import.meta.env.VITE_GH_API}/api/ExportToExcel`,
    {
      method: "POST",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(tableData),
    },
  );
  const blobResponse = await response.blob();
  const url = URL.createObjectURL(blobResponse);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const exportToCSV = (data: ExportTableData, fileName: string) => {
  const tableRows = [data.headers, ...data.rows];
  const stringToDownload = tableRows
    .map((row) =>
      row
        .map((field: string | number) =>
          typeof field === "string" ? `"${field.replace(/,/g, " | ")}"` : field,
        )
        .join(","),
    )
    .join("\r\n");
  const blob = new Blob(["\ufeff", stringToDownload], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const formatLtiData = ({
  dataSource,
  columns,
}: FormatLtiDataOptions): ExportTableData => {
  const headers = columns.map((column) => column.header);
  const rows = dataSource.map((row) => {
    return columns.map((column) => {
      if (typeof column.formatValue === "function") {
        return column.formatValue(row[column.id], row);
      }

      return row[column.id]?.toString() ?? "";
    });
  });
  return { headers, rows };
};

export type ExportDataInput<T extends Record<string, unknown>> = {
  columns: Array<{
    header: string;
    accessor: keyof T;
    condition?: boolean;
  }>;
  data: T[];
};

/**
 * Formats data for export by transforming it into a table structure with headers and rows
 *
 * @template T - Generic type extending `Record<string, unknown>` representing the data structure
 * @param {ExportDataInput<T>} params - The input parameters
 * @param {Array<{header: string, accessor: keyof T, condition?: boolean}>} params.columns - Array of column definitions
 *   - header: The display text for the column header
 *   - accessor: The property key to access data from each row
 *   - condition: Optional boolean to conditionally include/exclude the column
 * @param {T[]} params.data - Array of data objects to be formatted
 * @returns {ExportTableData} Formatted table data with headers and rows
 *   - headers: Array of column header strings
 *   - rows: 2D array containing the formatted data values
 */
export const formatDataForExport = <T extends Record<string, unknown>>({
  columns,
  data,
}: ExportDataInput<T>): ExportTableData => {
  if (columns.length === 0) {
    return { headers: [], rows: [] };
  }

  const visibleColumns = columns.filter((col) => col.condition ?? true);
  const headers = visibleColumns.map((col) => col.header);
  const rows = data.map((item) => {
    return visibleColumns.map((column) => {
      const value = item[column.accessor];

      if (
        typeof value === "string" ||
        typeof value === "number" ||
        value instanceof Date
      ) {
        return value;
      }

      return value?.toString() ?? "";
    });
  });

  return { headers, rows };
};
