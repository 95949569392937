<script
  setup
  lang="ts"
  generic="
    TIsMultiChoice extends boolean = false,
    TExcludeRegion extends boolean = false
  "
>
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import type { LanguageCode, LanguageCodeWithoutRegion } from "@/utils/i18n";
import { useWebSessionStore } from "@/stores";
import { GDropdown } from "@/components";
import { computed } from "vue";



/**
 * This determines the type of LocaleValue based on the generic parameters TIsMultiChoice and TExcludeRegion.
 * If TIsMultiChoice is true:
 *  - If TExcludeRegion is true, LocaleValue is an array of LanguageCodeWithoutRegion.
 *  - otherwise, LocaleValue is an array of LanguageCode.
 * If TIsMultiChoice is false:
 *  - If TExcludeRegion is true, LocaleValue is LanguageCodeWithoutRegion.
 *  - otherwise, LocaleValue is LanguageCode.
 */
type LocaleValue = TIsMultiChoice extends true
  ? TExcludeRegion extends true
    ? LanguageCodeWithoutRegion[]
    : LanguageCode[]
  : TExcludeRegion extends true
    ? LanguageCodeWithoutRegion
    : LanguageCode;

const { t } = useI18n();
const { languageOptionsWithRegionValues, languageOptions } =
  storeToRefs(useWebSessionStore());

const props = defineProps<{
  excludeRegion?: TExcludeRegion;
  isMultiChoice?: TIsMultiChoice;
  hasDefault?: boolean;
}>();


const options = computed(() => {
  if(props.excludeRegion) {
    return languageOptions.value.map((option) => ({
      label: option.label,
      value: option.value.toLowerCase(),
    }))
  }
  return languageOptionsWithRegionValues.value.map((option) => ({
    label: option.label,
    value: option.value.toLowerCase(),
  }))
})

const value = defineModel<LocaleValue>({
  required: true,
})
</script>
<template>
  <GDropdown
    :options="options"
    :value="value"
    :label="t('common.select_language')"
    :isMultiChoice="props.isMultiChoice"
    :hasDefault="props.hasDefault ? props.hasDefault : props.isMultiChoice"
    searchable
    @change="value = $event as LocaleValue"
  />
</template>
