<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { storeToRefs } from "pinia";

import { useUserActionsStore } from "../store";
import { useI18n } from "vue-i18n";

import CloseIcon from "@/assets/images/icons/close.svg";

const props = defineProps<{
  isOpen: boolean;
}>();

defineEmits<{
  (event: "close"): void;
}>();

const { t } = useI18n();

const actionBar = ref<HTMLDialogElement>();
const userActionStore = useUserActionsStore();
const { getAvailableActions } = userActionStore;
const { selectedUsers } = storeToRefs(userActionStore);

watchEffect(() => {
  if (actionBar.value) {
    props.isOpen ? actionBar.value.show() : actionBar.value.close();
  }
});
</script>
<template>
  <dialog
    ref="actionBar"
    class="action-dialog fixed bottom-20 z-20 rounded-lg bg-primary p-3"
  >
    <div
      class="flex place-items-center gap-2 border-r border-blue-70 px-4 py-2 text-white"
    >
      <span class="my-auto" v-if="selectedUsers?.length > 0">
        {{ selectedUsers.length }} {{ t("common.selected") }}
      </span>
      <CloseIcon
        class="h-4 w-4 cursor-pointer"
        @click="() => (selectedUsers = [])"
        :title="t('common.close')"
        data-testid="close-modal-button"
      />
    </div>
    <button
      v-for="{ label, action } in getAvailableActions(
        selectedUsers.map((item) => item.status),
      )"
      :key="label"
      @click="action"
      class="p-2 text-white hover:underline"
    >
      {{ label }}
    </button>
  </dialog>
</template>
<style>
.action-dialog[open] {
  opacity: 1;
  display: flex;
  transform: translateY(0);
}
.action-dialog {
  opacity: 0;
  display: none;
  transform: translateY(70%);
  transition:
    opacity 0.3s,
    transform 0.3s,
    overlay 0.3s allow-discrete,
    display 0.3s allow-discrete;
}
@starting-style {
  .action-dialog[open] {
    opacity: 0;
    transform: translateY(70%);
  }
}
</style>
