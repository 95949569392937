<script setup lang="ts">
import { GFlag, InsightCard } from "@/components";
import { useWebSessionStore } from "@/stores";

import { useI18n } from "vue-i18n";

const props = defineProps<{
  isLoading: boolean;
  courseLanguages: Array<{
    language: string;
    count: number;
  }>;
}>();
const { t } = useI18n();
const { getLanguageNameByLanguageCode, getCountryCodeByLanguageCode } =
  useWebSessionStore();
</script>
<template>
  <InsightCard
    :isLoading="props.isLoading"
    :title="t('courseDetails.course_lng_list_title')"
    :subtitle="t('courseDetails.course_lng_list_subtitle')"
  >
    <div class="flex max-h-7 flex-col gap-2">
      <ul>
        <li class="border-b p-2 pt-0 text-end text-sm">
          {{ t("courseDetails.course_lng_list_items_title") }}
        </li>
        <li
          v-for="(courseLanguage, index) in props.courseLanguages"
          :key="courseLanguage.language"
          class="flex items-center justify-between border-b p-2 text-sm last:border-b-0"
        >
          <div class="grid grid-cols-[24px_24px_1fr] gap-2">
            <span>{{ index + 1 }}</span>
            <GFlag
              :countryCode="
                getCountryCodeByLanguageCode(courseLanguage.language)
              "
            />
            <span>{{
              getLanguageNameByLanguageCode(courseLanguage.language)
            }}</span>
          </div>
          <span>
            {{ courseLanguage.count }}
          </span>
        </li>
      </ul>
    </div>
  </InsightCard>
</template>
