import { q, sanityImage } from "groqd";

import { runSafeQuery } from "@/services/sanity";

export const fetchTeamActivationSection = async () => {
  return await runSafeQuery(
    q("*", { isArray: true })
      .filterByType("onboardingSection")
      .filter("sectionName == 'Team Activation'")
      .grab({
        pages: q("pages")
          .filter()
          .deref()
          .grab({
            id: ["_id", q.string()],
            startSection: q.object({
              title: q.object({
                text: q.string(),
              }),
              heading: q.string(),
              hint: q.string().optional(),
              description: q.string(),
              nextButtonLabel: q.string(),
            }),
            endSection: q("endSection").grab$({
              title: q.string().optional(),
              subtitle: q.string().optional(),
              content: q("content[]", { isArray: true }).select({
                '_type == "image"': {
                  _type: q.literal("image"),
                  image: sanityImage("", { withAsset: ["base"] }),
                },
                '_type == "resourceCardsList"': {
                  _type: q.literal("resourceCardsList"),
                  resourceCards: q("")
                    .grab$({
                      title: q.string(),
                      cards: q("cards", { isArray: true }),
                    })
                    .grab$({
                      title: q.string().optional(),
                      cards: q("cards[]", { isArray: true }).grab({
                        title: q.string(),
                        languages: q.array(q.string()),
                        link: q.string(),
                        img: sanityImage("img", {
                          isList: false,
                          withAsset: ["base"],
                        }).grab({
                          url: ["asset.url", q.string()],
                        }),
                      }),
                    }),
                },
                '_type == "muxVideo"': {
                  _type: q.literal("muxVideo"),
                  muxVideo: q("").grab$({
                    title: q.string().optional(),
                    muted: q.boolean().optional(),
                    autoplay: q.boolean().optional(),
                    video: q("video.asset")
                      .deref()
                      .grab({ playbackId: q.string() }),
                  }),
                },
              }),
            }),
          }),
      }),
  );
};

export type TeamActivationSectionResult = Awaited<
  ReturnType<typeof fetchTeamActivationSection>
>;
