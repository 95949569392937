import { computed, onMounted, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useWebSessionStore, useCourseStore } from "@/stores";
import { useLocale } from "@/composables";
import {
  dateFilter,
  multiSelectLanguageFilter,
  multiSelectFilter,
} from "@/composables/useFilter";
import type { TableColumn } from "@/patterns";
import { formatLanguageRegion } from "@/utils/misc";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { NODE_TYPE_IDS } from "@/constants/course";

type ColumnAccessor =
  | "thumbnail"
  | "title"
  | "name"
  | "dateOfPublication"
  | "language"
  | "categoryId";

export type CourseTableDataRow = {
  id: number;
  guid: string;
  courseCode: string;
  title: string;
  name: string;
  language: string;
  thumbnail: string;
  categoryId: number;
  dateOfPublication: Date;
  courseName: string;
  tags: string;
  description: string;
  containerId: number;
  courseAreaId: number;
  imageIcon: string;
  trainingType: string;
  testAvailable: boolean;
  nodeTypeId: number;
  image_square: string;
  imageSquareNoText: string;
  lti11Link: string;
  lti13Link: string;
  courseLink: string;
  publicationStatus: number;
  mobileFriendly: string;
};

export const useCoursesTab = () => {
  const { locale } = useLocale();

  const websessionStore = useWebSessionStore();
  const { languageOptions, languageOptionsWithRegionValues } =
    storeToRefs(websessionStore);

  const { t } = useI18n();
  const coursesTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "thumbnail",
      headerLabel: t("common.thumbnail"),
      accessor: "thumbnail",
      sortable: false,
      smallScreenPosition: "last",
    },
    {
      id: "title",
      headerLabel: t("common.name"),
      accessor: "title",
      isGloballyFilterable: true,
      smallScreenPosition: "center",
    },
    {
      id: "name",
      headerLabel: t("common.name"),
      accessor: "name",
      visibleFrom: false,
      isGloballyFilterable: true,
    },
    {
      id: "language",
      headerLabel: t("common.language"),
      accessor: "language",
      filterFnName: multiSelectLanguageFilter,
    },
    {
      id: "dateOfPublication",
      headerLabel: t("common.publication_date"),
      accessor: "dateOfPublication",
      filterFnName: dateFilter,
    },
    {
      id: "categoryId",
      headerLabel: t("common.category"),
      accessor: "categoryId",
      filterFnName: multiSelectFilter,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const courseStore = useCourseStore();
  const { exportableCourses, isFetching, error } = storeToRefs(courseStore);
  const { getCategoryName, getCategoryOptions, getCourseLink } = courseStore;

  onMounted(() => {
    courseStore.fetchCoursesDetails();
  });

  const categoryOptions = computed(() =>
    getCategoryOptions(exportableCourses.value),
  );

  const courseList: ComputedRef<CourseTableDataRow[]> = computed(() =>
    exportableCourses.value.map((course) => ({
      id: course.CourseId,
      guid: course.CourseGuid,
      courseCode: course.CourseCode,
      title: course.properties.title,
      name: course.CourseName,
      language: formatLanguageRegion(
        languageOptions.value.find(
          (option) => option.value === course.Lang.toUpperCase(),
        )?.region || "",
      ),
      thumbnail: course.properties.image_landscape,
      categoryId: course.categoryId,
      dateOfPublication: new Date(course.PublishDate),
      courseName: course.CourseName,
      tags: course.AllTags,
      description: course.properties.coursedescription_new,
      containerId: course.CourseContainerId,
      courseAreaId: course.CourseAreaId,
      imageIcon: course.properties.image_icon,
      trainingType: course.properties.training_type,
      testAvailable: course.TestAvailable,
      nodeTypeId: course.NodeTypeId,
      image_square: course.properties.image_square,
      imageSquareNoText: course.properties.image_square_no_text,
      imageLandscape: course.properties.image_landscape,
      lti11Link: `${import.meta.env.VITE_GH_TOOL_DOMAIN}/int-lti/${
        course.CourseCode
      }`,
      lti13Link: `my.goodhabitz.com/${locale.value}/course/${course.CourseGuid}`,
      courseLink: getCourseLink(
        course.CourseId,
        course.DocumentName,
        course.Lang,
      ),
      publicationStatus: course.PublicationStatus,
      mobileFriendly: course.NodeTypeId === NODE_TYPE_IDS.COURSE ? "Yes" : "No",
    })),
  );

  const trackExportCourses = (
    selectedActivities: CourseTableDataRow[],
    isMultiLanguage: boolean,
  ) => {
    trackMixPanelEvent("coursesExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.title,
      })),
      isMultiLanguage: isMultiLanguage,
      context: "Connect Page",
    });
  };

  return {
    languageOptionsWithRegionValues,
    categoryOptions,
    courseList,
    coursesTableDefinition,
    trackExportCourses,
    isFetching,
    error,
    getCategoryName,
  };
};
