<script setup lang="ts">
import { GButton, GModal } from "@/components";
import { useI18n } from "vue-i18n";
import useUserActionsStore from "../store";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const userActionsStore = useUserActionsStore();
const { activeAction, isActionRequestPending } = storeToRefs(userActionsStore);
const { revokeInvitationsByIds, closeModal } = userActionsStore;
</script>
<template>
  <GModal
    :isOpen="activeAction === 'revokeInvitation'"
    :title="t('organisation.revoke_invitation')"
    @close="closeModal"
  >
    {{ t("organisation.modal_revoke_invitation_description") }}
    <template #actions>
      <GButton variant="link" @click="closeModal">{{
        t("common.cancel")
      }}</GButton>
      <GButton
        :isLoading="isActionRequestPending"
        @click="revokeInvitationsByIds()"
        >{{ t("organisation.revoke") }}</GButton
      >
    </template>
  </GModal>
</template>
