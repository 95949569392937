<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
<template>
  <div
    class="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-gray-200 border-t-primary border-l-primary"
  >
    <span class="sr-only">{{ t("common.loading") }}</span>
  </div>
</template>
