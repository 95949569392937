<script setup lang="ts">
import { ref, onErrorCaptured, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import { GButton, GEmptyState } from "@/components";
import { useLocale } from "@/composables/useLocale";
import { useUiStore } from "@/stores";

import errorPageImage from "@/assets/images/error-page-image.svg?url";
import RotateRightIcon from "@/assets/images/icons/rotate-right.svg";

const { t } = useI18n();
const { locale } = useLocale();

const uiStore = useUiStore();
const { initializationError } = storeToRefs(uiStore);

const hasError = ref(false);

onErrorCaptured((err) => {
  if (import.meta.env.DEV) console.error(err);
  hasError.value = true;

  return false;
});

watch(initializationError, () => {
  if (initializationError.value) console.error(initializationError.value);
});

const handleTryAgain = () => {
  window.location.reload();
};
</script>
<template>
  <div
    v-if="initializationError || hasError"
    class="grid h-full place-content-center"
  >
    <GEmptyState
      :title="t('error.title')"
      :description="t('error.message')"
      :imageUrl="errorPageImage"
    >
      <template #actions>
        <div class="grid grid-cols-2 gap-3 font-medium">
          <GButton loading-icon-position="start" @click="handleTryAgain()">
            <template #prefixIcon>
              <RotateRightIcon />
            </template>
            <span>{{ t("error.btn_action_1") }}</span>
          </GButton>
          <GButton variant="link" :href="`/${locale}/dashboard/dashboard.html`">
            {{ t("common.back_to_dashboard_overview") }}
          </GButton>
        </div>
      </template>
    </GEmptyState>
  </div>
  <slot v-else />
</template>
