<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { GButton, GStateHandlerWrapper } from "@/components";
import { FilterPanel, LanguageSelect, GTable } from "@/patterns";
import ExportModal from "../components/ExportModal.vue";
import { useLessonsTab } from "./useLessonsTab";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import ExternalLinkIcon from "@/assets/images/icons/external-link.svg";
import { useExportStore, useFlagStore } from "@/stores";
import { storeToRefs } from "pinia";
import type { LessonsFilters } from "./types";

const { t } = useI18n();
const exportStore = useExportStore();
const { shouldExportMultiLanguage, selectedExportType } =
  storeToRefs(exportStore);
const filters = ref<LessonsFilters>({
  language: "en-gb",
  search: "",
});
const flagStore = useFlagStore();
const { multiLangSCOExportLessonFlag } = storeToRefs(flagStore);
const {
  lessonTableDefinition,
  isFetching,
  lessonData,
  lessonError,
  selectedRows,
  exportableData,
  exportToScorm,
  trackExportLessons,
} = useLessonsTab(filters);

const defaultSort = [{ desc: true, id: "publicationDate" }];

const isExportModalOpen = ref(false);

const showMultiLanguageScoExport = computed(() => {
  return (
    selectedExportType.value === "scorm" && multiLangSCOExportLessonFlag.value
  );
});

const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
  }
};

const handleLTIDownloaded = () => {
  trackExportLessons(selectedRows.value);
  selectedRows.value = [];
};
</script>

<template>
  <GStateHandlerWrapper :isLoading="isFetching" :error="lessonError">
    <GButton
      class="mb-4 ml-auto lg:px-12"
      @click="onStartExport"
      :disabled="selectedRows.length === 0"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>

    <FilterPanel v-model:search="filters.search">
      <template #primaryFilters>
        <LanguageSelect
          :modelValue="filters.language"
          @update:modelValue="filters.language = $event"
        />
      </template>
    </FilterPanel>
    <GTable
      v-if="lessonData"
      data-testid="lessons-export-table"
      :data="lessonData"
      :columns="lessonTableDefinition"
      :filter="{
        columns: [
          {
            id: 'language',
            value: filters.language,
          },
          {
            id: 'title',
            value: filters.search,
          },
        ],
      }"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :default-sort="defaultSort"
    >
      <template v-slot:title="cellProps">
        {{ cellProps.value }}
      </template>
      <template v-slot:imageUrl="cellProps">
        <img
          :src="cellProps.value as string"
          :alt="cellProps.row?.title"
          class="w-14 min-w-14 object-cover"
        />
      </template>
      <template v-slot:publicationDate="cellProps">
        {{ (cellProps.value as Date).toLocaleDateString() }}
      </template>
      <template v-slot:actions="cellProps">
        <a
          :href="cellProps.row.lessonUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="flex w-max items-center border-b border-transparent text-primary hover:border-b hover:border-dotted hover:border-primary"
        >
          <span class="mr-1">{{ t("common.open") }}</span>
          <ExternalLinkIcon class="h-4 w-4" />
        </a>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <ExportModal
    :isOpen="isExportModalOpen"
    ltiFileName="gh-lessons-export"
    :data="exportableData"
    :exportTypes="['lti', 'scorm']"
    @close="isExportModalOpen = false"
    @exportToScorm="exportToScorm"
    @exportToLtiSuccess="handleLTIDownloaded"
  >
    <span v-if="showMultiLanguageScoExport" class="flex items-center gap-2"
      ><input
        type="checkbox"
        v-model="shouldExportMultiLanguage"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.export_multi_lang") }}</span
    >
  </ExportModal>
</template>
