<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant?: "success" | "danger" | "beta" | "new" | "default";
    size?: "small" | "medium";
  }>(),
  {
    variant: "new",
    size: "medium",
  }
);

const colorClasses = {
  new: "bg-green text-black",
  beta: "bg-warning text-black",
  danger: "bg-category-productivity-10 text-category-productivity-100",
  success: "bg-category-management-10 text-category-management-100",
  default: "bg-grey-5 text-black",
};

const sizeClasses = {
  medium: "py-1 px-3 text-sm",
  small: "py-0.5 px-2 text-xs",
};
</script>
<template>
  <span
    class="rounded"
    :class="`${colorClasses[props.variant]} ${sizeClasses[props.size]}`"
  >
    <slot></slot>
  </span>
</template>
