<script setup lang="ts">
import {
  GAppLoader,
  GErrorBoundary,
  GToast,
  GCookieBanner,
} from "../components";
import { useUiStore, useToastStore } from "../stores";
import App from "./App.vue";
import AppInitializer from "./AppInitializer.vue";
import AppLDInitializer from "./AppLDInitializer.vue";

const uiStore = useUiStore();
const toastStore = useToastStore();
</script>
<template>
  <GErrorBoundary>
    <Transition name="fade" appear>
      <GAppLoader v-if="uiStore.isLoading" />
    </Transition>
    <AppInitializer v-slot="{ user }">
      <AppLDInitializer :user="user">
        <App />
      </AppLDInitializer>
    </AppInitializer>
    <GCookieBanner />
    <TransitionGroup name="toast" tag="div" class="fixed bottom-4 right-4 z-50">
      <GToast
        v-for="toast in toastStore.toasts"
        :key="toast.id"
        :toastContent="toast"
      ></GToast>
    </TransitionGroup>
  </GErrorBoundary>
</template>
<style>
.toast-move,
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease-in-out;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-leave-active {
  position: absolute;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (prefers-reduced-motion) {
  .toast-move,
  .toast-enter-active,
  .toast-leave-active {
    transition: none;
  }
}
</style>
