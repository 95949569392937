import {
  trackMixPanelEvent,
  type MixpanelEventName,
  type MixpanelContextName,
} from "@/services/analytics/mixpanel";

export const onboardingSteps = {
  successPlan: {
    name: "Success Plan",
    order: 1,
  },
  overview: {
    name: "Overview",
    order: 2,
  },
  dashboard: {
    name: "Dashboard",
    order: 3,
  },
  reports: {
    name: "Reports",
    order: 4,
  },
  recommendations: {
    name: "Recommendations",
    order: 5,
  },
  resources: {
    name: "Resources",
    order: 6,
  },
  promo: {
    name: "Promo Studio",
    order: 7,
  },
  invite: {
    name: "Invite Your Team",
    order: 8,
  },
  roles: {
    name: "Roles",
    order: 9,
  },
};

export const unknownStep = {
  name: "Unknown",
  order: -1,
};

export type OnboardingStepObj =
  typeof onboardingSteps[keyof typeof onboardingSteps];

const createOnboardingTrackingProperties = ({
  name,
  order,
  linkText,
}: OnboardingStepObj & { linkText: string }) => ({
  page_name: name,
  page_step: `${order}-${Object.keys(onboardingSteps).length}`,
  version: "onboarding-2023-06-01-v1",
  link_text: linkText,
  context: "Onboarding" as MixpanelContextName,
});

export const trackOnboarding = (
  eventName: MixpanelEventName,
  {
    name = "Unknown",
    order = -1,
    linkText = "",
  }: OnboardingStepObj & { linkText?: string }
) => {
  const eventLinkText =
    eventName === "onboardingExit"
      ? "Exit Setup"
      : eventName === "onboardingBack"
      ? "Back"
      : linkText;
  trackMixPanelEvent(
    eventName,
    createOnboardingTrackingProperties({
      name,
      order,
      linkText: eventLinkText,
    })
  );
};
