<script setup lang="ts">
import { computed } from "vue";

type RangeProps = {
  label: string;
  fullWidth?: boolean;
  modelValue: number;
  weightLabels?: [string, string];
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
};
const props = withDefaults(defineProps<RangeProps>(), {
  min: 0,
  max: 100,
  step: 1,
});
defineEmits<{
  (event: "update:modelValue", value: number): void;
}>();

const progress = computed(() => {
  return `${props.modelValue}%`;
});
</script>
<template>
  <label class="inline-block w-full"
    ><span class="label-text mb-3 inline-block font-medium text-black">
      {{ label }}
    </span>
    <div class="flex flex-col gap-2">
      <div v-if="weightLabels" class="flex">
        <span class="font-medium text-black">{{ weightLabels[0] }}</span>
        <span class="ml-auto font-medium text-black">{{
          weightLabels[1]
        }}</span>
      </div>
      <input
        class="h-1 w-full cursor-pointer appearance-none"
        :class="weightLabels ? 'bg-[#FA0ECC]' : 'bg-grey-10'"
        type="range"
        role="slider"
        :step="step"
        :min="min"
        :max="max"
        :value="modelValue"
        :disabled="disabled"
        @input="
          $emit(
            'update:modelValue',
            parseInt(($event.target as HTMLInputElement).value)
          )
        "
      />
      <div class="flex">
        <span>{{ modelValue }}%</span>
        <span v-if="weightLabels" class="ml-auto">{{ max - modelValue }}%</span>
      </div>
    </div>
  </label>
</template>
<style scoped>
input[type="range"] {
  --primary-color: #5a1eec;
  /* Apply slider progress effect  */
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, var(--primary-color)),
    color-stop(100%, var(--primary-color))
  );
  background-size: v-bind(progress) 100%;
  background-repeat: no-repeat;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:disabled {
  @apply bg-grey-30;
  --disabled-color: #484554;
  cursor: not-allowed;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, var(--disabled-color)),
    color-stop(100%, var(--disabled-color))
  );
}

input[type="range"]::-webkit-slider-runnable-track {
  @apply h-2 w-full cursor-pointer rounded-full border-none bg-transparent shadow-none;
  -webkit-appearance: none;
}

input[type="range"]:disabled::-webkit-slider-runnable-track {
  @apply cursor-not-allowed;
}

input[type="range"]::-moz-range-track {
  @apply h-2 w-full cursor-pointer rounded-full border-none bg-transparent shadow-none;
  -webkit-appearance: none;
}

input[type="range"]:disabled::-moz-range-track {
  @apply cursor-not-allowed;
}

input[type="range"]::-webkit-slider-thumb {
  @apply -mt-1 h-4 w-3 cursor-pointer rounded border-2 border-solid  border-blue-130 bg-white;
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  @apply h-4 w-3 cursor-pointer rounded border-2 border-solid  border-blue-130 bg-white;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  @apply cursor-not-allowed border-grey-70;
}

input[type="range"]:disabled::-moz-range-thumb {
  @apply cursor-not-allowed border-grey-70;
}

input[type="range"]:focus::-webkit-slider-thumb {
  @apply outline outline-offset-2;
}

input[type="range"]:focus::-moz-range-thumb {
  @apply outline outline-offset-2;
}
</style>
