<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { GDropdown } from "@/components";
import type { DropdownOption } from "@/components/GDropdown/types";
import { useUserActionsStore } from "../store";
import type { UserActionId } from "../types";

const emit = defineEmits<{
  (event: "open"): void;
  (event: "close"): void;
}>();

const userActionStore = useUserActionsStore();
const { activeAction, selectedUsers } = storeToRefs(userActionStore);
const { getAvailableActions } = userActionStore;

const selectedItemsStatuses = computed(() => {
  return selectedUsers.value.map((item) => item.status);
});

const menuOptions = computed<DropdownOption[]>(() => {
  return getAvailableActions(selectedItemsStatuses.value).map((action) => {
    return {
      label: action.label ?? "",
      value: action.id ?? "",
      isDestructive: action.isDestructive,
    };
  });
});

const dispatchAction = (selectedAction: UserActionId) => {
  return getAvailableActions(selectedItemsStatuses.value)
    .find((action) => action.id === selectedAction)
    ?.action();
};
</script>
<template>
  <GDropdown
    :options="menuOptions"
    :value="activeAction"
    @change="dispatchAction($event as UserActionId)"
    @open="emit('open')"
    @close="emit('close')"
  >
    <template #trigger="{ setElementRef, isMenuOpen }">
      <slot
        name="trigger"
        :setElementRef="setElementRef"
        :isMenuOpen="isMenuOpen"
      />
    </template>
  </GDropdown>
</template>
