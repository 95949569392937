import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatISO, parseISO } from "date-fns";

import { useFetch } from "@/composables";
import type {
  ColumnAccessor,
  CourseMilestoneReport,
  CourseMilestoneTableRow,
  Filters,
} from "./types";
import type { FetchReportPayload } from "../types";
import { useFlagStore } from "@/stores";
import { createTableDef } from "@/patterns";
import { USER_STATUS } from "@/constants/user";
import { getPayloadTags } from "../utils";

export const useCourseMilestonesReport = (filters: Ref<Filters>) => {
  const flagStore = useFlagStore();
  const { t } = useI18n();

  const fetchMilestonesPayload = computed<FetchReportPayload>(() => ({
    CourseMilestoneId: filters.value.milestone ?? -1,
    DateFrom: formatISO(filters.value.date[0], {
      representation: "date",
    }),
    DateTo: formatISO(filters.value.date[1], {
      representation: "date",
    }),
    PersonStatusId: USER_STATUS[filters.value.status] ?? -1,
    SearchFor: filters.value.search,
    Tags: getPayloadTags(filters.value.teams),
    isOptingInNewDashboard: true,
  }));

  const { isFetchingReport, error, milestones } = fetchMilestonesReport(
    fetchMilestonesPayload,
  );

  const hasForeignUsername = computed(
    () =>
      flagStore.reportsForeignUsernameFlag &&
      milestones.value?.some((milestone) => milestone.ForeignUsername),
  );

  const tableDefinition = computed(() =>
    createTableDef<ColumnAccessor>(
      [
        {
          id: "id",
          headerLabel: t("courseMilestonesReport.tbl_col_id"),
          accessor: "id",
          isGloballyFilterable: true,
        },
        {
          id: "title",
          headerLabel: t("courseMilestonesReport.milestone"),
          accessor: "title",
          smallScreenPosition: "center",
        },
        {
          id: "date",
          headerLabel: t("reports.tbl_col_date"),
          accessor: "date",
        },
        {
          id: "courseId",
          headerLabel: t("courseMilestonesReport.tbl_col_course_id"),
          accessor: "courseId",
        },
        {
          id: "courseName",
          headerLabel: t("reports.tbl_col_course_title"),
          accessor: "courseName",
        },
        {
          id: "studentName",
          headerLabel: t("reports.tbl_col_name"),
          accessor: "studentName",
        },
        {
          id: "studentEmail",
          headerLabel: t("reports.tbl_col_email"),
          accessor: "studentEmail",
        },
        {
          id: "foreignUsername",
          headerLabel: t("reports.tbl_col_foreign_username"),
          accessor: "foreignUsername",
        },
      ],
      {
        studentEmail: milestones.value?.some((milestone) => milestone.Email),
        foreignUsername: hasForeignUsername.value,
      },
    ),
  );

  return {
    fetchMilestonesPayload,
    isFetching: isFetchingReport,
    error,
    data: computed(() => mapMileStonesDataToTableData(milestones.value)),
    tableDefinition,
    hasForeignUsername,
  };
};

const mapMileStonesDataToTableData = (
  milestones: CourseMilestoneReport[] | null,
): CourseMilestoneTableRow[] => {
  if (!milestones) return [];

  return milestones.map((milestone) => ({
    id: milestone.CourseMilestoneId,
    title: milestone.CourseMilestone,
    date: parseISO(milestone.Logdate).toLocaleDateString(),
    courseId: milestone.CourseId,
    courseName: milestone.CourseName,
    studentName: `${milestone.LastName}, ${milestone.FirstName} ${milestone.MidName}`,
    studentEmail: milestone.Email,
    foreignUsername: milestone.ForeignUsername,
  }));
};

const fetchMilestonesReport = (payload: Ref<FetchReportPayload>) => {
  const {
    isFetching: isFetchingReport,
    error,
    data: milestones,
  } = useFetch("api/ReportStudentCourseMilestone", {
    refetch: true,
  })
    .post(payload)
    .json<CourseMilestoneReport[]>();

  return { isFetchingReport, error, milestones };
};
