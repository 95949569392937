<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { GButton, GEmptyState } from "@/components";
import noResultFoundImage from "@/assets/images/no-results-found.svg?url";
import ArrowLeftIcon from "@/assets/images/icons/arrow-left.svg";
import { useLocale } from "@/composables";

const { t } = useI18n();
const { locale } = useLocale();
</script>

<template>
  <div class="grid h-full w-full place-content-center">
    <GEmptyState
      :title="t('common.no_results_found')"
      :description="t('common.no_results_found_details')"
      :imageUrl="noResultFoundImage"
    >
      <template #actions>
        <div class="grid grid-cols-2 gap-3 font-medium">
          <RouterLink to="/">
            <GButton>
              <template #prefixIcon>
                <ArrowLeftIcon class="fill-white" />
              </template>
              <span>{{ t("common.dashboard_home") }}</span>
            </GButton>
          </RouterLink>
          <GButton variant="link" :href="`/${locale}/home/redirector`">
            {{ t("error.btn_action_2") }}
          </GButton>
        </div>
      </template>
    </GEmptyState>
  </div>
</template>
