<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { ref } from "vue";
import { useField } from "vee-validate";

type InputProps = {
  name: string;
  type?: string;
  size?: "small" | "large";
  modelValue: string | number;
  label?: string;
  labelClasses?: string[];
  fullWidth?: boolean;
  containerClasses?: string;
  inputClasses?: string;
  disabled?: boolean;
  readonly?: boolean;
  hint?: string;
};

const props = withDefaults(defineProps<InputProps>(), {
  type: "text",
  size: "large",
  inputClasses: "",
});

const { value, errorMessage, handleBlur } = useField(
  () => props.name,
  undefined,
  { syncVModel: true },
);

const input = ref<HTMLInputElement>();
defineExpose({
  focus: () => input.value?.focus(),
});
</script>

<template>
  <div :class="[containerClasses, fullWidth && 'w-full']">
    <label :for="name" class="label inline-block w-full p-0">
      <span
        v-if="label"
        :class="[
          'label-text mb-2 inline-block font-medium capitalize text-black',
          labelClasses,
        ]"
      >
        {{ label }}
        <slot name="labelIcon"></slot>
      </span>
      <slot name="before"></slot>
      <div
        class="input focus-within:border-primary flex h-12 items-center gap-2 rounded-lg border border-grey-10 bg-white pl-4 pr-3 text-sm leading-8"
        :class="{
          'border-error focus-within:border-error': !!errorMessage,
          'cursor-not-allowed': disabled,
          'bg-grey-5': disabled || readonly,
          [inputClasses]: true,
        }"
      >
        <div v-if="$slots.prefixIcon" class="">
          <slot name="prefixIcon"></slot>
        </div>
        <input
          :id="name"
          ref="input"
          :name="name"
          :type="type"
          :disabled="disabled"
          :readonly="readonly"
          class="flex-1 overflow-hidden text-ellipsis border-0 bg-transparent font-circular text-base placeholder:text-grey-60 focus:outline-none disabled:bg-grey-5 disabled:text-grey-30 disabled:placeholder:text-grey-30"
          v-bind="$attrs"
          v-model="value"
          @blur="handleBlur"
        />
        <div v-if="$slots.suffixIcon" class="ml-auto">
          <slot name="suffixIcon"></slot>
        </div>
      </div>
    </label>
    <p v-if="errorMessage || hint" class="w-fit text-sm">
      <span class="text-error" v-if="errorMessage">{{ errorMessage }}</span>
      <span class="" v-else-if="hint">{{ hint }}</span>
    </p>
    <slot name="after"></slot>
  </div>
</template>
