<script lang="ts" setup generic="T extends string">
import { computed } from "vue";
import type { GrowthArea } from "../types";

const props = defineProps<{
  growthAreas: Array<GrowthArea>;
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const sliceSeparatorLineWidth = 4;
const sliceSeparatorLineColor = "#ffffff";
const innerCircleSizePx = 100;
const sizePx = 400;
const VIEW_BOX_SIZE = computed(() => sizePx);
const VIEW_BOX_CENTER_POINT = computed(() => VIEW_BOX_SIZE.value / 2);
const VIEW_BOX = computed(
  () => `0 0 ${VIEW_BOX_SIZE.value} ${VIEW_BOX_SIZE.value}`,
);

const slicePercentage = (1 / props.growthAreas.length) * 100;

function percentToDegrees(percent: number): number {
  return percent * 3.6;
}

function getCoordinatesFromDegrees(
  angel: number,
  radius: number,
  svgSize: number,
) {
  const x = Math.cos((angel * Math.PI) / 180);
  const y = Math.sin((angel * Math.PI) / 180);

  const coordX = x * radius + svgSize / 2;
  const coordY = y * -radius + svgSize / 2;

  return { x: coordX, y: coordY };
}

const slices = computed(() => {
  let cumulativePercent = 0;

  return props.growthAreas.map((item) => {
    const offset = cumulativePercent * -3.6;
    cumulativePercent += slicePercentage;

    function getScorePieSlicePath() {
      const svgSize = VIEW_BOX_SIZE.value;
      const svgRadius = svgSize / 2;

      const degrees = percentToDegrees(slicePercentage);
      const largeArcFlag = degrees > 180 ? 1 : 0;

      const innerCircleRadius = innerCircleSizePx / 2;
      const ringAvailableSpace = svgRadius - innerCircleRadius;

      const ringWidth = (item.score.value / 100) * ringAvailableSpace;
      const ringOuterRadius = innerCircleRadius + ringWidth;
      const ringInnerRadius = innerCircleRadius;

      const x1 = VIEW_BOX_CENTER_POINT.value + innerCircleRadius + ringWidth;
      const y1 = VIEW_BOX_CENTER_POINT.value;

      const { x: x2, y: y2 } = getCoordinatesFromDegrees(
        degrees,
        ringOuterRadius,
        svgSize,
      );
      const { x: x3, y: y3 } = getCoordinatesFromDegrees(
        degrees,
        ringInnerRadius,
        svgSize,
      );

      const x4 = VIEW_BOX_CENTER_POINT.value + innerCircleRadius;
      const y4 = VIEW_BOX_CENTER_POINT.value;

      return [
        `M ${x1} ${y1}`,
        `A ${ringOuterRadius} ${ringOuterRadius} 0 ${largeArcFlag} 0 ${x2} ${y2}`,
        `L ${x3} ${y3}`,
        `A ${ringInnerRadius} ${ringInnerRadius} 0 ${largeArcFlag} 1 ${x4} ${y4}`,
      ].join(" ");
    }

    function getAmbitionPieSlicePath() {
      const svgSize = VIEW_BOX_SIZE.value;
      const svgRadius = svgSize / 2;

      const degrees = percentToDegrees(slicePercentage);
      const largeArcFlag = degrees > 180 ? 1 : 0;

      const innerCircleRadius = innerCircleSizePx / 2;
      const ringAvailableSpace = svgRadius - innerCircleRadius;

      const ringWidth = (item.ambition.value / 100) * ringAvailableSpace;
      const ringOuterRadius = innerCircleRadius + ringWidth;
      const ringInnerRadius = innerCircleRadius;

      const x1 = VIEW_BOX_CENTER_POINT.value + innerCircleRadius + ringWidth;
      const y1 = VIEW_BOX_CENTER_POINT.value;

      const { x: x2, y: y2 } = getCoordinatesFromDegrees(
        degrees,
        ringOuterRadius,
        svgSize,
      );
      const { x: x3, y: y3 } = getCoordinatesFromDegrees(
        degrees,
        ringInnerRadius,
        svgSize,
      );

      const x4 = VIEW_BOX_CENTER_POINT.value + innerCircleRadius;
      const y4 = VIEW_BOX_CENTER_POINT.value;

      return [
        `M ${x1} ${y1}`,
        `A ${ringOuterRadius} ${ringOuterRadius} 0 ${largeArcFlag} 0 ${x2} ${y2}`,
        `L ${x3} ${y3}`,
        `A ${ringInnerRadius} ${ringInnerRadius} 0 ${largeArcFlag} 1 ${x4} ${y4}`,
      ].join(" ");
    }

    return {
      item,
      offset,
      scorePieSlicePath: getScorePieSlicePath(),
      ambitionPieSlicePath: getAmbitionPieSlicePath(),
    };
  });
});

function updateGrowthArea(growthArea: string) {
  document
    .querySelector("#ga-" + growthArea)
    ?.scrollIntoView({ block: "start", inline: "start" });
  document
    .querySelector("#ga-button-" + growthArea)
    ?.scrollIntoView({ block: "start", inline: "start" });
  emit("update:modelValue", growthArea);
}
</script>

<template>
  <svg
    :viewBox="VIEW_BOX"
    class="aspect-square h-full w-full max-w-[400px] origin-center"
  >
    <g>
      <path
        v-for="slice in slices"
        :key="slice.item.name.replace(/[^A-Za-z0-9]/g, '')"
        :fill="slice.item.color"
        :opacity="0.2"
        :d="slice.ambitionPieSlicePath"
        :transform="`rotate(${slice.offset})`"
        class="origin-center cursor-pointer transition-all hover:opacity-100"
        :class="
          modelValue === slice.item.name.replace(/[^A-Za-z0-9]/g, '') &&
          'opacity-30'
        "
        @click="updateGrowthArea(slice.item.name.replace(/[^A-Za-z0-9]/g, ''))"
      />
      <path
        v-for="slice in slices"
        :key="slice.item.name.replace(/[^A-Za-z0-9]/g, '')"
        :fill="slice.item.color"
        :opacity="0.3"
        :d="slice.scorePieSlicePath"
        :transform="`rotate(${slice.offset})`"
        class="origin-center cursor-pointer transition-all hover:opacity-80"
        :class="
          modelValue === slice.item.name.replace(/[^A-Za-z0-9]/g, '') &&
          'opacity-100'
        "
        @click="updateGrowthArea(slice.item.name.replace(/[^A-Za-z0-9]/g, ''))"
      />
    </g>

    <g v-if="slices.length > 1">
      <line
        v-for="slice in slices"
        :key="slice.item.id"
        :x1="VIEW_BOX_CENTER_POINT"
        :y1="VIEW_BOX_CENTER_POINT"
        :x2="VIEW_BOX_SIZE"
        :y2="VIEW_BOX_CENTER_POINT"
        :stroke="sliceSeparatorLineColor"
        :stroke-width="sliceSeparatorLineWidth"
        :transform="`rotate(${slice.offset})`"
        class="origin-center"
      />
    </g>
  </svg>
</template>
