<script setup lang="ts">
import { useSlots } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { AppLink } from "@/components";
import SpinnerIcon from "@/assets/images/icons/spinner.svg";

type ButtonProps = {
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "danger"
    | "link"
    | "link_danger";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingIconPosition?: "start" | "end";
  loadingIcon?: string;
  onlyIconOnSm?: boolean;
  href?: RouteLocationRaw;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: "primary",
  size: "medium",
  loadingIconPosition: "start",
  isLoading: false,
});

const variantClasses: Record<NonNullable<ButtonProps["variant"]>, string> = {
  primary: "bg-primary hover:bg-blue-120 text-white [&>*]:fill-white",
  secondary: "bg-blue-10 hover:bg-blue-20 text-primary [&>*]:fill-primary",
  danger: "bg-error hover:bg-red-120 text-white [&>*]:fill-white",
  tertiary: "bg-transparent hover:bg-blue-10 text-primary [&>*]:fill-primary",
  link: "bg-transparent text-primary hover:underline focus:text-primary:120",
  link_danger: "bg-transparent hover:bg-red-10 text-error [&>*]:fill-error",
};

const sizeClasses: Record<NonNullable<ButtonProps["size"]>, string> = {
  large: `h-12 ${props.variant !== "link" ? " px-6" : ""}`,
  medium: `h-10 font-medium ${props.variant !== "link" ? "px-4" : ""}`,
  small: `h-8 text-sm font-medium ${props.variant !== "link" ? "px-4" : ""}`,
};

const iconSizeClasses: Record<NonNullable<ButtonProps["size"]>, string> = {
  large: "h-5 w-5",
  medium: "h-4 w-4",
  small: "h-3 w-3",
};

const { prefixIcon, suffixIcon } = useSlots();

const hasPrefixIcon =
  prefixIcon || (props.isLoading && props.loadingIconPosition === "start");
const hasSuffixIcon =
  suffixIcon || (props.isLoading && props.loadingIconPosition === "end");
</script>

<template>
  <component
    :is="href ? AppLink : 'button'"
    :href="href"
    class="inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg disabled:cursor-not-allowed disabled:bg-grey-5 disabled:text-grey-30"
    :disabled="props.isLoading || $attrs.disabled"
    :class="[
      variantClasses[variant],
      sizeClasses[size],
      fullWidth ? 'w-full' : '',
    ]"
  >
    <div
      v-if="hasPrefixIcon"
      :class="{
        'animate-spin': loadingIconPosition === 'start' && isLoading,
      }"
    >
      <component
        v-if="isLoading && loadingIconPosition === 'start'"
        :is="loadingIcon ?? SpinnerIcon"
        :class="[iconSizeClasses[size]]"
      ></component>
      <slot v-else name="prefixIcon"></slot>
    </div>
    <span
      class="whitespace-nowrap"
      :class="{
        'hidden md:inline-block': onlyIconOnSm,
      }"
    >
      <slot></slot>
    </span>

    <div
      v-if="hasSuffixIcon"
      :class="{ 'animate-spin': loadingIconPosition === 'end' && isLoading }"
    >
      <component
        v-if="isLoading && loadingIconPosition === 'end'"
        :is="loadingIcon ?? SpinnerIcon"
        :class="[iconSizeClasses[size]]"
      ></component>
      <slot v-else name="suffixIcon"></slot>
    </div>
  </component>
</template>
