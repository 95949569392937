<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { GButton, GCard, GModal, GSpinner } from "@/components";
import PlusIcon from "@/assets/images/icons/plus.svg";

const props = defineProps<{
  canAddNew: boolean;
  isFetchingKeyList: boolean;
  isDeleteModalOpen: boolean;
  isWideCard?: boolean;
  errorMessage?: string;
}>();

const emit = defineEmits<{
  (e: "addNew"): void;
  (e: "confirmDelete"): void;
  (e: "closeDeleteModal"): void;
}>();

const { t } = useI18n();
</script>
<template>
  <GCard class="px-0 pb-16">
    <h3 class="mb-4 text-3xl"><slot name="title"></slot></h3>
    <div class="mb-9 flex justify-between">
      <p class="lg:max-w-1/2">
        <slot name="description"></slot>
      </p>
      <GButton
        :disabled="!props.canAddNew"
        @click="emit('addNew')"
        class="whitespace-nowrap"
      >
        <template #prefixIcon>
          <PlusIcon class="fill-white" />
        </template>
        {{ t("connectionMethods.add_connection") }}
      </GButton>
    </div>
    <div v-if="isFetchingKeyList" class="my-32 flex justify-center">
      <GSpinner data-testid="connections-list-loading-spinner" />
    </div>
    <GCard v-else-if="errorMessage" hasBorder class="w-full">
      <p
        class="text-center text-lg font-bold text-error"
        data-testid="connections-list-error"
      >
        {{ errorMessage }}
      </p>
    </GCard>
    <div
      v-else
      class="grid w-full gap-4"
      :class="isWideCard ? 'grid-cols-fit-160' : 'grid-cols-fit-88'"
    >
      <slot></slot>
    </div>
  </GCard>
  <GModal
    :isOpen="isDeleteModalOpen"
    :title="t('common.are_you_sure')"
    @close="emit('closeDeleteModal')"
    class="md:w-[26rem]"
  >
    {{ t("connectionMethods.delete_connection_confirmation") }}
    <template #actions>
      <GButton variant="danger" @click="$emit('confirmDelete')">{{
        t("common.sure_delete")
      }}</GButton>
    </template>
  </GModal>
</template>
