<script setup lang="ts">
import { GToggleBar, PageLayout } from '@/components';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

</script>
<template>
  <GToggleBar :label="t('common.toggle_bar_label')" opt="in" />
  <PageLayout :title="t('overview.title')">
    
  </PageLayout>
</template>
