<script setup lang="ts">
import { GCard, GCheckbox } from "@/components";

const props = defineProps<{
  achievement: {
    id?: string;
    label?: string;
  };
  isSelected: boolean;
}>();

const emit = defineEmits<{
  (event: "select", id: string): void;
}>();
</script>
<template>
  <label>
    <GCard
      isElevated
      class="flex cursor-pointer gap-6 border-2 border-transparent transition-all duration-100 ease-in-out hover:border-primary focus:border-primary"
      :class="{
        'border-primary': props.isSelected,
        'border-transparent': !props.isSelected,
      }"
    >
      <GCheckbox
        :modelValue="props.isSelected"
        @update:modelValue="emit('select', props.achievement.id)"
      />
      <p class="text-lg font-medium text-blue-140">
        {{ props.achievement.label }}
      </p>
    </GCard>
  </label>
</template>
