<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { GButton } from "@/components";
import { useLocale } from "@/composables";

import {
  saveGDPRCookie,
  isCookieConsentNeeded,
} from "../../services/analytics/cookies";

const { t } = useI18n();
const { locale } = useLocale();

const cookieSettingsPageUrl = computed(
  () => `/${locale.value}/cookies/cookie-settings.html`
);
</script>

<template>
  <Transition name="slide" :duration="300">
    <div
      v-if="isCookieConsentNeeded()"
      class="fixed inset-x-0 bottom-0 z-50 flex flex-col items-center justify-between gap-6 border-t border-grey-10 bg-white p-5 lg:flex-row"
    >
      <div class="flex flex-col items-center gap-6">
        <p class="text-3xl font-bold lg:self-start">
          {{ t("cookies.title") }}
        </p>
        <div class="text-sm font-medium">
          <span class="mr-1">{{ t("cookies.description") }}</span>
          <a
            :href="cookieSettingsPageUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
          >
            {{ t("cookies.action") }}
          </a>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <GButton @click="() => saveGDPRCookie(true)">
          {{ t("cookies.btn_action_1") }}
        </GButton>
        <GButton variant="link" @click="() => saveGDPRCookie(false)">
          {{ t("cookies.btn_action_2") }}
        </GButton>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}

@media (prefers-reduced-motion) {
  .slide-enter-active,
  .slide-leave-active {
    transition: none;
  }
}
</style>
