<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { TextInput } from "@/components";
import SearchIcon from "@/assets/images/icons/search.svg";
import CloseIcon from "@/assets/images/icons/close.svg";
import { debouncedWatch } from "@vueuse/core";

type Props = {
  name?: string;
  debounce?: number;
};

const props = withDefaults(defineProps<Props>(), {
  name: "search",
  debounce: 500,
});
const { t } = useI18n();
// The value that gets emitted to the parent component
const searchModel = defineModel<string>({
  default: "",
});
// The value that gets displayed in the input
const localSearch = ref(searchModel.value);

//  searchModel value gets emitted to the parent component after debounce when localSearch changes
// Debounce is 0 if localSearch is empty (User clicked on clear icon)
debouncedWatch(
  localSearch,
  () => {
    searchModel.value = localSearch.value;
  },
  { debounce: () => (localSearch.value ? props.debounce : 0) },
);
</script>
<template>
  <TextInput
    :name="props.name"
    fullWidth
    role="searchbox"
    :placeholder="`${t('common.search')}...`"
    :labelClasses="['hidden lg:inline-block']"
    v-model.trim="localSearch"
  >
    <template #prefixIcon>
      <SearchIcon />
    </template>
    <template #suffixIcon>
      <CloseIcon
        v-if="localSearch"
        @click="localSearch = ''"
        class="cursor-pointer"
      />
    </template>
  </TextInput>
</template>
<style lang="scss" scoped>
.search-input::-webkit-search-cancel-button {
  visibility: hidden;
}
</style>