<script setup lang="ts">
export interface TileBgProps {
  color: string;
  shapeColor?: string;
  shape: "convex" | "rectangle" | "slope" | "concave";
  position?: "start" | "end";
  radius?: number;
}
withDefaults(defineProps<TileBgProps>(), {
  radius: 10,
  position: "start",
  shapeColor: "rgba(0, 0, 0, 0.3)",
});
const VIEW_BOX_WIDTH = 330;
const VIEW_BOX_HEIGHT = 155;
</script>

<template>
  <svg
    :viewBox="`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clip">
        <rect width="100%" height="100%" :rx="radius" />
      </clipPath>

      <filter :id="`blend-${color}`">
        <feFlood result="floodFill" :flood-color="color" />
        <feBlend in="SourceGraphic" in2="floodFill" mode="overlay" />
      </filter>
    </defs>

    <rect width="100%" height="100%" :fill="color" :rx="radius" />

    <g
      clip-path="url(#clip)"
      :filter="`url(#blend-${color})`"
      :fill="shapeColor"
      :transform="{ start: '', end: 'scale(-1, 1)' }[position]"
      transform-origin="center"
    >
      <ellipse v-if="shape === 'convex'" cy="50%" rx="115" ry="120" />

      <rect v-else-if="shape === 'rectangle'" width="50%" height="100%" />

      <rect
        v-else-if="shape === 'slope'"
        width="100%"
        height="100%"
        transform="rotate(45)"
      />

      <path
        v-else-if="shape === 'concave'"
        :d="`M 0 0 h 40 q 0 125, 110 ${VIEW_BOX_HEIGHT} H 0 z`"
      />
    </g>
  </svg>
</template>
