<script setup lang="ts">
import { GCard, GStateHandlerWrapper } from "@/components";

type Props = {
  isLoading: boolean;
  stats: Array<{ label: string; value: string }>;
};

const props = defineProps<Props>();
</script>
<template>
  <GCard hasBorder>
    <GStateHandlerWrapper :isLoading="isLoading">
      <div class="flex items-center gap-4">
        <div class="rounded-lg bg-blue-10 p-2">
          <slot name="icon"></slot>
        </div>
        <div class="flex gap-6">
          <div v-for="{ label, value } in props.stats" :key="value">
            <p class="text-sm">{{ label }}</p>
            <p class="text-lg text-primary">{{ value }}</p>
          </div>
        </div>
      </div>
    </GStateHandlerWrapper>
  </GCard>
</template>
