import { q } from "groqd";

import { runSafeQuery } from "@/services/sanity";

export const fetchLaunchAccordion = async () => {
  const result = await runSafeQuery(
    q("*")
      .filterByType("launchAccordion")
      .grab({
        accordion: q.object({
          alwaysOpen: q.boolean(),
          title: q.string(),
          subtitle: q.string(),
          items: q.array(
            q.object({
              text: q.string(),
              action: q.union([
                q.literal("startDashboardTour"),
                q.literal("inviteTeamMembers"),
                q.literal("exploreCourseLibrary"),
                q.literal("exploreAssessments"),
              ]),
              icon: q.object({
                name: q.string(),
                bgColor: q.string(),
              }),
            }),
          ),
        }),
      }),
  );
  return result[0].accordion;
};

export type LaunchAccordion = Awaited<ReturnType<typeof fetchLaunchAccordion>>;
export type LaunchAccordionItemAction =
  LaunchAccordion["items"][number]["action"];
