<script setup lang="ts" generic="TData extends {}">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import {
  getCoreRowModel,
  useVueTable,
  createColumnHelper,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  FlexRender,
  type ExpandedState,
  type SortingState,
  type RowSelectionState,
  type ColumnDef,
  type VisibilityState,
  type ColumnFilter,
  type FilterFnOption,
  type Row,
} from "@tanstack/vue-table";
import { useMediaQuery } from "@vueuse/core";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { fuzzyFilter, includesLocaleString } from "@/composables/useFilter";
import { GButton } from "@/components";
import GEmptyTableView from "./GEmptyTableView.vue";

import ChevronDownIcon from "@/assets/images/icons/chevron-down.svg";
import RoundedTriangleIcon from "@/assets/images/icons/rounded-triangle.svg";

/**
 * Column type definition
 * @propery id: required because accessor is not available on 'display cells' like checkbox or button
 * @property accessor: defines what property of the data item should be rendered in the column
 * (should be keyof TData, but because it's inferred from props.data, it is not known at this point)
 * @property headerLabel: Label for column header
 */
export type TableColumn<T = any> = {
  id: string;
  accessor?: T;
  headerLabel?: string;
  visibleFrom?: "sm" | "md" | "lg" | false;
  filterFnName?: FilterFnOption<any>;
  sortable?: boolean;
  isGloballyFilterable?: boolean;
};

const { t } = useI18n();
const route = useRoute();

// Vue cannot handle <TData>[], generic type cannot be used for selectedList yet :()
const props = withDefaults(
  defineProps<{
    data: TData[];
    columns: TableColumn[];
    filter?: {
      global?: string;
      columns?: ColumnFilter[];
    };
    defaultSort?: SortingState;
    selectable?: "rows" | "subRows" | "all" | false;
    selectedList?: TData[];
    isExpanded?: boolean;
  }>(),
  {
    selectable: "rows",
  },
);

const emit = defineEmits<{
  (e: "selectedListChange", value: TData[]): void;
}>();

const columnHelper = createColumnHelper<TData>();

const mappedColumns: ColumnDef<TData, any>[] = props.columns.map((col) => {
  if (col.accessor) {
    return columnHelper.accessor(col.accessor as any, {
      id: col.id,
      header: () => col.headerLabel,
      enableColumnFilter: !!col.filterFnName,
      enableGlobalFilter: col.isGloballyFilterable,
      enableSorting: col.sortable !== false,
      filterFn: col.filterFnName || includesLocaleString,
    });
  }
  return columnHelper.display({
    id: col.id,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    header: col.headerLabel,
  });
});

const sorting = ref<SortingState>(props.defaultSort || []);

const columnVisibility = ref<VisibilityState>({});
const expanded = ref<ExpandedState>({});

const isMedium = useMediaQuery("(min-width: 640px)");
const isLarge = useMediaQuery("(min-width: 1024px)");

const setColumnVisibility = () => {
  const tableColumns = table.getAllLeafColumns();
  props.columns.forEach((col) => {
    const tableColumn = tableColumns.find((tc) => col.id === tc.id);
    if (col.visibleFrom === false) tableColumn?.toggleVisibility(false);
    else if (col.visibleFrom === "md")
      tableColumn?.toggleVisibility(isMedium.value);
    else if (col.visibleFrom === "lg")
      tableColumn?.toggleVisibility(isLarge.value);
  });
};

watch([isMedium, isLarge], () => {
  setColumnVisibility();
});

const rowSelection = ref<RowSelectionState>({});

watch(
  () => props.selectedList,
  (newVal) => {
    if (newVal?.length === 0) table.resetRowSelection();
  },
  { deep: true },
);

watch(rowSelection, () => {
  const selectedRows = table.getSelectedRowModel().rows;

  emit(
    "selectedListChange",
    selectedRows.map((row) => row.original) as TData[],
  );
});

const table = useVueTable({
  data: props.data,
  columns: mappedColumns,
  filterFns: {
    fuzzy: fuzzyFilter,
  },
  state: {
    get rowSelection() {
      return rowSelection.value;
    },
    get sorting() {
      return sorting.value;
    },
    get columnVisibility() {
      return columnVisibility.value;
    },
    get globalFilter() {
      return props.filter?.global;
    },
    get columnFilters() {
      return props.filter?.columns;
    },
    get expanded() {
      return expanded.value;
    },
  },
  onColumnVisibilityChange: (updaterOrValue) => {
    columnVisibility.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(columnVisibility.value)
        : updaterOrValue;
  },
  onRowSelectionChange: (updaterOrValue) => {
    rowSelection.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(rowSelection.value)
        : updaterOrValue;
  },
  onSortingChange: (updaterOrValue) => {
    sorting.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(sorting.value)
        : updaterOrValue;
    trackMixPanelEvent("sort", {
      context: "Dashboard",
      page: route?.path,
      sort: sorting.value,
    });
  },
  onExpandedChange: (updaterOrValue) => {
    expanded.value =
      typeof updaterOrValue === "function"
        ? updaterOrValue(expanded.value)
        : updaterOrValue;
  },
  getSubRows: (row) => row.subRows,
  globalFilterFn: fuzzyFilter,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getExpandedRowModel: getExpandedRowModel(),
  filterFromLeafRows: true,
  paginateExpandedRows: false,
  enableMultiRowSelection: true,
});

setColumnVisibility();
table.toggleAllRowsExpanded(props.isExpanded);

const isSomeRowsSelected = computed(() => {
  const totalSelected = Object.keys(table.getState().rowSelection ?? {}).length;
  return (
    totalSelected > 0 && totalSelected < table.getFilteredRowModel().rows.length
  );
});

const isAllRowsSelected = computed(() => {
  const preGroupedRows = table.getFilteredRowModel().rows;
  const { rowSelection } = table.getState();

  let isAllRowsSelected = Boolean(
    preGroupedRows.length && Object.keys(rowSelection).length,
  );

  if (isAllRowsSelected) {
    if (
      preGroupedRows.some((row) => row.getCanSelect() && !rowSelection[row.id])
    ) {
      isAllRowsSelected = false;
    }
  }
  return isAllRowsSelected;
});

const toggleAllRowsSelected = (value: boolean) => {
  table.setRowSelection((old) => {
    value = typeof value !== "undefined" ? value : !isAllRowsSelected.value;
    const rowSelection = { ...old };
    const preGroupedRows = table.getPreGroupedRowModel().rows;
    if (value) {
      preGroupedRows.forEach((row) => {
        if (!row.getCanSelect()) {
          return;
        }
        rowSelection[row.id] = true;
      });
    } else {
      preGroupedRows.forEach((row) => {
        delete rowSelection[row.id];
      });
    }

    return rowSelection;
  });
};

const toggleRowExpand = (row: Row<TData>) => {
  row.getToggleExpandedHandler()?.();
  trackMixPanelEvent("expand", {
    context: "Dashboard",
    page: route?.path,
    item: (row.original as any).title ?? (row.original as any).name,
  });
};
</script>

<template>
  <div>
    <div class="sticky flex w-full items-center gap-4 bg-white py-4">
      <select
        class="mr-auto cursor-pointer"
        :value="table.getState().pagination.pageSize"
        @change="
          table.setPageSize(Number(($event?.target as HTMLInputElement)?.value))
        "
      >
        <option
          v-for="pageSize in [5, 10, 20, 30, 40, 50]"
          :key="pageSize"
          :value="pageSize"
        >
          {{ t("table.show_size", { size: pageSize }) }}
        </option>
      </select>
      <span class="ml-auto text-primary" v-if="selectedList?.length > 0">
        {{ selectedList.length }} {{ t("common.selected") }}
      </span>
      <slot name="primaryAction"></slot>
    </div>
    <div class="max-w-[90vw] overflow-x-auto">
      <table class="table w-full">
        <thead>
          <tr>
            <th v-if="table.getCanSomeRowsExpand()"></th>
            <th v-if="selectable">
              <input
                type="checkbox"
                class="checkbox checkbox-primary checkbox-sm"
                :checked="isAllRowsSelected"
                :indeterminate="isSomeRowsSelected"
                @change="
                  toggleAllRowsSelected(
                    ($event.target as HTMLInputElement).checked,
                  )
                "
                :aria-label="t('table.select_all')"
              />
            </th>
            <th
              v-for="(header, i) in table.getFlatHeaders()"
              :key="i"
              :class="
                header.column.getCanSort() ? 'cursor-pointer select-none' : ''
              "
              @click="header.column.getToggleSortingHandler()?.($event)"
            >
              <div class="flex flex-nowrap items-center gap-2 text-grey-70">
                <template v-if="!header.isPlaceholder">
                  <span class="whitespace-pre-wrap">
                    <FlexRender
                      :render="header.column.columnDef.header"
                      :props="header.getContext()"
                  /></span>
                  <div
                    v-if="header.column.getCanSort()"
                    class="flex shrink-0 flex-col gap-0.5 text-grey-30"
                  >
                    <RoundedTriangleIcon
                      class="rotate-180"
                      :class="
                        (header.column.getIsSorted() as string) === 'asc' &&
                        'text-primary'
                      "
                    />
                    <RoundedTriangleIcon
                      :class="
                        (header.column.getIsSorted() as string) === 'desc' &&
                        'text-primary'
                      "
                    />
                  </div>
                </template>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="row in table.getRowModel().rows"
            :key="row.id"
            class="group"
          >
            <td
              v-if="table.getCanSomeRowsExpand()"
              class="w-10 bg-white group-hover:bg-grey-5"
            >
              <button v-if="row.getCanExpand()" @click="toggleRowExpand(row)">
                <ChevronDownIcon
                  class="shrink-0"
                  :class="row.getIsExpanded() ? '' : '-rotate-90'"
                />
              </button>
            </td>
            <td v-if="selectable" class="bg-white group-hover:bg-grey-5">
              <input
                v-if="
                  selectable === 'all' ||
                  (row.getCanExpand()
                    ? selectable === 'rows'
                    : selectable === 'subRows')
                "
                type="checkbox"
                class="checkbox checkbox-primary checkbox-sm align-middle"
                :checked="row.getIsSelected()"
                :indeterminate="row.getIsSomeSelected()"
                @change="row.getToggleSelectedHandler()?.($event)"
                :aria-label="t('table.select_row')"
              />
            </td>
            <td
              v-for="cell in row.getVisibleCells()"
              :key="cell.id"
              class="bg-white p-3 group-hover:bg-grey-5"
            >
              <slot
                :name="cell.column.id"
                :value="cell.getValue()"
                :row="cell.row.original"
                :rowFns="row"
              >
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
      <GEmptyTableView v-if="table.getRowModel().rows.length === 0" />
    </div>
    <div
      v-if="table.getRowModel().rows.length > 0"
      class="flex flex-col justify-center gap-2 md:flex-row"
    >
      <div class="mt-4 flex items-center justify-center gap-2">
        <GButton
          @click="table.setPageIndex(0)"
          :disabled="!table.getCanPreviousPage()"
          size="small"
          variant="secondary"
          :aria-label="t('table.first_page')"
        >
          <div class="flex">
            <ChevronDownIcon class="rotate-90" />
            <ChevronDownIcon class="rotate-90" />
          </div>
        </GButton>
        <GButton
          @click="table.previousPage()"
          :disabled="!table.getCanPreviousPage()"
          size="small"
          variant="secondary"
          :aria-label="t('table.previous_page')"
        >
          <ChevronDownIcon class="rotate-90" />
        </GButton>
        <GButton
          @click="table.nextPage()"
          :disabled="!table.getCanNextPage()"
          size="small"
          variant="secondary"
          :aria-label="t('table.next_page')"
        >
          <ChevronDownIcon class="-rotate-90" />
        </GButton>
        <GButton
          @click="table.setPageIndex(table.getPageCount() - 1)"
          :disabled="!table.getCanNextPage()"
          size="small"
          variant="secondary"
          :aria-label="t('table.last_page')"
        >
          <div class="flex">
            <ChevronDownIcon class="-rotate-90" />
            <ChevronDownIcon class="-rotate-90" />
          </div>
        </GButton>
      </div>

      <span class="mt-4 flex items-center justify-center gap-1">
        <div>{{ t("table.page") }}</div>
        <strong>
          {{
            `${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`
          }}
        </strong>
      </span>
    </div>
  </div>
</template>
<style>
.table :where(thead, tfoot) :where(th, td) {
  @apply !bg-grey-5 p-3 text-grey-70 first:!z-0;
}
</style>
