<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { Table } from "@tanstack/vue-table";
import { GButton, GDropdown } from "@/components";

import ChevronDownIcon from "@/assets/images/icons/chevron-down.svg";

const props = defineProps<{ table: Table<unknown> }>();

const { t } = useI18n();

const shownRows = computed(() => {
  const pageIndex = props.table.getState().pagination.pageIndex;
  const pageSize = props.table.getState().pagination.pageSize;

  const firstRowIndex = (pageIndex + 1) * pageSize - pageSize + 1;
  const lastRowIndex = Math.min(
    (pageIndex + 1) * pageSize,
    props.table.getRowCount(),
  );

  return [firstRowIndex, lastRowIndex];
});

const shownPageIndices = computed(() => {
  const pageIndex = props.table.getState().pagination.pageIndex;
  const pageCount = props.table.getPageCount();

  return [...Array(pageCount).keys()].reduce((acc, currentValue) => {
    if (
      currentValue === 0 ||
      currentValue === pageCount - 1 ||
      (currentValue >= pageIndex - 1 && currentValue <= pageIndex + 1)
    ) {
      if (acc.length && currentValue - acc[acc.length - 1] > 1) {
        acc.push(-1);
      }
      acc.push(currentValue);
    }
    return acc;
  }, []);
});
</script>
<template>
  <div
    class="flex w-full flex-wrap items-center justify-center gap-4 md:justify-between"
  >
    <div
      v-if="table.getPageCount() > 1"
      class="flex items-center justify-center gap-2"
      data-testid="table-pagination"
    >
      <GButton
        @click="table.previousPage()"
        :disabled="!table.getCanPreviousPage()"
        variant="secondary"
        :aria-label="t('table.previous_page')"
      >
        <ChevronDownIcon class="rotate-90" />
      </GButton>

      <GButton
        v-for="(pi, index) in shownPageIndices"
        :key="index"
        :is="pi === -1 ? 'span' : 'GButton'"
        @click="table.setPageIndex(pi)"
        :disabled="pi === -1"
        :class="pi === -1 && 'disabled:cursor-default disabled:bg-transparent'"
        :variant="
          table.getState().pagination.pageIndex === pi
            ? 'secondary'
            : 'tertiary'
        "
        :aria-label="t('table.page') + ' ' + (pi + 1)"
      >
        {{ pi === -1 ? "..." : pi + 1 }}
      </GButton>

      <GButton
        @click="table.nextPage()"
        :disabled="!table.getCanNextPage()"
        variant="secondary"
        :aria-label="t('table.next_page')"
      >
        <ChevronDownIcon class="-rotate-90" />
      </GButton>
    </div>
    <div
      class="ml-auto flex flex-col items-center justify-center gap-4 md:flex-row"
    >
      <span data-testid="pagination_shown_items">{{
        t("table.showing_range", {
          first_index: shownRows[0],
          last_index: shownRows[1],
          total: table.getRowCount(),
        })
      }}</span>

      <GDropdown
        class="cursor-pointer"
        :value="table.getState().pagination.pageSize"
        @change="(e) => table.setPageSize(e as number)"
        :options="
          [5, 10, 20, 30, 40, 50].map((size) => ({
            label: t('table.show_size', { size }),
            value: size,
          }))
        "
      />
    </div>
  </div>
</template>
