import { computed } from "vue";
import { defineStore } from "pinia";
import { StorageSerializers, useLocalStorage } from "@vueuse/core";

import { useFetch } from "@/composables";
import type { WebSession } from "@gh-dashboard/types";
import { formatLanguageRegion } from "@/utils/misc";

export const useWebSessionStore = defineStore("webSession", () => {
  const webSession = useLocalStorage<WebSession | null>("websession", null, {
    serializer: StorageSerializers.object,
  });

  const { data, execute, isFinished } = useFetch(`/api/websession2`, {
    immediate: false,
  }).json<WebSession>();

  const getWebSession = async () => {
    if (webSession.value) return webSession.value;
    try {
      await execute();
      webSession.value = data.value || null;
    } catch (error) {
      console.error(error);
    }
  };

  const languageOptions = computed(() => {
    const languageList = webSession.value?.LanguageList ?? [];
    return languageList
      .filter(({ StatusId }) => StatusId === 1)
      .map((languageItem) => ({
        value: languageItem.LanguageCode.toUpperCase(),
        label: languageItem.LanguageName,
        region: languageItem.LanguageRegion.toLowerCase(),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  const languageOptionsWithRegionValues = computed(() =>
    languageOptions.value.map((option) => ({
      label: option.label,
      value: formatLanguageRegion(option.region),
    })),
  );

  // EN to en-gb
  const getLanguageRegionByLanguageCode = (languageCode: string) => {
    const languageList = webSession.value?.LanguageList ?? [];
    return languageList.find(
      (item) => item.LanguageCode.toUpperCase() === languageCode?.toUpperCase(),
    )?.LanguageRegion;
  };

  //EN to GB
  const getCountryCodeByLanguageCode = (languageCode: string) => {
    const languageList = webSession.value?.LanguageList ?? [];
    const countryCode = languageList
      .find(
        (item) =>
          item.LanguageCode.toLowerCase() === languageCode?.toLowerCase(),
      )
      ?.LanguageRegion?.split("-")[1];
    return countryCode;
  };

  // en-gb to EN
  const getLanguageCodeByLanguageRegion = (languageRegion: string) => {
    return webSession.value?.LanguageList.find(
      (item) =>
        item.LanguageRegion.toLowerCase() === languageRegion.toLowerCase(),
    )?.LanguageCode;
  };

  // EN to English
  const getLanguageNameByLanguageCode = (languageCode: string) => {
    return webSession.value?.LanguageList.find(
      (item) => item.LanguageCode === languageCode,
    )?.LanguageName;
  };

  const dashboardMenuItems = computed(() => {
    return webSession.value?.MenuTree.filter((item) => item.Id >= 4300).map(
      (item) => {
        const { Id, ParentId, Url, MenuName } = item;
        return { Id, ParentId, Url, MenuName };
      },
    );
  });

  return {
    webSession,
    isFinished,
    getWebSession,
    languageOptions,
    languageOptionsWithRegionValues,
    dashboardMenuItems,
    getLanguageRegionByLanguageCode,
    getLanguageCodeByLanguageRegion,
    getCountryCodeByLanguageCode,
    getLanguageNameByLanguageCode,
  };
});
