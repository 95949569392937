<script lang="ts" setup>
import { computed, watch, watchEffect } from "vue";
import { createAsmFetch } from "@/composables/useFetch";
import { GDropdown } from "@/components";

type AsmLocale = { name: string; code: string };
const useAsmFetch = createAsmFetch();

const emit = defineEmits<{
  (e: "error", value: unknown): void;
  (e: "ready"): void;
}>();

const localeModel = defineModel<string>({
  default: "en-GB",
  required: true,
});

const {
  isFetching,
  data: localeData,
  error,
} = useAsmFetch(`/api/Locale`).json<AsmLocale[]>();

watchEffect(() => {
  if (isFetching.value === false) emit("ready");
});

watch(error, (err) => {
  if (!err) return;
  emit("error", err);
});

const localeOptions = computed(() => {
  if (!localeData.value) return [];
  return localeData.value
    .map((lang) => ({
      label: lang.name,
      value: lang.code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});
</script>
<template>
  <GDropdown
    :options="localeOptions"
    :value="localeModel"
    @change="localeModel = $event as string"
    label="Language"
  />
</template>
