<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { InsightCard, GProgressBar, GBadge } from "@/components";
import type { CourseContainerReportDetails } from "@/types/reports";
import { SearchInput, GTable, createTableDef } from "@/patterns";

const props = defineProps<{
  data: CourseContainerReportDetails["studentsProgress"];
  isLoading: boolean;
}>();

const { t } = useI18n();
const search = ref("");

type ColumnAccessor =
  | "name"
  | "email"
  | "language"
  | "certificateProgress"
  | "testCertificate"
  | "studyTime"
  | "foreignUsername";

const tableDefinition = computed(() =>
  createTableDef<ColumnAccessor>(
    [
      {
        id: "name",
        headerLabel: t("reports.tbl_col_name"),
        accessor: "name",
      },
      {
        id: "email",
        headerLabel: t("reports.tbl_col_email"),
        accessor: "email",
      },
      {
        id: "foreignUsername",
        headerLabel: t("reports.tbl_col_foreign_username"),
        accessor: "foreignUsername",
      },
      {
        id: "language",
        headerLabel: t("common.language"),
        accessor: "language",
      },
      {
        id: "certificateProgress",
        headerLabel: t(
          "courseDetails.students_progress_report_tbl_col_certificate_progress",
        ),
        accessor: "certificateProgress",
      },
      {
        id: "testCertificate",
        headerLabel: t(
          "courseDetails.students_progress_report_tbl_col_test_certificate",
        ),
        accessor: "testCertificate",
      },
      {
        id: "studyTime",
        headerLabel: t("reports.tbl_col_study_time_hhmmss"),
        accessor: "studyTime",
      },
    ],
    { foreignUsername: props.data.some((d) => d.foreignUsername) },
  ),
);
</script>
<template>
  <InsightCard
    class="lg:col-span-3"
    :title="$t('courseDetails.students_progress_report_title')"
    :subtitle="$t('courseDetails.students_progress_report_subtitle')"
    :isLoading="props.isLoading"
    :error="null"
    @retry="() => {}"
  >
    <template #primaryAction>
      <SearchInput v-model="search" />
    </template>
    <GTable
      class="text-sm"
      :data="props.data"
      :columns="tableDefinition"
      :filter="{
        global: search,
      }"
      :defaultSort="[
        {
          id: 'certificateProgress',
          desc: true,
        },
      ]"
      :selectable="false"
    >
      <template v-slot:certificateProgress="cellProps">
        <p
          :class="{
            'text-category-management-100':
              cellProps.row.certificateProgress.isComplete,
          }"
        >
          {{
            cellProps.row.certificateProgress.isComplete
              ? t("courseDetails.course_progress_complete")
              : t("courseDetails.course_progress_in_progress")
          }}
        </p>
        <div class="flex items-center justify-between gap-2">
          <span>{{ `${cellProps.row.certificateProgress.value}%` }}</span>
          <GProgressBar
            :variant="
              cellProps.row.certificateProgress.isComplete
                ? 'success'
                : 'primary'
            "
            :progress="cellProps.row.certificateProgress.value"
            class="flex-1"
          />
        </div>
      </template>
      <template v-slot:testCertificate="cellProps">
        <div class="grid place-content-center text-center">
          <GBadge
            v-if="cellProps.row.testCertificateStatus !== 'notStarted'"
            class="flex flex-col items-center"
            :variant="
              cellProps.row.testCertificateStatus === 'passed'
                ? 'success'
                : 'danger'
            "
          >
            <span class="px-2 capitalize">
              {{ cellProps.row.testCertificateStatus }}
            </span>
          </GBadge>

          <span v-else>{{
            t("courseDetails.course_progress_not_started")
          }}</span>
        </div>
      </template>
    </GTable>
  </InsightCard>
</template>
