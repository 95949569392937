<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { urlFor } from "@/services/sanity";

import {
  OnboardingStep,
  OnboardingWizard,
} from "@/components/Onboarding/OnboardingWizard";
import StepContent from "./components/StepContent.vue";
import StepHint from "./components/StepHint.vue";
import { GAppLoader, GCard, GMuxPlayer } from "@/components";
import {
  unknownStep,
  onboardingSteps,
  trackOnboarding,
} from "./tracking-events";
import {
  fetchTeamActivationSection,
  type TeamActivationSectionResult,
} from "@/sanity-queries";
import ResourceCard from "./components/ResourceCard.vue";

const activeStep = ref(0);
const muxPlayer = ref<typeof GMuxPlayer>();
const stepsResult = ref<TeamActivationSectionResult | null>(null);
const router = useRouter();

const currentStep = computed(() => {
  const dashboardTourStepsCount = 7;
  const step = Object.values(onboardingSteps).find(
    (step) => step.order === activeStep.value + dashboardTourStepsCount + 1
  );
  return step ?? unknownStep;
});

onMounted(async () => {
  stepsResult.value = await fetchTeamActivationSection();
});

const handleGoNext = (linkText: string) => {
  const isLastStep =
    stepsResult.value &&
    activeStep.value === stepsResult.value[0].pages.length - 1;

  if (isLastStep) {
    return closeTour();
  }

  trackOnboarding("onboardingNext", {
    name: onboardingSteps.invite.name,
    order: onboardingSteps.invite.order,
    linkText,
  });
  activeStep.value++;
};

const handleGoBack = (stepNumber: number) => {
  trackOnboarding("onboardingBack", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  activeStep.value = stepNumber;
};

const closeTour = () => {
  trackOnboarding("onboardingExit", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  router.push({
    name: "launch",
  });
};
</script>
<template>
  <GAppLoader v-if="!stepsResult?.[0].pages" />
  <OnboardingWizard
    v-else
    :activeStep="activeStep"
    :stepsCount="stepsResult[0].pages.length"
    @goBack="handleGoBack"
    @close="closeTour()"
  >
    <OnboardingStep
      v-for="{ id, startSection, endSection } of stepsResult[0].pages"
      :key="id"
    >
      <StepContent
        :heading="startSection.heading"
        :description="startSection.description"
        :actionLabel="startSection.nextButtonLabel"
        @goNext="handleGoNext(startSection.nextButtonLabel)"
        class="col-span-2"
      >
        <template #additionalContent>
          <StepHint v-if="startSection.hint" class="mt-3">{{
            startSection.hint
          }}</StepHint>
        </template>
        <template #media>
          <div class="mb-3">
            <p v-if="endSection.title" class="text-lg font-semibold">
              {{ endSection.title }}
            </p>
            <p v-if="endSection.subtitle" class="text-grey-80">
              {{ endSection.subtitle }}
            </p>
          </div>
          <div class="grid gap-y-2">
            <div v-for="content of endSection.content" :key="content._type">
              <img
                v-if="content._type === 'image'"
                :src="urlFor(content.image)"
              />
              <GMuxPlayer
                v-if="content._type === 'muxVideo'"
                ref="muxPlayer"
                stream-type="on-demand"
                :playback-id="content.muxVideo.video.playbackId"
              />
              <GCard
                v-if="content._type === 'resourceCardsList'"
                class="grid gap-4 px-8 py-6"
                isElevated
              >
                <p v-if="content.resourceCards.title">
                  {{ content.resourceCards.title }}
                </p>
                <ResourceCard
                  v-for="resource in content.resourceCards.cards"
                  :key="resource.title"
                  :image-src="resource.img.url"
                  :resource-link="resource.link ?? ''"
                  :title="resource.languages?.join(', ') ?? ''"
                  :content="resource.title ?? ''"
                />
              </GCard>
            </div>
          </div>
        </template>
      </StepContent>
    </OnboardingStep>
  </OnboardingWizard>
</template>
