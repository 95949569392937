<script setup lang="ts">
import { GButton, GModal } from "@/components";
import { computed, ref } from "vue";
import type { Recommendation } from "../types";
import RecommendationCard from "./RecommendationCard.vue";
import ChevronRightIcon from "@/assets/images/icons/chevron-right.svg";
import ChevronLeftIcon from "@/assets/images/icons/chevron-left.svg";
import { useRecommendCourse } from "../useRecommendCourse";

const props = defineProps<{
  recommendations: Recommendation[];
  backgroundColor: string;
  color: string;
}>();

const visibleRecommendationsIndices = ref<[number, number]>([0, 3]);
const isNextButtonDisabled = computed(
  () => props.recommendations.length === visibleRecommendationsIndices.value[1],
);

const {
  RECOMMENDATION_DURATION,
  isConfirming,
  isRecommending,
  recommendCourse,
  confirmRecommendation,
  discardRecommendation,
} = useRecommendCourse(props.recommendations);

const showNextRecommendations = () => {
  const [start, end] = visibleRecommendationsIndices.value;
  const newEnd = Math.min(end + 1, props.recommendations.length);

  visibleRecommendationsIndices.value = [start + 1, newEnd];
};

const showPrevRecommendations = () => {
  const [start, end] = visibleRecommendationsIndices.value;
  const newStart = Math.max(0, start - 1);

  visibleRecommendationsIndices.value = [newStart, end - 1];
};
</script>

<template>
  <!-- OScan first release was for only one language -->
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <div
    class="w-full overflow-hidden rounded-xl p-6"
    :style="`background-color: ${props.backgroundColor};`"
  >
    <div class="mb-6 flex justify-between gap-10">
      <div>
        <p class="mb-2 text-lg font-bold">Relevant content to ambition</p>
        <p class="text-sm text-grey-80">
          Recommend some of these courses to the members of your organisation.
          By doing so, they can discover them directly on the home page.
        </p>
      </div>
      <div class="flex items-end gap-4">
        <GButton
          variant="secondary"
          size="large"
          @click="showPrevRecommendations"
          :disabled="visibleRecommendationsIndices[0] === 0"
        >
          <ChevronLeftIcon />
        </GButton>
        <GButton
          @click="showNextRecommendations"
          :disabled="isNextButtonDisabled"
          variant="secondary"
          size="large"
        >
          <ChevronRightIcon />
        </GButton>
      </div>
    </div>
    <div class="flex gap-5">
      <RecommendationCard
        class="flex-1"
        v-for="recommendation in props.recommendations.slice(
          visibleRecommendationsIndices[0],
          visibleRecommendationsIndices[1],
        )"
        :key="recommendation.id"
        :recommendation="recommendation"
        :isRecommended="recommendation.isRecommended"
        :color="props.color"
        @recommend="recommendCourse(recommendation.id)"
      />
    </div>
  </div>

  <GModal
    :isOpen="isConfirming"
    title="Recommend course"
    @close="discardRecommendation"
  >
    Recommend this course for {{ RECOMMENDATION_DURATION }} days?
    <template #actions>
      <GButton @click="confirmRecommendation()" :isLoading="isRecommending"
        >Recommend</GButton
      >
      <GButton variant="secondary" @click="discardRecommendation()"
        >cancel</GButton
      >
    </template>
  </GModal>
</template>
