<script setup lang="ts">
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { GSpinner, GEmptyState } from "@/components";

import errorPageImage from "@/assets/images/error-page-image.svg?url";

type ErrorObj = { title?: string; description?: string };

const props = defineProps<{
  isLoading: boolean;
  error?: ErrorObj | null;
}>();

watch(
  () => props.error,
  () => {
    if (props.error) {
      console.error(props.error);
    }
  }
);

const { t } = useI18n();
</script>
<template>
  <div v-if="isLoading" class="my-32 flex justify-center">
    <GSpinner />
  </div>
  <slot name="error" v-else-if="error">
    <GEmptyState
      :title="(error as ErrorObj)?.title as string ?? t('error.title')"
      :description="(error as ErrorObj)?.description ?? t('error.could_not_download')"
      :imageUrl="errorPageImage"
      class="m-auto my-8"
    ></GEmptyState>
  </slot>
  <slot v-else></slot>
</template>
