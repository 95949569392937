<script setup lang="ts">
import { GCard, GCheckbox } from "@/components";

const props = defineProps<{
  isDisabled?: boolean;
  isSelected?: boolean;
  id: string;
}>();

const emit = defineEmits<{
  (event: "select", id: string): void;
}>();
</script>
<template>
  <label>
    <GCard
      isElevated
      class="relative select-none border-2 border-transparent transition-all duration-100 ease-in-out focus:border-primary"
      :class="{
        'border-primary': props.isSelected,
        'cursor-pointer hover:border-primary': !props.isDisabled,
        'cursor-not-allowed': props.isDisabled,
      }"
    >
      <div class="relative grid justify-items-center gap-4">
        <div class="absolute top-0 left-0">
          <GCheckbox
            :modelValue="!!props.isSelected"
            @update:modelValue="emit('select', props.id)"
            :disabled="props.isDisabled"
            class="transition-all duration-100"
          />
        </div>
        <slot></slot>
      </div>
    </GCard>
  </label>
</template>
