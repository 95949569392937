import { reactive, ref } from "vue";
import type { DropdownOption } from "@/components";
import type { ColumnFilter, FilterFn, Row } from "@tanstack/vue-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import type { MaybeRef } from "@vueuse/core";

export type FilterType = "search" | "multiSelect" | "select" | "dateRange";

export type FilterDefinition<T> = {
  id: T;
  type: FilterType;
  options?: MaybeRef<DropdownOption[]>;
  startingValue: unknown;
  hasDefault?: boolean;
  label: string;
  disabled?: boolean;
};

function testFalsey(val: unknown) {
  return val === undefined || val === null || val === "";
}

export const includesLocaleString: FilterFn<any> = (
  row: Row<any>,
  columnId: string,
  filterValue: string,
) => {
  if (filterValue === "" || filterValue === null) return true;
  const cellValue = row.getValue<string>(columnId);
  if (!filterValue || !cellValue.length) return false;
  filterValue = "" + filterValue;
  if (filterValue.length > cellValue.length) return false;
  const ascii = (s: string) =>
    s
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  return ascii(cellValue).includes(ascii(filterValue));
};

export const multiSelectFilter = (
  row: any,
  columnId: string,
  filterValue: unknown[],
) => {
  return filterValue.length === 0 ||
    (filterValue.length === 1 && filterValue[0] === null)
    ? true
    : filterValue.includes(row.original[columnId]);
};

multiSelectFilter.autoRemove = (val: unknown) => testFalsey(val);

export const multiSelectLanguageFilter = (
  row: any,
  columnId: string,
  filterValue: string[],
) => {
  if (
    filterValue.length === 0 ||
    (filterValue.length === 1 && filterValue[0] === null)
  )
    return true;

  return filterValue.includes(String(row.original[columnId]));
};

multiSelectLanguageFilter.autoRemove = (val: unknown) => testFalsey(val);

export const dateFilter = (
  row: any,
  columnId: string,
  filterValue: [Date, Date] | null,
) => {
  if (!filterValue) return true;
  const value = row.original[columnId] as unknown as Date;
  return value >= filterValue[0] && value <= filterValue[1];
};

dateFilter.autoRemove = (val: unknown) => testFalsey(val);

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const handleNotInAnyTeamsFilterOption = (
  filter: ColumnFilter,
  idTag: string,
) => {
  const filterValueArray = filter.value as number[];

  if (
    filter.id === idTag &&
    filterValueArray[filterValueArray.length - 1] === -1
  ) {
    filter.value = [-1];
  } else if (
    filter.id === idTag &&
    filterValueArray.length > 1 &&
    filterValueArray[0] === -1
  ) {
    filter.value = filterValueArray.slice(1);
  }

  return filter;
};

export const useFilter = (initialValue: ColumnFilter[]) => {
  const filter = ref(initialValue);

  const updateFilter = ({ id, value }: ColumnFilter) => {
    filter.value = reactive([
      ...filter.value.filter((f: ColumnFilter) => f.id !== id),
      { id, value },
    ] as ColumnFilter[]);
  };
  return { filter, updateFilter };
};
