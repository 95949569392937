<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";

import { computed, watch } from "vue";
import { GBadge, GToggle } from "..";
import { useRoute } from "vue-router";
import {
  IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY,
  PAGE_TOGGLE_STATE_MAP_STORAGE_KEY,
} from "@/constants";
import { redirectToOldDashboardPage } from "@/utils/redirect";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { storeToRefs } from "pinia";
import { useFlagStore } from "@/stores";

const props = defineProps<{
  label: string;
  opt: "in" | "out";
  size?: "small" | "medium";
  badgeText?: string;
}>();

const route = useRoute();
const flagStore = useFlagStore();
const { routeMapFlag } = storeToRefs(flagStore);

const isOptingInNewDashboard = useLocalStorage<"true" | "false">(
  IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY,
  null,
);

const initialToggleState = computed(() => {
  if (isOptingInNewDashboard.value === null) {
    return props.opt === "out";
  }

  return isOptingInNewDashboard.value === "true";
});

const pageToggleStateMap = useLocalStorage(PAGE_TOGGLE_STATE_MAP_STORAGE_KEY, {
  [route.path]: initialToggleState.value,
});

const toggleValue = computed(() => {
  return pageToggleStateMap.value[route.path];
});

const oldRoute = computed(() => {
  return (
    routeMapFlag.value &&
    Object.values(routeMapFlag.value)?.find(
      (routeSpec) => routeSpec.new === route?.path,
    )?.old
  );
});

watch(
  toggleValue,
  (value) => {
    if (value === undefined || value === null) {
      pageToggleStateMap.value[route.path] = initialToggleState.value;
    }

    if (value === false) {
      redirectToOldDashboardPage(oldRoute.value);
    }
  },
  { immediate: true },
);

const handleToggle = (value: boolean) => {
  trackMixPanelEvent("toggleDashboard", {
    context: "Dashboard",
    target: "Old dashboard",
  });

  const currentPathSection = route?.path?.split("/")[2];
  const routeArray = Object.values(routeMapFlag.value);
  const sectionRoutes = routeArray.filter((routeSpec) =>
    routeSpec?.new?.includes(`/${currentPathSection}/`),
  );

  if (sectionRoutes.length > 0) {
    sectionRoutes.forEach((routeSpec) => {
      pageToggleStateMap.value[routeSpec.new] = value;
    });
  } else {
    pageToggleStateMap.value[route.path] = value;
  }
};
</script>
<template>
  <div
    v-if="!!oldRoute"
    class="absolute z-10 flex w-full justify-end bg-[#D5F7FF] px-8 py-2"
  >
    <GBadge v-if="props.badgeText" class="mr-6">{{ props.badgeText }}</GBadge>
    <GToggle
      :modelValue="Boolean(pageToggleStateMap[route.path])"
      @update:modelValue="handleToggle($event)"
    >
      <template #label>
        <span class="mr-1 text-blue">{{ props.label }}</span>
      </template>
    </GToggle>
  </div>
</template>
