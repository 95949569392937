import { useFetch } from "@/composables";
import { useToastStore } from "@/stores/toasts";
import { computed, ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import type { Lti11Key } from "./Lti11Tab.vue";
import type { Lti13Key } from "./Lti13Tab.vue";
import type { ScormKey } from "./ScormTab.vue";

export function useConnection<
  ConnectionKey extends Lti11Key | Lti13Key | ScormKey
>(keyListURL: string) {
  const { t } = useI18n();
  const toastStore = useToastStore();

  const keyList = ref<ConnectionKey[] | null>(null) as Ref<
    ConnectionKey[] | null
  >;
  const keyToEdit = ref<Lti11Key | Lti13Key | ScormKey | null>(null);

  const {
    execute,
    data,
    isFetching,
    error: listFetchingError,
    onFetchResponse,
  } = useFetch<ConnectionKey[]>(keyListURL, {}).get().json<ConnectionKey[]>();

  onFetchResponse((response) => {
    if (response.ok && data.value) keyList.value = data.value.reverse();
  });

  const listFetchingErrorMessage = computed(() => {
    if (listFetchingError.value) {
      if ((data.value as any)?.status === 401)
        return t("common.restricted_access");
      else return `${t("error.title")}. ${t("error.reload_or_support")}`;
    }
    return undefined;
  });

  const deleteKey = async (keyIdToDelete: number) => {
    keyToEdit.value = null;
    const { error: deleteKeyError } = await useFetch<ConnectionKey[]>(
      keyListURL + keyIdToDelete
    ).delete();
    if (!deleteKeyError.value && keyList.value) {
      keyList.value = keyList.value.filter((key) => key.Id !== keyIdToDelete);
      toastStore.addToast({
        header: "Success",
        body: t("connectionMethods.delete_success_msg", { keyIdToDelete }),
      });
    } else
      toastStore.addToast({
        header: "Error",
        body: t("connectionMethods.delete_failure_msg", { keyIdToDelete }),
      });
  };

  const getToastForSave = (
    isOkResponse: boolean | undefined,
    editedKeyName: string
  ) => {
    if (isOkResponse)
      toastStore.addToast({
        header: "Success",
        body: t("connectionMethods.save_success_msg", { editedKeyName }),
      });
    else
      toastStore.addToast({
        header: "Error",
        body: t("connectionMethods.save_failure_msg", { editedKeyName }),
      });
  };

  const getToastForAdd = (isOkResponse: boolean | undefined) => {
    if (isOkResponse)
      toastStore.addToast({
        header: "Success",
        body: t("connectionMethods.add_success_msg"),
      });
    else
      toastStore.addToast({
        header: "Error",
        body: t("connectionMethods.add_failure_msg"),
      });
  };

  return {
    execute,
    keyList,
    keyToEdit,
    isFetching,
    listFetchingErrorMessage,
    deleteKey,
    getToastForSave,
    getToastForAdd,
  };
}
