import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import type { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { makeSafeQueryRunner } from "groqd";

const client = createClient({
  projectId: "geo5cnkg",
  dataset: "production",
  useCdn: import.meta.env.PROD,
});

export const runSafeQuery = makeSafeQueryRunner((query) => client.fetch(query));

const builder = imageUrlBuilder(client);

export const urlFor = (source: SanityImageSource) =>
  builder.image(source).url();
