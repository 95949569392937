import { q } from "groqd";

import { runSafeQuery } from "@/services/sanity";

export const fetchAboutYouSection = async () => {
  return await runSafeQuery(
    q("*", { isArray: true })
      .filterByType("onboardingSection")
      .filter("sectionName == 'About You'")
      .grab({
        pages: q("pages")
          .filter()
          .deref()
          .grab({
            id: ["_id", q.string()],
            title: q.string(),
            subtitle: q.string(),
            nextButtonLabel: q.string(),
            skippable: q.boolean().optional(),
            content: q("content[]", { isArray: true }).grab({
              questions: q("questions[]", { isArray: true }).grab({
                id: ["_key", q.string()],
                label: q.string(),
              }),
            }),
          }),
      })
  );
};

export type AboutYouSectionResult = Awaited<
  ReturnType<typeof fetchAboutYouSection>
>;
