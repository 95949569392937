export const USER_STATUS = {
  created: 1,
  invited: 2,
  active: 3,
  blocked: 4,
} as const;

export const userStatusValueToKey = Object.fromEntries(
  Object.entries(USER_STATUS).map(([key, value]) => [value, key]),
) as Record<UserStatusValue, UserStatusKey>;

type UserStatus = typeof USER_STATUS;
export type UserStatusKey = keyof UserStatus;
export type UserStatusValue = UserStatus[UserStatusKey];
