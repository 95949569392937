<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { GCard } from "@/components";
import ConnectionCardList from "./ConnectionCardList.vue";
import { useFetch } from "@/composables";
import { useConnection } from "./useConnection";
import { ref } from "vue";
import Lti11ConnectionCard from "./Lti11ConnectionCard.vue";

export type Lti11Key = {
  Id: number;
  CompanyName: string;
  Name: string;
  KeyValue: string;
  SecretValue: string;
  ContentWeighing: number;
  TestWeighing: number;
};

const { t } = useI18n();
const keyListURL = `${import.meta.env.VITE_GH_API}/api/LtiKey/`;
const isDeleteModalOpen = ref(false);

const {
  execute: fetchLtiKeyList,
  keyList,
  isFetching,
  listFetchingErrorMessage,
  keyToEdit,
  deleteKey,
  getToastForSave,
  getToastForAdd,
} = useConnection<Lti11Key>(keyListURL);

const addConnection = async () => {
  const result = await useFetch<Lti11Key[]>(keyListURL).post({}).json();
  getToastForAdd(result.response.value?.ok);
  fetchLtiKeyList();
};

const saveConnection = async (editedKey: Lti11Key) => {
  keyToEdit.value = null;
  const result = await useFetch<Lti11Key[]>(keyListURL)
    .put({
      ...editedKey,
      ContentWeighing: 100 - editedKey.TestWeighing,
    })
    .json();
  getToastForSave(result.response.value?.ok, editedKey.Name);
  fetchLtiKeyList();
};

const deleteConnection = async () => {
  await deleteKey(keyToEdit.value?.Id ?? -1);
  isDeleteModalOpen.value = false;
};
</script>
<template>
  <GCard>
    <ConnectionCardList
      :canAddNew="!isFetching && !keyToEdit"
      :isDeleteModalOpen="isDeleteModalOpen"
      :isFetchingKeyList="isFetching"
      :errorMessage="listFetchingErrorMessage"
      @addNew="addConnection"
      @closeDeleteModal="isDeleteModalOpen = false"
      @confirmDelete="deleteConnection"
    >
      <template #description>
        {{ t("connectionMethods.lti11_description") }}
      </template>
      <Lti11ConnectionCard
        v-for="key in keyList"
        :key="key.Id"
        :connectionKey="key"
        :isInEditMode="keyToEdit === key"
        @save="saveConnection"
        @delete="isDeleteModalOpen = true"
        @edit="keyToEdit = key"
        @cancel="keyToEdit = null"
      />
    </ConnectionCardList>
  </GCard>
</template>
