<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
<template>
  <div
    data-testid="app-loader"
    class="absolute z-50 flex h-full w-full flex-col items-center justify-center gap-3 bg-white"
  >
    <img
      src="@/assets/images/logo-full.svg?url"
      :alt="t('common.goodhabitz_logo')"
      class="animate-pulse"
    />
  </div>
</template>
