<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { GButton, GExportModal, GStateHandlerWrapper } from "@/components";
import { FilterPanel, TeamsSelect, GTable, createTableDef } from "@/patterns";
import { useFetch } from "@/composables";
import { useFlagStore } from "@/stores";
import { multiSelectFilter } from "@/composables/useFilter";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const { t } = useI18n();
const flagStore = useFlagStore();

type TeamMemberReport = {
  Email: string;
  FirstName: string;
  LastName: string;
  MidName: string;
  TagId: number;
  TagName: string;
  ForeignUsername: string;
};

type TeamMemberTableDataRow = {
  email: string;
  name: string;
  teamId: number;
  teamName: string;
};

type ColumnAccessor =
  | "teamName"
  | "teamId"
  | "name"
  | "email"
  | "foreignUsername";

const filters = ref({
  search: "",
  teamId: [],
});

const selectedRows = ref<TeamMemberTableDataRow[]>([]);
const { data, isFetching, error } = useFetch("/api/ReportGroupMember")
  .post(() => ({
    GroupId: -1,
    SearchFor: "",
  }))
  .json<TeamMemberReport[]>();

const teamMemberList = computed(() => {
  if (data.value) {
    return data.value.map((report) => ({
      email: report.Email,
      name: `${report.LastName}, ${report.FirstName}${
        report.MidName && " " + report.MidName
      }`,
      teamId: report.TagId,
      teamName: report.TagName,
      foreignUsername: report.ForeignUsername,
    }));
  }
  return null;
});

const hasForeignUsername = computed(
  () =>
    flagStore.reportsForeignUsernameFlag &&
    teamMemberList.value?.some((d) => d.foreignUsername),
);

const courseReportTableDefinition = computed(() =>
  createTableDef<ColumnAccessor>(
    [
      {
        id: "teamName",
        headerLabel: t("reports.team"),
        accessor: "teamName",
        filterFnName: "includesString",
      },
      {
        id: "teamId",
        headerLabel: "",
        accessor: "teamId",
        filterFnName: multiSelectFilter,
        visibleFrom: false,
      },
      {
        id: "name",
        headerLabel: t("common.name"),
        accessor: "name",
        filterFnName: "includesString",
      },
      {
        id: "email",
        headerLabel: t("reports.tbl_col_email"),
        accessor: "email",
        filterFnName: "includesString",
      },
      {
        id: "foreignUsername",
        headerLabel: t("reports.tbl_col_foreign_username"),
        accessor: "foreignUsername",
      },
    ],
    {
      email: teamMemberList.value?.some((d) => d.email),
      foreignUsername: hasForeignUsername.value,
    },
  ),
);

const isExportModalOpen = ref(false);
const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
    trackMixPanelEvent("export", {
      count: selectedRows.value.length,
      items: selectedRows.value.map((entry) => ({
        teamId: entry.teamId,
        email: entry.email,
      })),
      context: "Reports",
    });
  }
};

const reportToExport = computed(() => {
  if (selectedRows.value.length > 0) {
    return {
      headers: [
        t("reports.team"),
        t("common.name"),
        t("reports.tbl_col_email"),
        ...(hasForeignUsername.value
          ? [t("reports.tbl_col_foreign_username")]
          : []),
      ],
      rows: selectedRows.value.map((row) => [
        row.teamName,
        row.name,
        row.email,
      ]),
    };
  }
  return { headers: [], rows: [] };
});
</script>
<template>
  <GStateHandlerWrapper
    :isLoading="isFetching"
    :error="error && { title: t('error.title'), description: error }"
  >
    <template v-if="teamMemberList">
      <GButton
        class="mb-4 ml-auto lg:px-12"
        @click="onStartExport"
        :disabled="selectedRows.length === 0"
        data-testid="export-button"
      >
        <template #prefixIcon><ArrowDownToLineIcon /></template>
        {{ t("common.export") }}
      </GButton>
      <FilterPanel v-model:search="filters.search">
        <template #primaryFilters>
          <TeamsSelect v-model="filters.teamId" />
        </template>
      </FilterPanel>
      <GTable
        :data="teamMemberList"
        :columns="courseReportTableDefinition"
        :filter="{
          global: filters.search,
          columns: [{ id: 'teamId', value: filters.teamId }],
        }"
        :default-sort="[{ desc: true, id: 'teamName' }]"
        :selectedList="selectedRows"
        @selectedListChange="(list) => (selectedRows = list)"
      >
      </GTable>
    </template>
  </GStateHandlerWrapper>
  <GExportModal
    :isOpen="isExportModalOpen"
    fileName="GH - team member report"
    :data="reportToExport"
    @close="isExportModalOpen = false"
  >
  </GExportModal>
</template>
