<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { twMerge } from "tailwind-merge";

const props = withDefaults(
  defineProps<{
    size?: "small" | "large";
    modelValue: boolean;
    indeterminate?: boolean;
    class?: string;
    disabled?: boolean;
  }>(),
  {
    size: "large",
    class: "",
  }
);

const emit = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();

const classes = {
  active:
    "text-white border-grey-30 hover:border-primary checked:bg-primary checked:bg-[url('/src/components/GCheckbox/check-white.svg')] indeterminate:bg-[url('/src/components/GCheckbox/minus-white.svg')] indeterminate:bg-primary cursor-pointer",
  disabled:
    "border-grey-10 bg-grey-5 cursor-not-allowed checked:bg-[url('/src/components/GCheckbox/check-grey.svg')] indeterminate:bg-[url('/src/components/GCheckbox/minus-grey.svg')]",
};
</script>
<template>
  <div :class="`relative ${props.size === 'small' ? 'h-5 w-5' : 'h-6 w-6'}`">
    <input
      v-bind="$attrs"
      type="checkbox"
      :checked="props.modelValue"
      :disabled="props.disabled"
      :indeterminate="props.indeterminate"
      :class="
        twMerge(
          `peer relative z-10 h-full w-full appearance-none rounded-[4px] border-2 bg-center bg-no-repeat transition-all duration-100 ease-in-out checked:border-transparent indeterminate:border-transparent ${
            props.disabled ? classes.disabled : classes.active
          }  ${props.class}`
        )
      "
      @change="
        emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      "
    />
  </div>
</template>
