<script setup lang="ts">
import { GButton, GCard } from "@/components";

const props = defineProps<{
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  imageSrc: string;
}>();

defineEmits<{
  (event: "buttonClick"): void;
}>();
</script>
<template>
  <GCard isElevated class="flex gap-5">
    <img
      :src="imageSrc"
      alt=""
      class="aspect-square w-[190px] rounded-lg object-cover"
    />

    <div class="flex flex-col justify-between">
      <p class="text-lg font-bold">{{ title }}</p>
      <p class="text-grey-80">{{ description }}</p>
      <GButton
        :href="props.buttonLink"
        class="px-12 font-medium"
        @click="$emit('buttonClick')"
        variant="secondary"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ buttonText }}
      </GButton>
    </div>
  </GCard>
</template>
