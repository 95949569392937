<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { GDropdown } from "@/components";
import { useTeamsStore } from "@/stores/teams";

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean;
    restrictedToMyTeams?: boolean;
  }>(),
  {
    restrictedToMyTeams: false,
  },
);

const teamsModel = defineModel<number[] | null[]>({
  required: true,
  default: null,
  set: (value: (number | null)[]) => {
    const NO_TEAMS_OPTION = -1;
    if (value.includes(null)) return [null];
    if (value.includes(NO_TEAMS_OPTION)) {
      return value.at(-1) === NO_TEAMS_OPTION
        ? [NO_TEAMS_OPTION]
        : value.filter((val) => val !== NO_TEAMS_OPTION);
    }
    return value;
  },
});

const { t } = useI18n();
const { myTeamsListOptions, teamsListOptions } = storeToRefs(useTeamsStore());
</script>
<template>
  <GDropdown
    searchable
    isMultiChoice
    hasDefault
    :label="t('common.select_teams')"
    :is-disabled="props.isDisabled"
    :options="props.restrictedToMyTeams ? myTeamsListOptions : teamsListOptions"
    :value="teamsModel"
    @change="teamsModel = $event as number[]"
  />
</template>
