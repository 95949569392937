<script setup lang="ts">
import { GButton } from "@/components";
import ArrowRightIcon from "@/assets/images/icons/arrow-right.svg";
import type { Recommendation } from "../types";

const props = defineProps<{
  recommendation: Recommendation;
  color: string;
  isRecommended: boolean;
}>();

const emit = defineEmits<{
  (e: "recommend", id: number): void;
}>();
</script>
<template>
  <!-- OScan first release was for only one language -->
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <div class="grid flex-1 place-items-start gap-2">
    <div
      class="h-32 w-full overflow-hidden rounded-lg"
      :style="`background-color: ${color};`"
    >
      <img
        :src="props.recommendation.imageUrl"
        :alt="`${props.recommendation.title} image`"
        class="h-full w-full object-contain"
      />
    </div>
    <div>
      <p class="text-sm text-grey-60">Course</p>
      <p class="line-clamp-2 min-h-[40px] text-sm font-medium">
        {{ props.recommendation.title }}
      </p>
    </div>

    <GButton
      v-if="!props.isRecommended"
      variant="link"
      @click="emit('recommend', props.recommendation.id)"
    >
      {{ !props.isRecommended ? "Recommend course" : "Course recommended!" }}
      <template #suffixIcon>
        <ArrowRightIcon />
      </template>
    </GButton>
    <p v-else class="font-medium leading-10 text-primary">Recommended ✅</p>
  </div>
</template>
