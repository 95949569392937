<script setup lang="ts">
import { GDropdown } from "@/components";
import { useFetch } from "@/composables";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  language: string;
  courseId?: number;
  lessons?: number[];
}>();

type ActivityTypesResponse = {
  Id: string;
  Name: string;
  DisplayName: string;
}[];

const { t } = useI18n();

const moduleIdParam = computed(() => {
  if (props.lessons?.length === 1 && props.lessons[0]) {
    return props.lessons[0].toString();
  }
  return "";
});

const searchParams = computed(() => {
  const params = new URLSearchParams();
  if (props.courseId === -1) return params.toString();

  params.append("languageRegion", props.language);
  if (props.courseId) {
    params.append("courseId", props.courseId.toString());
  }
  if (moduleIdParam.value) {
    params.append("moduleId", moduleIdParam.value);
  }
  return params.toString();
});

const { data, execute: fetchActivities } = useFetch(
  () => `api/activityTypes?${searchParams.value.toString()}`,
  { immediate: false },
).json<ActivityTypesResponse>();

const options = computed(() => {
  if (!data.value) return [];

  return data.value.map((activity) => ({
    label: activity.DisplayName,
    value: activity.DisplayName,
  }));
});

watch(searchParams, (updatedSearchParams) => {
  if (updatedSearchParams) fetchActivities();
});

const selectedActivities = defineModel<number[]>({
  default: [],
  required: true,
});
</script>
<template>
  <GDropdown
    :options="options"
    :value="selectedActivities"
    :label="t('common.select_category')"
    isMultiChoice
    searchable
    hasDefault
    @change="selectedActivities = $event as number[]"
  />
</template>
