<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { useLocale } from "@/composables";
import { PageLayout } from "@/components";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import CardWithImage from "./components/cards/CardWithImage.vue";
import WelcomeAccordionSection from "./components/WelcomeAccordionSection.vue";
import HelpCenterCard from "./components/cards/HelpCenterCard.vue";
import courseImage from "@/assets/images/onboarding/course-image.png";
import webinarImage from "@/assets/images/onboarding/webinar-image.jpg";

const { t } = useI18n();
const openModalSections = ref<string[]>(["section_1"]);
const { locale } = useLocale();
</script>
<template>
  <PageLayout :title="t('launch.title')">
    <template #heading>
      <h1 class="w-full border-b pb-3 text-3xl">{{ t("launch.title") }}</h1>
    </template>
    <div class="grid grid-cols-1 gap-7 lg:grid-cols-7">
      <div class="lg:col-span-3">
        <h2 class="text-lg font-semibold">
          {{ t("launch.helpful_resources") }}
        </h2>
        <p class="mb-4 text-grey-60">
          {{ t("launch.helpful_resources_description") }}
        </p>
        <div class="grid gap-7">
          <CardWithImage
            :title="t('launch.getting_started.title')"
            :description="t('launch.getting_started.description')"
            :imageSrc="courseImage"
            :buttonText="t('launch.getting_started.button')"
            :buttonLink="`https://my.goodhabitz.com/${locale}/courses/59684-0900-en-gb-getting-started-with-goodhabitz`"
            @buttonClick="
              () =>
                trackMixPanelEvent('launchAdminCourse', {
                  link_text: t('launch.getting_started.button'),
                  context: 'Launch Page',
                })
            "
          />
          <CardWithImage
            :title="t('launch.webinar.title')"
            :description="t('launch.webinar.description')"
            :imageSrc="webinarImage"
            :buttonText="t('launch.webinar.button')"
            buttonLink="https://events.goodhabitz.com/coachingwebinarmei2023/"
            @buttonClick="
              () =>
                trackMixPanelEvent('launchSignupWebinar', {
                  link_text: t('launch.webinar.button'),
                  context: 'Launch Page',
                })
            "
          />
          <HelpCenterCard
            @buttonClick="
              () =>
                trackMixPanelEvent('launchHelpCenter', {
                  link_text: t('launch.help_center.button'),
                  context: 'Launch Page',
                })
            "
          />
        </div>
      </div>
      <div class="lg:col-span-4">
        <h2 class="text-lg font-semibold">{{ t("launch.success_plan") }}</h2>
        <p class="mb-4 text-grey-60">
          {{ t("launch.success_plan_description") }}
        </p>
        <WelcomeAccordionSection
          :isOpen="openModalSections.includes('section_1')"
        />
      </div>
    </div>
  </PageLayout>
</template>
