import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";

export type Notification = {
  id: number;
  type: "danger" | "success" | "info";
  message: string;
};

const NOTIFICATION_TIMEOUT = 7000;

export const useNotificationsStore = defineStore("notifications", () => {
  const route = useRoute();
  const notifications = ref<Notification[]>([]);
  const persistedNotifications = ref<Notification[]>([]);

  function showNotification(notification: Omit<Notification, "id">) {
    const id = Date.now();
    notifications.value.push({ id, ...notification });

    if (notification.type !== "danger") {
      scheduleNotificationDismissal({ id, type: notification.type });
    }
  }

  function dismissNotification(notificationId: number) {
    notifications.value = notifications.value.filter(
      ({ id }) => id !== notificationId,
    );
  }

  function persistNotification(notificationId: number) {
    persistedNotifications.value.push(
      notifications.value.find(({ id }) => id === notificationId),
    );
  }

  function scheduleNotificationDismissal({
    id,
    type,
  }: Omit<Notification, "message">) {
    setTimeout(() => {
      if (type === "info") {
        persistNotification(id);
      }
      dismissNotification(id);
    }, NOTIFICATION_TIMEOUT);
  }

  // Clear notifications on route change
  watch(route, () => {
    notifications.value = [];
  });

  return {
    notifications,
    persistedNotifications,
    showNotification,
    dismissNotification,
  };
});
