<script setup lang="ts">
import { ListboxOption } from "@headlessui/vue";
import type { DropdownOption } from "./types";

const props = withDefaults(
  defineProps<{ option: DropdownOption; type?: "default" | "checkbox" }>(),
  {
    type: "default",
  },
);
</script>
<template>
  <ListboxOption
    :value="props.option.value"
    :disabled="props.option.isDisabled"
    v-slot="{ active, selected }"
    class="p-1"
  >
    <span
      class="flex cursor-pointer items-center gap-2 rounded-lg px-4 py-3.5 transition-colors duration-[200ms]"
      :class="{
        'text-error': props.option.isDestructive,
        'bg-[#FDE8E8]': props.option.isDestructive && active && !selected,
        'bg-error text-white': props.type === 'default' && props.option.isDestructive && selected,
        'bg-grey-5': !props.option.isDestructive && active,
        'bg-primary text-white': props.type === 'default' && !props.option.isDestructive && selected,
      }"
    >
      <input
        v-if="props.type === 'checkbox'"
        type="checkbox"
        :checked="selected"
        class="checkbox checkbox-primary checkbox-sm"
      />
      <slot v-else name="prefix"></slot>
      {{ props.option.label }}
    </span>
  </ListboxOption>
</template>
