export const isStringArray = (value: unknown): value is Array<string> => {
  return (
    isNonEmptyArray(value) && value.every((val) => typeof val === "string")
  );
};

export const isNumberArray = (value: unknown): value is Array<number> => {
  return (
    isNonEmptyArray(value) && value.every((val) => typeof val === "number")
  );
};

export const isEmptyArray = (value: unknown): value is Array<never> => {
  return Array.isArray(value) && value.length === 0;
};

export const isNonEmptyArray = (value: unknown): value is Array<unknown> => {
  return Array.isArray(value) && value.length > 0;
};

export const isDateTuple = (
  value: unknown
): value is [startDate: Date, endDate: Date] => {
  return (
    (value as [Date, Date])?.[0] instanceof Date &&
    (value as [Date, Date])?.[1] instanceof Date
  );
};

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === "fulfilled";
