import { createApp } from "vue";
import { createPinia } from "pinia";
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import App from "./app";
import router from "./router";
import { setupI18n } from "@/i18n";

import "./assets/base.css";

const pinia = createPinia();
const app = createApp(App);
const i18n = setupI18n();
app.use(LDPlugin, {
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID,
  deferInitialization: true,
});
app.use(pinia);
app.use(i18n);
app.use(router);

app.mount("#app");
