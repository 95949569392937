import { ref } from "vue";
import { defineStore } from "pinia";
import { getToken } from "@/utils/getToken";
import { useAIServiceFetch } from "@/composables/useFetch";

const getECR = () => "f76c510c-64ca-4e37-9118-4064f31092c0";

export const useAiConfigStore = defineStore("aiConfig", () => {
  const organisationSettings = ref<Record<string, string>>({});

  async function getAiConfigSettings() {
    try {
      const ghToken = getToken();
      const ecr = getECR();

      const { data } = await useAIServiceFetch(
        `/api/ai-config/organisation-settings/${ecr}`,
        {
          headers: {
            Authorization: `Bearer ${ghToken}`,
          },
        }
      )
        .get()
        .json();

      organisationSettings.value = data.value;
      return data;
    } catch (err) {
      console.error(err);
      return ref(null);
    }
  }

  async function updateAiConfigSettings(
    organisationName: string,
    organisationValues: string
  ) {
    try {
      const ghToken = getToken();
      const ecr = getECR();

      const { data, isFetching, error } = await useAIServiceFetch(
        `/api/ai-config/organisation-settings/${ecr}`,
        {
          headers: {
            Authorization: `Bearer ${ghToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            organisationCommonName: organisationName,
            organisationalValues: organisationValues,
          }),
        }
      )
        .post()
        .json();

      organisationSettings.value = data.value;
      return {
        data,
        isFetching,
        error,
      };
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  return {
    getAiConfigSettings,
    updateAiConfigSettings,
  };
});
