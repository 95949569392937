import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export const useLocale = () => {
  const { locale } = useI18n();
  const route = useRoute();

  const setLocale = (newLocale: string) => {
    window.location.href = `/${newLocale}${route.path}`;
  };

  return { locale, setLocale };
};
