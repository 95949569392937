import { startOfDay, subMonths } from "date-fns";

export const reportInitialDateRange = [
  subMonths(startOfDay(new Date()), 1),
  new Date(),
] as [Date, Date];

export const getPayloadTags = (tags: number[]) => {
  if (tags.includes(null)) return null;
  if (tags.includes(-1)) return [];
  return tags;
};
