<script setup lang="ts">
import ClockIcon from "@/assets/images/icons/clock.svg";
import CheckCircleIcon from "@/assets/images/icons/checkCircle.svg";
import TriangleExclamationSolidIcon from "@/assets/images/icons/triangle-exclamation-solid.svg";
import CloseIcon from "@/assets/images/icons/close.svg";

withDefaults(
  defineProps<{
    variant: "success" | "danger" | "info";
    message: string;
    dismissible?: boolean;
    onDismiss?: () => void;
  }>(),
  { dismissible: true },
);

const IconMap = {
  success: CheckCircleIcon,
  error: TriangleExclamationSolidIcon,
  info: ClockIcon,
} as const;
</script>
<template>
  <div
    role="alert"
    class="flex items-start justify-between rounded-lg px-4 py-2"
    :class="{
      'bg-success-light text-success': variant === 'success',
      'bg-error-light text-error': variant === 'danger',
      'bg-info-light text-info': variant === 'info',
    }"
  >
    <div class="flex items-center gap-2">
      <component :is="IconMap[variant]" class="h-6 w-6 flex-1" />
      <span>{{ message }}</span>
    </div>
    <CloseIcon
      v-if="variant !== 'info' && dismissible"
      class="h-6 w-6 cursor-pointer"
      @click="onDismiss"
    />
  </div>
</template>
