import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import type { ExportType, ExportTypeOption } from "@/types/export";

export const useExportStore = defineStore("export", () => {
  const { t } = useI18n();
  const route = useRoute();

  const contentType = computed(() => {
    if (route.path.includes("courses")) return "courses";
    if (route.path.includes("assessments")) return "assessments";
    if (route.path.includes("single-activities")) return "single-activities";
    if (route.path.includes("goodscan")) return "goodscan";
    if (route.path.includes("lessons")) return "lessons";
    return "";
  });
  watch(contentType, () => {
    if (
      contentType.value !== "courses" &&
      selectedExportType.value === "metadata"
    )
      selectedExportType.value = "scorm";
  });
  const fileFormatOptions = ref([
    { value: "csv", label: "CSV" },
    { value: "excel", label: "Excel" },
  ]);

  const exportTypeOptions = (exportTypes: ExportType[]): ExportTypeOption[] => {
    const options: ExportTypeOption[] = [
      {
        label: "Remote SCORM",
        value: "scorm",
      },
      {
        label: "LTI",
        value: "lti",
      },
      {
        label: t("contentSelection.metadata_only"),
        value: "metadata",
      },
    ];
    return options.filter((option) => exportTypes.includes(option.value));
  };
  const selectedExportType = ref<ExportType>("scorm");
  const selectedFileFormat = ref<"csv" | "excel">("csv");

  const shouldIncludeMetadata = ref(true);
  const shouldExportMultiLanguage = ref(false);

  return {
    contentType,
    exportTypeOptions,
    fileFormatOptions,
    selectedExportType,
    selectedFileFormat,
    shouldIncludeMetadata,
    shouldExportMultiLanguage,
  };
});
