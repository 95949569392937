<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import AchievementCard from "./AchievementCard.vue";
import { OnboardingStep } from "@/components/Onboarding/OnboardingWizard";
import StepContent from "./StepContent.vue";

defineProps<{
  achievements: Array<{
    id?: string;
    label?: string;
  }>;
}>();

const emit = defineEmits<{
  (event: "goNext"): void;
}>();

const selectedAchievements = ref<string[]>([]);
const { t } = useI18n();

const handleSelectAchievement = (achievementId: string) => {
  if (selectedAchievements.value.includes(achievementId)) {
    selectedAchievements.value = selectedAchievements.value.filter(
      (id) => id !== achievementId
    );
  } else {
    selectedAchievements.value = [...selectedAchievements.value, achievementId];
  }
};
</script>
<template>
  <OnboardingStep>
    <StepContent
      :heading="t('onboarding.about_you.achievements.heading')"
      :description="t('onboarding.about_you.achievements.description')"
      :actionLabel="t('onboarding.about_you.action_label')"
      contentClassName="md:col-span-8"
      skippable
      @goNext="emit('goNext')"
    >
      <template #additionalContent>
        <div class="grid gap-y-4 pb-4 pt-9">
          <AchievementCard
            v-for="achievement of achievements"
            :key="achievement.id"
            :achievement="achievement"
            :isSelected="selectedAchievements.includes(achievement.id)"
            @select="handleSelectAchievement"
          />
        </div>
      </template>
    </StepContent>
  </OnboardingStep>
</template>
