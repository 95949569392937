import { onKeyStroke } from "@vueuse/core";
import { onMounted, type Ref } from "vue";

const keys = {
  ArrowUp: "ArrowUp",
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
  Home: "Home",
  End: "End",
} as const;

const getFocusableTabs = (tabs: HTMLButtonElement[]) => {
  return tabs.filter((tab) => !tab.disabled);
};

const getFocusedTab = (focusableTabs: HTMLButtonElement[]) => {
  return (
    focusableTabs.find((tab) => tab.isEqualNode(document.activeElement)) ??
    focusableTabs[0]
  );
};

const moveToNextFocusableTab = (
  focusableTabs: HTMLButtonElement[],
  focusedTab: HTMLButtonElement
) => {
  const nextTabIndex =
    focusableTabs.indexOf(focusedTab) === focusableTabs.length - 1
      ? 0
      : focusableTabs.indexOf(focusedTab) + 1;
  const nextTab = focusableTabs[nextTabIndex];
  nextTab.focus();
};

const moveToPreviousFocusableTab = (
  focusableTabs: HTMLButtonElement[],
  focusedTab: HTMLButtonElement
) => {
  const previousTabIndex =
    focusableTabs.indexOf(focusedTab) === 0
      ? focusableTabs.length - 1
      : focusableTabs.indexOf(focusedTab) - 1;
  const previousTab = focusableTabs[previousTabIndex];
  previousTab.focus();
};

const moveToFirstFocusableTab = (focusableTabs: HTMLButtonElement[]) => {
  const firstTab = focusableTabs[0];
  firstTab.focus();
};

const moveToLastFocusableTab = (focusableTabs: HTMLButtonElement[]) => {
  const lastTab = focusableTabs[focusableTabs.length - 1];
  lastTab.focus();
};

export const useKeyboardNavigation = (
  tabListRef: Ref<HTMLDivElement | undefined>
) => {
  onMounted(() => {
    const tabList = tabListRef.value;
    if (tabList) {
      const tabs = Array.from(
        tabList.querySelectorAll<HTMLButtonElement>("[role=tab]")
      );
      const focusableTabs = getFocusableTabs(tabs);

      onKeyStroke([keys.ArrowRight, keys.ArrowDown], () => {
        if (tabList.contains(document.activeElement)) {
          const focusedTab = getFocusedTab(tabs);
          moveToNextFocusableTab(focusableTabs, focusedTab);
        }
      });

      onKeyStroke([keys.ArrowLeft, keys.ArrowUp], () => {
        if (tabList.contains(document.activeElement)) {
          const focusedTab = getFocusedTab(tabs);
          moveToPreviousFocusableTab(focusableTabs, focusedTab);
        }
      });

      onKeyStroke([keys.Home], () => {
        if (tabList.contains(document.activeElement)) {
          moveToFirstFocusableTab(focusableTabs);
        }
      });

      onKeyStroke([keys.End], () => {
        if (tabList.contains(document.activeElement)) {
          moveToLastFocusableTab(focusableTabs);
        }
      });
    }
  });
};
