import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { GH_ASM_TOKEN_STORAGE_KEY } from "@/constants";
import { getToken } from "@/utils/getToken";

export type AsmAccessRight = {
  referenceId: string;
  referenceType: string;
  startDate: string;
  duration: number;
  templateId: string;
  templateName: "demo" | "full-access";
  valid: boolean;
};

export type TokenResponse = {
  token: string;
};

function isAssessmentsAccessRights(
  data: AsmAccessRight[] | null | undefined
): data is AsmAccessRight[] {
  return (
    Array.isArray(data) &&
    data.every((accessRight) => accessRight.referenceId !== undefined)
  );
}

function isTokenResponse(
  data: TokenResponse | null | undefined
): data is TokenResponse {
  return !!(data && typeof data.token === "string");
}

export const useAssessmentsStore = defineStore("assessments", () => {
  const asmAccessRights = ref<AsmAccessRight[]>([]);

  const isAssessmentsUser = computed(() => {
    return asmAccessRights.value.some((right) => right.valid === true);
  });

  async function getAsmToken() {
    try {
      const ghToken = getToken();
      const response = await fetch(
        `${import.meta.env.VITE_GH_ASM_API}/CreateToken`,
        {
          headers: {
            Authorization: `Bearer ${ghToken}`,
          },
        }
      );

      const jsonResponse = await response.json();
      if (isTokenResponse(jsonResponse)) {
        const token = jsonResponse.token;
        localStorage.setItem(GH_ASM_TOKEN_STORAGE_KEY, token);
        return token;
      }
    } catch {
      return null;
    }
  }

  async function fetchAsmRights() {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_GH_ASM_API}/api/AccessRights`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              GH_ASM_TOKEN_STORAGE_KEY
            )}`,
          },
        }
      );

      const jsonResponse = await response.json();
      if (isAssessmentsAccessRights(jsonResponse)) {
        asmAccessRights.value = jsonResponse;
        return jsonResponse;
      }
    } catch {
      return null;
    }
  }

  const demoAccessRecords = computed(
    () =>
      asmAccessRights.value?.filter(
        (item) => item.templateName === "demo" && item.valid
      ) || []
  );

  const fullAccessRecords = computed(
    () =>
      asmAccessRights.value?.filter(
        (item) => item.templateName === "full-access" && item.valid
      ) || []
  );

  const isAssessmentsDemoUser = computed(
    () => !!demoAccessRecords.value.length
  );

  const isAssessmentsFullUser = computed(
    () => !!fullAccessRecords.value.length
  );

  return {
    asmAccessRights,
    isAssessmentsUser,
    isAssessmentsDemoUser,
    isAssessmentsFullUser,
    getAsmToken,
    fetchAsmRights,
  };
});
