<script setup lang="ts">
import { computed } from "vue";
import type { UserStatusKey } from "@/constants/user";
import UserStatusFilterItem from "./UserStatusFilterItem.vue";
import type { StatusFilterKey } from "./types";

const props = defineProps<{
  statuses: Partial<Record<UserStatusKey, number | null>>;
}>();

const statusModel = defineModel<StatusFilterKey>({
  default: "all",
});

const totalUsersCount = computed<number | null>(() => {
  if (Object.values(props.statuses).some((status) => status === null)) {
    return null;
  }

  return Object.values(props.statuses)
    .filter(Boolean)
    .reduce((total, count) => total + count, 0);
});

const statusOptions = computed(() => {
  const allStatusesOption = {
    all: totalUsersCount.value,
  };

  const statusOptionsEntries = Object.entries({
    ...allStatusesOption,
    ...props.statuses,
  }) as [StatusFilterKey, number | null][];

  return statusOptionsEntries.map(([status, count]) => ({
    status,
    count,
  }));
});
</script>
<template>
  <div
    class="flex flex-wrap items-center gap-2"
    data-testid="user-status-selector"
  >
    <UserStatusFilterItem
      v-for="{ status, count } in statusOptions"
      :key="status"
      :status="status"
      :count="count"
      :isActive="status === statusModel"
      @select="statusModel = $event"
    />
  </div>
</template>
