<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { toDate } from "date-fns";

import {
  GButton,
  PageLayout,
  InsightCard,
  BarChart,
  GPageDatePicker,
  GExportModal,
} from "@/components";
import StudentProgressTable from "../components/StudentProgressTable.vue";
import CourseLanguagesList from "../components/CourseLanguagesList.vue";
import StatsCard from "../components/StatsCard.vue";
import { useDetailsPage } from "./useDetailsPage";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import UserIcon from "@/assets/images/icons/user.svg";
import ClockIcon from "@/assets/images/icons/clock.svg";
import FileCertificateIcon from "@/assets/images/icons/file-certificate.svg";
import { ref, watch } from "vue";

const { t } = useI18n();
const {
  dateRange,
  courseDetailsReport,
  formattedActivityVisitsData,
  statsData,
  isLoading,
} = useDetailsPage();

const iconNameMap: Record<string, string> = {
  user: UserIcon,
  clock: ClockIcon,
  fileCertificate: FileCertificateIcon,
};

const exportableReportData = ref<{
  headers: string[];
  rows: (string | number)[][];
}>({
  headers: [],
  rows: [],
});

const isExportModalOpen = ref(false);

watch(isExportModalOpen, () => {
  if (
    isExportModalOpen.value === false ||
    !courseDetailsReport.value?.studentsProgress
  )
    return;
  exportableReportData.value.headers = [
    t("reports.tbl_col_email"),
    t("reports.tbl_col_name"),
    t("reports.tbl_col_progress"),
    t("reports.tbl_col_course_completed"),
    t("reports.tbl_col_test_passed"),
    t("reports.tbl_col_study_time"),
    t("common.language"),
  ];

  exportableReportData.value.rows =
    courseDetailsReport.value.studentsProgress.map((report) => {
      return [
        report.email,
        report.name,
        report.certificateProgress.value + "%",
        report.certificateProgress.isComplete
          ? t("courseDetails.course_progress_complete")
          : report.certificateProgress.value > 0
            ? t("courseDetails.course_progress_in_progress")
            : t("courseDetails.course_progress_not_started"),
        report.testCertificateStatus === "passed"
          ? t("courseDetails.course_progress_complete")
          : report.testCertificateStatus === "failed"
            ? t("courseDetails.test_failed")
            : t("courseDetails.course_progress_not_started"),
        report.studyTime,
        report.language,
      ];
    });
});
</script>
<template>
  <PageLayout
    :title="courseDetailsReport?.courseName ?? ''"
    :heading="courseDetailsReport?.courseName ?? ''"
    :backButton="{
      label: t('reports.btn_back_to_reports'),
      url: `/admin-dashboard/reports/courses`,
    }"
  >
    <template #subHeading>
      {{ courseDetailsReport?.courseDescription }}
    </template>
    <div class="grid grid-cols-3 gap-x-4 gap-y-5">
      <div
        class="col-span-3 flex w-full flex-col justify-between gap-y-4 lg:flex-row"
      >
        <GPageDatePicker
          @change="dateRange = [toDate($event[0]), toDate($event[1])]"
        />
        <div class="flex items-end gap-3">
          <GButton
            variant="secondary"
            size="small"
            @click="isExportModalOpen = true"
          >
            {{ t("courseDetails.btn_action_secondary") }}
            <template #suffixIcon>
              <ArrowDownToLineIcon />
            </template>
          </GButton>
        </div>
      </div>

      <StatsCard
        v-for="stats in statsData"
        :key="`${stats.data[0].label}-${stats.data[0].value}`"
        class="col-span-3 lg:col-span-1"
        :stats="stats.data"
        :isLoading="isLoading"
      >
        <template #icon>
          <component :is="iconNameMap[stats.icon]" class="fill-primary" />
        </template>
      </StatsCard>
      <CourseLanguagesList
        class="col-span-3 lg:col-span-1"
        :isLoading="isLoading"
        :courseLanguages="courseDetailsReport?.courseStudentsPerLanguages ?? []"
      />
      <InsightCard
        :isLoading="isLoading"
        :title="t('reports.tbl_col_lesson_visits')"
        :subtitle="t('reports.activity_list_subtitle')"
        class="col-span-3 lg:col-span-2"
      >
        <BarChart
          :xValues="formattedActivityVisitsData.labels"
          :yValues="
            formattedActivityVisitsData.data.length > 0
              ? formattedActivityVisitsData.data
              : [0]
          "
      /></InsightCard>
      <div class="col-span-3 mt-8">
        <StudentProgressTable
          :isLoading="isLoading"
          :data="courseDetailsReport?.studentsProgress ?? []"
        />
      </div>
    </div>
  </PageLayout>
  <GExportModal
    :isOpen="isExportModalOpen"
    :fileName="`${courseDetailsReport?.courseName} detailed course report`"
    :data="exportableReportData"
    @close="isExportModalOpen = false"
  >
  </GExportModal>
</template>
