<script setup lang="ts">
import { GButton, GCard } from "@/components";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineEmits<{
  (event: "buttonClick"): void;
}>();
</script>
<template>
  <GCard class="h-full" isElevated>
    <p class="mb-3 text-lg font-bold">{{ t("launch.help_center.title") }}</p>
    <p class="mb-8">{{ t("launch.help_center.description") }}</p>
    <GButton
      href="https://goodhabitz.my.site.com/helpcenter/"
      @click="$emit('buttonClick')"
      target="_blank"
      rel="noopener noreferrer"
      class="px-12 font-medium"
    >
      {{ t("launch.help_center.button") }}
    </GButton>
  </GCard>
</template>
