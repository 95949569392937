<script lang="ts" setup>
import { computed } from "vue";

type RadioOption = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type GRadioGroupProps = {
  name: string;
  options: RadioOption[];
  modelValue: string | number | undefined;
  isDisabled?: boolean;
};

const props = defineProps<GRadioGroupProps>();
const emit = defineEmits(["update:modelValue"]);

const selected = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
<template>
  <label class="label inline-block pl-0">
    <span class="mb-2 flex items-center text-sm font-medium">
      <slot name="label"></slot>
    </span>
    <div class="flex flex-col gap-2">
      <label v-for="option in options" :key="option.value" class="flex gap-2">
        <input
          type="radio"
          :name="name"
          :value="option.value"
          v-model="selected"
          :disabled="option.isDisabled || isDisabled"
          class="radio radio-primary"
        />
        {{ option.label }}</label
      >
    </div>
  </label>
</template>
