<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { InsightCard, GProgressBar, GExportModal } from "@/components";
import { useCourseStore } from "@/stores";
import type { UserReportDetails } from "./types";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { SearchInput, GTable, type TableColumn } from "@/patterns";

const props = defineProps<{
  data?: UserReportDetails["CourseDetails"];
  isLoading: boolean;
  isExportModalOpen: boolean;
}>();

const emit = defineEmits<{
  (event: "updateIsExportModalOpen", value: boolean): void;
}>();

const { t } = useI18n();
const courseStore = useCourseStore();
const { courses, isFetching: isFetchingCourses } = storeToRefs(courseStore);

const tableData = computed(() => {
  return props.data?.map((c) => {
    const courseDetails = courses.value.find((course) => {
      return course.CourseId === c.CourseId;
    });
    return {
      courseName: courseDetails?.properties.title,
      progress: c.Progress,
      modulesVisited: c.Modules,
      courseCertificate: c.Completed,
      testCertificate: c.Passed,
      studyTime: formatSecondsToHHMMSS(c.ContentSeconds),
      courseImg: courseDetails?.properties.image_landscape,
    };
  });
});

const isLoading = computed(() => {
  return props.isLoading || isFetchingCourses.value;
});

type ColumnAccessor =
  | "thumbnail"
  | "courseName"
  | "progress"
  | "modulesVisited"
  | "courseCertificate"
  | "testCertificate"
  | "studyTime";
const tableDefinition: TableColumn<ColumnAccessor>[] = [
  {
    id: "thumbnail",
    headerLabel: t("common.thumbnail"),
    accessor: "thumbnail",
    sortable: false,
    smallScreenPosition: "last",
  },
  {
    id: "courseName",
    headerLabel: t("reports.tbl_col_course_title"),
    accessor: "courseName",
    smallScreenPosition: "center",
  },
  {
    id: "progress",
    headerLabel: t("reports.tbl_col_progress"),
    accessor: "progress",
  },
  {
    id: "modulesVisited",
    headerLabel: t("reports.tbl_col_lesson_visits"),
    accessor: "modulesVisited",
  },
  {
    id: "studyTime",
    headerLabel: t("reports.tbl_col_study_time_hhmmss"),
    accessor: "studyTime",
  },
  {
    id: "courseCertificate",
    headerLabel: t("reports.tbl_col_course_completed"),
    accessor: "courseCertificate",
  },
  {
    id: "testCertificate",
    headerLabel: t("reports.tbl_col_test_passed"),
    accessor: "testCertificate",
  },
];

const searchQuery = ref("");

const exportableReportData = computed(() => ({
  headers: [
    t("reports.tbl_col_course_title"),
    t("reports.tbl_col_progress"),
    t("reports.tbl_col_lesson_visits"),
    t("reports.tbl_col_study_time"),
    t("reports.tbl_col_course_completed"),
    t("reports.tbl_col_test_passed"),
  ],
  rows:
    tableData.value?.map((row) => {
      return [
        row.courseName,
        row.progress + "%",
        row.modulesVisited,
        row.studyTime,
        row.courseCertificate.toString(),
        row.testCertificate.toString(),
      ];
    }) ?? [],
}));
</script>
<template>
  <InsightCard
    class="lg:col-span-3"
    :title="$t('userReportDetails.course_progress_report_title')"
    :subtitle="$t('userReportDetails.course_progress_report_subtitle')"
    :isLoading="isLoading"
    :error="null"
    @retry="() => {}"
  >
    <template #primaryAction>
      <SearchInput
        name="title"
        fullWidth
        :labelClasses="['hidden']"
        :placeholder="`${t('common.search')}...`"
        :label="t('common.search')"
        :modelValue="searchQuery"
        @update:modelValue="searchQuery = $event"
      />
    </template>
    <GTable
      class="text-sm"
      :data="tableData ?? []"
      :columns="tableDefinition"
      :filter="{
        columns: [{ id: 'courseName', value: searchQuery }],
      }"
      :defaultSort="[
        {
          id: 'progress',
          desc: true,
        },
      ]"
      :selectable="false"
    >
      <template v-slot:thumbnail="cellProps">
        <img
          :src="`/ghcc_media/${cellProps.row.courseImg}`"
          :alt="t('common.thumbnail')"
          class="h-8 min-w-14"
        />
      </template>
      <template v-slot:progress="cellProps">
        <div class="items-center justify-between gap-2">
          <span>{{ `${cellProps.row.progress}%` }}</span>
          <GProgressBar
            variant="primary"
            :progress="cellProps.row.progress"
            class="w-32 flex-1"
          />
        </div>
      </template>
    </GTable>
  </InsightCard>
  <GExportModal
    :isOpen="props.isExportModalOpen"
    fileName="GH - student detailed report"
    :data="exportableReportData"
    @close="emit('updateIsExportModalOpen', false)"
  >
  </GExportModal>
</template>
