<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  OnboardingStep,
  OnboardingWizard,
} from "@/components/Onboarding/OnboardingWizard";
import { GAppLoader, GCard, GMuxPlayer } from "@/components";
import "@mux/mux-player";
import { useRouter } from "vue-router";

import StepContent from "./components/StepContent.vue";
import ResourceCard from "./components/ResourceCard.vue";
import StepHint from "./components/StepHint.vue";
import {
  onboardingSteps,
  trackOnboarding,
  type OnboardingStepObj,
  unknownStep,
} from "./tracking-events";
import {
  fetchDashboardTourSection,
  type DashboardTourSectionResult,
} from "@/sanity-queries";
import { urlFor } from "@/services/sanity";

const activeStep = ref(0);
const muxPlayer = ref<typeof GMuxPlayer>();
const stepsResult = ref<DashboardTourSectionResult | null>(null);

const router = useRouter();

onMounted(async () => {
  stepsResult.value = await fetchDashboardTourSection();
});

const currentStep = computed(() => {
  const step = Object.values(onboardingSteps).find(
    (step) => step.order === activeStep.value + 1
  );
  return step ?? unknownStep;
});

// FIXME: This leads to the app crashing
// watch(currentStep, () => {
//   if (muxPlayer.value?.pause) {
//     muxPlayer.value?.pause();
//   }
// });

const handleGoNext = (step: OnboardingStepObj & { linkText: string }) => {
  const isLastStep =
    stepsResult.value &&
    activeStep.value === stepsResult.value[0].pages.length - 1;

  if (isLastStep) {
    return closeTour();
  }

  activeStep.value++;
  trackOnboarding("onboardingNext", {
    name: step.name,
    order: step.order,
    linkText: step.linkText,
  });
};

const handleGoBack = (stepNumber: number) => {
  trackOnboarding("onboardingBack", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  activeStep.value = stepNumber;
};

const closeTour = () => {
  trackOnboarding("onboardingExit", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  router.push({
    name: "launch",
  });
};
</script>
<template>
  <GAppLoader v-if="!stepsResult?.[0].pages" />
  <OnboardingWizard
    v-else
    :activeStep="activeStep"
    :stepsCount="stepsResult[0].pages.length"
    @goBack="handleGoBack"
    @close="closeTour()"
  >
    <OnboardingStep
      v-for="({ id, startSection, endSection }, index) of stepsResult[0].pages"
      :key="id"
    >
      <StepContent
        :heading="startSection.heading"
        :description="startSection.description"
        :actionLabel="startSection.nextButtonLabel"
        @goNext="
          handleGoNext({
            name: startSection.title.text,
            order: index + 1,
            linkText: startSection.nextButtonLabel,
          })
        "
        class="col-span-2"
      >
        <template #additionalContent>
          <StepHint v-if="startSection.hint" class="mt-3">{{
            startSection.hint
          }}</StepHint>
        </template>
        <template #media>
          <div class="mb-3">
            <p v-if="endSection.title" class="text-lg font-semibold">
              {{ endSection.title }}
            </p>
            <p v-if="endSection.subtitle" class="text-grey-80">
              {{ endSection.subtitle }}
            </p>
          </div>
          <img
            v-if="endSection.content[0]._type === 'image'"
            :src="urlFor(endSection.content[0].image)"
          />
          <GMuxPlayer
            v-else-if="endSection.content[0]._type === 'muxVideo'"
            ref="muxPlayer"
            stream-type="on-demand"
            :playback-id="endSection.content[0].muxVideo.video.playbackId"
          />
          <GCard
            v-else-if="endSection.content[0]._type === 'resourceCardsList'"
            class="grid gap-4 px-8 py-6"
            isElevated
          >
            <p v-if="endSection.content[0].resourceCards.title">
              {{ endSection.content[0].resourceCards.title }}
            </p>
            <ResourceCard
              v-for="resource in endSection.content[0].resourceCards.cards"
              :key="resource.title"
              :image-src="resource.img.url"
              :resource-link="resource.link ?? ''"
              :title="resource.languages?.join(', ') ?? ''"
              :content="resource.title ?? ''"
            />
          </GCard>
        </template>
      </StepContent>
    </OnboardingStep>
  </OnboardingWizard>
</template>
