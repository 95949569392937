import { defineStore } from "pinia";
import { useLDFlag } from "launchdarkly-vue-client-sdk";
import { type Ref } from "vue";

export const entitlementKeyToNameMap = {
  entLicenses: "ent-licenses",
  entGroupActivities: "ent-group-activities",
  entLessonsAssessments: "ent-lessons-assessments",
  entLessonsNoAssessments: "ent-lessons-no-assessments",
  entGoodscanAccess: "ent-goodscan-access",
  entIscanAccess: "ent-iscan-access",
  entOscanAccess: "ent-oscan-access",
  entAssessments: "ent-assessments",
  entCourses: "ent-courses",
  entLessons: "ent-lessons",
  entLearningBite: "ent-learning-bite",
  entReportOrganisation: "ent-report-organisation",
  entReportTeam: "ent-report-team",
  entReportIndividual: "ent-report-individual",
  entReportCourse: "ent-report-course",
  entReportActivities: "ent-report-activities",
  entReportMilestone: "ent-report-milestone",
  entReportAssessment: "ent-report-assessment",
  entReportTeamMembers: "ent-report-team-members",
  entManageOrganisation: "ent-manage-organisation",
  entManageUsers: "ent-manage-users",
  entManageTeam: "ent-manage-team",
  entManageOrganisationLog: "ent-manage-organisation-log",
  entManageRoles: "ent-manage-roles",
  entConnectionMethodLti11: "ent-connection-method-lti11",
  entConnectionMethodLti13: "ent-connection-method-lti13",
  entConnectionMethodRs: "ent-connection-method-rs",
  entConnectionMethodRsMlscorm: "ent-connection-method-rs-mlscorm",
  entConnectionMethodSso: "ent-connection-method-sso",
  entConnectionMethodWeb: "ent-connection-method-web",
  entConnectionMethodMobile: "ent-connection-method-mobile",
  entConnectionSelectionCourses: "ent-connection-selection-courses",
  entConnectionSelectionGoodscan: "ent-connection-selection-goodscan",
  entConnectionSelectionSingleActivities:
    "ent-connection-selection-single-activities",
  entConnectionSelectionAssessments: "ent-connection-selection-assessments",
  entConnectionSelectionLessons: "ent-connection-selection-lessons",
  entRecommendOrganisation: "ent-recommend-organisation",
  entRecommendTeam: "ent-recommend-team",
  entResourcesPromoStudio: "ent-resources-promo-studio",
  entResourcesManualsDocuments: "ent-resources-manuals-documents",
  entResourcesCoursesImagesLeafletsWorkouts:
    "ent-resources-courses-images-leaflets-workouts",
  entResourcesHelpcenter: "ent-resources-helpcenter",
  entSupportDigitalCoach: "ent-support-digital-coach",
  entSupportDedicatedCoach: "ent-support-dedicated-coach",
  entSupportCustomerSupport_24h: "ent-support-customer-support-24h",
  entSupportCustomerSupport_48h: "ent-support-customer-support-48h",
  entLanguageCaEs: "ent-language-ca-es",
  entLanguageDaDk: "ent-language-da-dk",
  entLanguageDeDe: "ent-language-de-de",
  entLanguageDeAt: "ent-language-de-at",
  entLanguageDeCh: "ent-language-de-ch",
  entLanguageEnGb: "ent-language-en-gb",
  entLanguageEnAu: "ent-language-en-au",
  entLanguageEnIn: "ent-language-en-in",
  entLanguageEsEs: "ent-language-es-es",
  entLanguageEsAr: "ent-language-es-ar",
  entLanguageEsMx: "ent-language-es-mx",
  entLanguageFrFr: "ent-language-fr-fr",
  entLanguageFrBe: "ent-language-fr-be",
  entLanguageItIt: "ent-language-it-it",
  entLanguageNlNl: "ent-language-nl-nl",
  entLanguageNlBe: "ent-language-nl-be",
  entLanguagePlPl: "ent-language-pl-pl",
  entLanguagePtPt: "ent-language-pt-pt",
  entLanguagePtBr: "ent-language-pt-br",
  entLanguageSvSe: "ent-language-sv-se",
  entLanguageTrTr: "ent-language-tr-tr",
  entLanguageZhCn: "ent-language-zh-cn",
} as const;

type Entitlements = typeof entitlementKeyToNameMap;
export type EntitlementKey = keyof Entitlements;
export type EntitlementName = Entitlements[EntitlementKey];

export const useEntitlementsStore = defineStore("entitlements", () => {
  const entitlementsFeatureFlag = useLDFlag("entitlements", true);

  const entitlementFlags = {} as Record<EntitlementKey, Ref<boolean>>;
  for (const entitlementKey in entitlementKeyToNameMap) {
    const flagName = entitlementKeyToNameMap[entitlementKey];
    entitlementFlags[entitlementKey] = useLDFlag(flagName, true);
  }

  const checkEntitlementEnabled = (entitlementKey: EntitlementKey) => {
    // If "entitlements" feature flag is disabled, then all entitlements are enabled
    if (entitlementsFeatureFlag.value === false) return true;

    return (
      entitlementsFeatureFlag.value && entitlementFlags[entitlementKey].value
    );
  };

  return {
    checkEntitlementEnabled,
  };
});
