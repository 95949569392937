<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  OnboardingStep,
  OnboardingWizard,
} from "@/components/Onboarding/OnboardingWizard";

import StepContent from "./components/StepContent.vue";
import {
  unknownStep,
  onboardingSteps,
  trackOnboarding,
} from "./tracking-events";
import { GAppLoader, GDropdown, TextInput } from "@/components";
import {
  fetchAboutYouSection,
  type AboutYouSectionResult,
} from "@/sanity-queries";
import RolesStep from "./components/RolesStep.vue";
import AchievementsStep from "./components/AchievementsStep.vue";

const aboutYouSectionResult = ref<AboutYouSectionResult | null>(null);
const { t } = useI18n();
const activeStep = ref(0);
const router = useRouter();
const selectedIndustry = ref("");

const currentStep = computed(() => {
  const dashboardTourStepsCount = 7;
  const step = Object.values(onboardingSteps).find(
    (step) => step.order === activeStep.value + dashboardTourStepsCount + 1,
  );
  return step ?? unknownStep;
});

const stepsCount = computed(() => {
  if (!aboutYouSectionResult.value) {
    return 0;
  }

  const staticPagesCount = 2;
  return aboutYouSectionResult.value[0].pages.length - 1 + staticPagesCount;
});

const achievements = computed(() => {
  if (!aboutYouSectionResult.value) {
    return [];
  }

  return aboutYouSectionResult.value[0].pages.flatMap(
    (page) => page.content[0].questions,
  );
});

onMounted(async () => {
  aboutYouSectionResult.value = await fetchAboutYouSection();
});

const handleGoNext = (linkText: string) => {
  const isLastStep =
    aboutYouSectionResult.value && activeStep.value === stepsCount.value;

  if (isLastStep) {
    return closeTour();
  }

  trackOnboarding("onboardingNext", {
    name: onboardingSteps.invite.name,
    order: onboardingSteps.invite.order,
    linkText,
  });
  activeStep.value++;
};

const handleGoBack = (stepNumber: number) => {
  trackOnboarding("onboardingBack", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  activeStep.value = stepNumber;
};

const closeTour = () => {
  trackOnboarding("onboardingExit", {
    name: currentStep.value.name,
    order: currentStep.value.order,
  });
  router.push({
    name: "launch",
  });
};

const industries = [
  {
    value: "",
    label: "select industry",
  },
  {
    value: "1",
    label: "Accounting",
  },
  {
    value: "2",
    label: "Airlines/Aviation",
  },
  {
    value: "3",
    label: "Alternative Dispute Resolution",
  },
  {
    value: "4",
    label: "Alternative Medicine",
  },
  {
    value: "5",
    label: "Animation",
  },
];

const companyName = ref("");
</script>
<template>
  <GAppLoader v-if="!aboutYouSectionResult?.[0].pages" />
  <OnboardingWizard
    v-else
    :stepsCount="stepsCount"
    :activeStep="activeStep"
    @goBack="handleGoBack"
    @close="closeTour()"
  >
    <OnboardingStep>
      <StepContent
        :heading="
          t('onboarding.about_you.company_info.heading', {
            name: 'Branda',
          })
        "
        :description="t('onboarding.about_you.company_info.description')"
        :actionLabel="t('onboarding.about_you.action_label')"
        @goNext="handleGoNext('Next: Roles')"
        skippable
      >
        <template #additionalContent>
          <div class="grid gap-y-5 pb-4 pt-9">
            <TextInput
              name="Company Name"
              :label="t('onboarding.about_you.company_info.company_name_label')"
              v-model="companyName"
            />
            <TextInput
              name="Company Name"
              :label="t('onboarding.about_you.company_info.location_label')"
              v-model="companyName"
            />
            <GDropdown
              name="Company Name"
              :label="
                t('onboarding.about_you.company_info.industry_type_label')
              "
              :options="industries"
              :value="selectedIndustry"
              @change="selectedIndustry = $event as string"
            >
            </GDropdown>
            <TextInput
              name="Company Name"
              :label="t('onboarding.about_you.company_info.company_size_label')"
              v-model="companyName"
            />
          </div>
        </template>
      </StepContent>
    </OnboardingStep>
    <RolesStep @goNext="handleGoNext('Next')" />
    <AchievementsStep
      :achievements="achievements"
      @goNext="handleGoNext('Next')"
    />
  </OnboardingWizard>
</template>
