<script setup lang="ts">
import { GCard } from "..";
import CloseIcon from "@/assets/images/icons/close.svg";
import InfoIcon from "@/assets/images/icons/info.svg";
import { useToastStore, type Toast } from "@/stores/toasts";

type ToastProps = {
  autohide?: boolean;
  duration?: number;
  toastContent: Toast;
};
const props = withDefaults(defineProps<ToastProps>(), {
  duration: 5000,
  autohide: true,
});

const toastStore = useToastStore();

setTimeout(() => {
  if (props.autohide) {
    toastStore.removeToast(props.toastContent.id);
  }
}, props.duration);
</script>
<template>
  <GCard
    isElevated
    hasBorder
    class="rounded-medium shadow-m my-2 grid max-w-sm grid-cols-[1fr_auto_1fr] items-center gap-y-2 gap-x-12 bg-white py-6"
  >
    <slot name="icon">
      <InfoIcon class="h-5 w-auto text-primary" />
    </slot>
    <span> {{ toastContent.header }}</span>
    <CloseIcon
      class="cursor-pointer justify-self-end"
      @click="toastStore.removeToast(toastContent.id)"
      :title="$t('common.close')"
    />
    <span class="col-start-2 text-grey-70">{{ toastContent.body }}</span>
  </GCard>
</template>
