<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useLocale } from "@/composables/useLocale";
import { GButton, GEmptyState } from "@/components";
import noResultImage from "@/assets/images/no-results-found.svg?url";

const { t } = useI18n();
const { locale } = useLocale();
</script>
<template>
  <div class="my-10 grid place-content-center">
    <GEmptyState
      :title="t('common.no_results_found')"
      :description="t('common.no_results_found_details')"
      :imageUrl="noResultImage"
    >
      <template #actions>
        <GButton :href="`/${locale}/home/redirector`">
          {{ t("common.course_library") }}
        </GButton>
      </template>
    </GEmptyState>
  </div>
</template>
