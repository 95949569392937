<script setup lang="ts">
import { GDropdown } from "@/components";
import { useFetch } from "@/composables";
import type { LanguageCode } from "@/utils/i18n";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

type ModuleListResponse = {
  ModuleId: number;
  ModuleName: string;
}[];

const props = defineProps<{
  language: LanguageCode;
  courseId?: number;
}>();

const { t } = useI18n();
const searchParams = computed(() => {
  if (props.courseId === -1 || !props.courseId) return "";

  const s = new URLSearchParams();
  s.append("languageRegion", props.language);
  s.append("courseId", props.courseId.toString());
  return s.toString();
});

const { data, execute: fetchLessons } = useFetch(
  () => `api/modules?${searchParams.value}`,
  {
    immediate: false,
  },
).json<ModuleListResponse>();

watch(searchParams, (s) => {
  if (s) {
    fetchLessons();
  }
});

const options = computed(() => {
  if (!data.value) return [];

  return data.value.map((activity) => ({
    label: activity.ModuleName,
    value: activity.ModuleId,
  }));
});

const selectedLesson = defineModel<number[] | null[]>({
  default: [null],
  required: true,
});
</script>
<template>
  <GDropdown
    :options="options"
    :value="selectedLesson"
    :label="t('common.select_lesson')"
    searchable
    isMultiChoice
    hasDefault
    @change="selectedLesson = $event as number[]"
  />
</template>
