<script lang="ts" setup>
import type { UseFetchReturn } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useFetch } from "@/composables";
import { GCard, KeyValueBlock } from "@/components";
import { useFlagStore } from "@/stores";
import { useConnection } from "./useConnection";
import ConnectionCardList from "./ConnectionCardList.vue";
import Lti13ConnectionCard from "./Lti13ConnectionCard.vue";

export type Lti13Key = {
  Id: number;
  CompanyId: number;
  Name: string;
  ClientId: string;
  DeploymentId: string;
  PlatformId: string;
  PublicKeySetUrl: string;
  AccessTokenUrl: string;
  AuthenticationRequestUrl: string;
  ContentWeighing: number;
  TestWeighing: number;
};

const { t } = useI18n();
const isDeleteModalOpen = ref(false);
const step1Details = {
  tool_url: `${import.meta.env.VITE_LTI13_CONNECTOR_DOMAIN}/launch/`,
  initiate_login_url: `${import.meta.env.VITE_LTI13_CONNECTOR_DOMAIN}/login/`,
  redirection_uris: `${import.meta.env.VITE_LTI13_CONNECTOR_DOMAIN}/launch/`,
  public_key_set: `${import.meta.env.VITE_LTI13_CONNECTOR_DOMAIN}/jwks/`,
};

const keyListURL = `${import.meta.env.VITE_GH_API}/api/lti13settings/`;

const flagStore = useFlagStore();
const contentSelectionUrl = `${import.meta.env.VITE_LTI13_CONNECTOR_DOMAIN}/deep-linking/`;

const {
  execute: fetchLtiKeyList,
  keyList,
  isFetching,
  listFetchingErrorMessage,
  keyToEdit,
  deleteKey,
  getToastForSave,
} = useConnection<Lti13Key>(keyListURL);

const addNewConnection = () => {
  const newLtiKey = {
    Id: -1,
    CompanyId: 0,
    Name: "",
    ClientId: "",
    DeploymentId: "",
    PlatformId: "",
    PublicKeySetUrl: "",
    AccessTokenUrl: "",
    AuthenticationRequestUrl: "",
    ContentWeighing: 50,
    TestWeighing: 50,
  };
  keyList.value?.unshift(newLtiKey);
  keyToEdit.value = newLtiKey;
};

const cancelEditConnection = async () => {
  keyList.value = keyList.value?.filter((key) => key.Id !== -1) ?? null;
  keyToEdit.value = null;
};

const saveConnection = async (editedKey: Lti13Key) => {
  keyToEdit.value = null;
  let result: UseFetchReturn<Lti13Key[]>;
  if (editedKey.Id === -1)
    result = await useFetch<Lti13Key[]>(keyListURL).post(editedKey).json();
  else
    result = await useFetch(keyListURL + editedKey.Id)
      .put(editedKey)
      .json();
  getToastForSave(result.response.value?.ok, editedKey.Name);
  fetchLtiKeyList();
};

const deleteConnection = async (keyIdToDelete: number) => {
  keyToEdit.value = null;
  if (keyIdToDelete === -1) {
    keyList.value = keyList.value?.filter((key) => key.Id !== -1) ?? null;
  } else {
    await deleteKey(keyIdToDelete);
    fetchLtiKeyList();
  }
  isDeleteModalOpen.value = false;
};
</script>

<template>
  <GCard>
    <h3 class="mb-4 text-3xl">{{ t("connectionMethods.step_1_title") }}</h3>
    <p class="mb-4">{{ t("connectionMethods.step_1_explanation") }}</p>
    <GCard hasBorder class="mb-4" data-testId="configuration-links">
      <KeyValueBlock
        v-for="(value, key) in step1Details"
        :key="key"
        :keyString="t(`connectionMethods.${key}`)"
        :valueString="value"
        keyClasses="min-w-[10rem]"
        copyable
      />
      <KeyValueBlock
        v-if="flagStore.handleLti13InLmsConnectorFlag"
        :keyString="t(`connectionMethods.content_selection_url`)"
        :valueString="contentSelectionUrl"
        keyClasses="min-w-[10rem]"
        copyable
      />
    </GCard>
    <ConnectionCardList
      :canAddNew="!isFetching && !keyToEdit"
      :isDeleteModalOpen="isDeleteModalOpen"
      :isFetchingKeyList="isFetching"
      :errorMessage="listFetchingErrorMessage"
      isWideCard
      @addNew="addNewConnection"
      @closeDeleteModal="isDeleteModalOpen = false"
      @confirmDelete="deleteConnection(keyToEdit?.Id ?? -1)"
    >
      <template #title>{{ t("connectionMethods.step_2_title") }}</template>
      <template #description>
        {{ t("connectionMethods.step_2_explanation") }}
      </template>
      <Lti13ConnectionCard
        v-for="key in keyList"
        :key="key.Id"
        :connectionKey="key"
        :isInEditMode="keyToEdit === key"
        @save="saveConnection"
        @delete="isDeleteModalOpen = true"
        @edit="keyToEdit = key"
        @cancel="cancelEditConnection"
      />
    </ConnectionCardList>
  </GCard>
</template>
