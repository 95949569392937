<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { TabPanel, TabsList, type Tab } from ".";
import GRestrictedTabView from "./GRestrictedTabView.vue";

type TabsProps = {
  tabs: Tab[];
};

const props = defineProps<TabsProps>();
const emit = defineEmits<{
  (event: "change", id: string): void;
}>();

const route = useRoute();

const availableTabs = computed(() => {
  return props.tabs.filter((tab) => !tab.isDisabled && !tab.isHidden);
});

const activeTabId = ref<string | null>(availableTabs.value[0]?.id);

watch(
  () => [route.path, props.tabs],
  () => {
    activeTabId.value =
      availableTabs.value.find((tab) => route.path.includes(tab.id))?.id ??
      null;
  },
  { immediate: true },
);

const handleChangeTab = (value: string) => {
  activeTabId.value = value;
  emit("change", value);
};
</script>
<template>
  <div class="mb-4">
    <TabsList
      :tabs="tabs"
      :activeTabId="activeTabId"
      @change="handleChangeTab($event)"
    />
    <TabPanel
      v-if="availableTabs.length > 0 && activeTabId"
      :id="activeTabId"
      :label="activeTabId"
      :isActive="true"
    >
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </TabPanel>
    <GRestrictedTabView v-else />
  </div>
</template>
