import { createFetch } from "@vueuse/core";
import { getToken } from "@/utils/getToken";
import { GH_ASM_TOKEN_STORAGE_KEY } from "@/constants";

type MakeCreateFetchInput = {
  baseUrl: string;
  token?: string;
  headers?: Record<string, string>;
};

const makeCreateFetch = ({
  baseUrl,
  token = getToken(),
  headers,
}: MakeCreateFetchInput) =>
  createFetch({
    baseUrl,
    options: {
      async beforeFetch({ options }) {
        options.headers = {
          ...options.headers,
          ...headers,
          Authorization: `Bearer ${token}`,
        };

        return { options };
      },
    },
    fetchOptions: {
      mode: "cors",
    },
  });

export const useFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_GH_API,
});
export const useWebCatalogFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_GH_WEB_CATALOG_API,
});
export const useAIServiceFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_GH_AI_SERVICE_API,
});
export const useCourseBffFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_COURSE_BFF_API,
});
export const useOScanFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_O_SCAN_API,
});
export const useReportingServiceFetch = makeCreateFetch({
  baseUrl: import.meta.env.VITE_GH_REPORTING_SERVICE_URL,
  headers: { From: `admin-dashboard-${import.meta.env.MODE}` },
});

// We make it a function to be lazily initialized so we make sure the token is already in the storage
export const createAsmFetch = () =>
  makeCreateFetch({
    baseUrl: import.meta.env.VITE_GH_ASM_API,
    token: localStorage.getItem(GH_ASM_TOKEN_STORAGE_KEY) || "",
  });
