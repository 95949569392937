<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { GCard } from "@/components";

const { t } = useI18n();
</script>
<template>
  <GCard class="w-full">
    <div
      class="mx-auto my-12 flex max-w-xl flex-col items-center justify-center text-sm md:text-base"
    >
      <img
        src="../../assets/images/restricted-access.svg"
        alt=""
        class="mb-4 h-auto w-40 md:mb-14 md:w-60"
      />
      <p class="mb-4 text-xl font-bold text-primary md:text-3xl">
        {{ t("common.restricted_access") }}
      </p>
      <p class="mb-10 text-center">
        {{ t("common.restricted_access_details") }}
      </p>
    </div>
  </GCard>
</template>
