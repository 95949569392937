<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const props = defineProps<{
  countryCode?: string;
}>();

const FlagIcon = defineAsyncComponent(() =>
  props.countryCode
    ? import(`../../assets/images/flags/${props.countryCode}.svg`)
    : import("../../assets/images/no-preview.svg")
);
</script>
<template>
  <component :is="FlagIcon" />
</template>
