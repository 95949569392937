import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useLocale } from "@/composables";
import {
  useAssessmentsStore,
  useFlagStore,
  useWebSessionStore,
} from "@/stores";

import GaugeHighIcon from "@/assets/images/icons/gauge-high.svg";
import AddressBookIcon from "@/assets/images/icons/address-book.svg";
import BookIcon from "@/assets/images/icons/book.svg";
import PlugIcon from "@/assets/images/icons/plug.svg";
import GraduationCapIcon from "@/assets/images/icons/graduation-cap.svg";
import ChartColumn from "@/assets/images/icons/chart-column.svg";
import LightbulbIcon from "@/assets/images/icons/lightbulb.svg";
import RocketLaunchIcon from "@/assets/images/icons/rocket-launch.svg";
import SparklesIcon from "@/assets/images/icons/sparkles.svg";
import {
  type EntitlementKey,
  useEntitlementsStore,
} from "@/stores/entitlements";

type DashMenuItem = {
  id: string;
  name: string;
  menuTreeId: number;
  link?: string;
  icon?: string;
  items?: DashMenuItem[];
  isHidden?: boolean;
  entitlements?: EntitlementKey[];
};

export const useDashboardMenuItems = () => {
  const webSessionStore = useWebSessionStore();
  const isInMenuTree = (item: DashMenuItem) =>
    webSessionStore.webSession?.MenuTree.find(
      (menuItem) => item.menuTreeId === menuItem.Id,
    );
  const asmStore = useAssessmentsStore();
  const { t } = useI18n();
  const { locale } = useLocale();
  const flagStore = useFlagStore();
  const {
    smeOnboardingFlag,
    routeMapFlag,
    learningLabzFlag,
    oScanReportPageFlag,
  } = storeToRefs(flagStore);

  const getDashboardPageUrl = (menuTreeId: number) => {
    const newRoute = routeMapFlag.value[menuTreeId.toString()]?.new;
    const oldRoute = routeMapFlag.value[menuTreeId.toString()]?.old;
    return (
      newRoute ??
      (oldRoute
        ? `/${locale.value}${oldRoute}`
        : `/${locale.value}/dashboard/dashboard.html`)
    );
  };

  const menuItems: ComputedRef<DashMenuItem[]> = computed(() => [
    {
      id: "/dashboard",
      name: t("dashboardMenu.dashboard"),
      menuTreeId: 4305,
      icon: GaugeHighIcon,
    },
    {
      id: "organisation",
      name: t("dashboardMenu.organisation"),
      menuTreeId: 4310,
      icon: AddressBookIcon,
      entitlements: [
        "entManageOrganisation",
        "entManageUsers",
        "entManageRoles",
        "entManageTeam",
        "entManageOrganisationLog",
      ],
      items: [
        {
          id: "organisation/users",
          name: t("dashboardMenu.organisation_users"),
          menuTreeId: 4311,
          entitlements: ["entManageUsers"],
        },
        {
          id: "organisation/teams",
          name: t("dashboardMenu.organisation_groups"),
          menuTreeId: 4312,
          entitlements: ["entManageTeam"],
        },
        {
          id: "organisation/log",
          name: t("dashboardMenu.organisation_log"),
          menuTreeId: 4313,
          entitlements: ["entManageOrganisationLog"],
        },
        {
          id: "organisation/roles",
          name: t("dashboardMenu.organisation_roles"),
          menuTreeId: 4314,
          entitlements: ["entManageRoles"],
        },
        {
          id: "oscan-report",
          name: t("dashboardMenu.oscan"),
          menuTreeId: 4383,
          entitlements: ["entOscanAccess"],
          isHidden: !oScanReportPageFlag.value,
        },
      ],
    },
    {
      id: "reports",
      name: t("dashboardMenu.reports"),
      menuTreeId: 4320,
      icon: ChartColumn,
      entitlements: [
        "entReportTeam",
        "entReportCourse",
        "entReportIndividual",
        "entReportActivities",
        "entReportMilestone",
      ],
      items: [
        {
          id: "reports/students",
          name: t("dashboardMenu.reports_student"),
          menuTreeId: 4321,
          entitlements: ["entReportIndividual"],
        },
        {
          id: "reports/courses",
          name: t("dashboardMenu.reports_course"),
          menuTreeId: 4322,
          entitlements: ["entReportCourse"],
        },
        {
          id: "reports/detailed-users",
          name: t("dashboardMenu.reports_log"),
          menuTreeId: 4323,
          entitlements: ["entReportActivities"],
        },
        {
          id: "reports/course-milestones",
          name: t("dashboardMenu.reports_course_milestones"),
          menuTreeId: 4324,
          entitlements: ["entReportMilestone"],
        },
        {
          id: "reports/team-member",
          name: t("dashboardMenu.reports_group_member"),
          menuTreeId: 4325,
          entitlements: ["entReportTeam"],
        },
      ],
    },
    {
      id: "connect",
      name: t("dashboardMenu.connect"),
      icon: PlugIcon,
      menuTreeId: 4330,
      entitlements: [
        "entConnectionMethodLti11",
        "entConnectionMethodLti13",
        "entConnectionMethodRs",
        "entConnectionMethodRsMlscorm",
        "entConnectionSelectionAssessments",
        "entConnectionSelectionCourses",
        "entConnectionSelectionGoodscan",
        "entConnectionSelectionLessons",
        "entConnectionSelectionSingleActivities",
      ],
      items: [
        {
          id: "connect-methods",
          name: t("connectionMethods.title"),
          menuTreeId: 4331,
          entitlements: [
            "entConnectionMethodLti11",
            "entConnectionMethodLti13",
            "entConnectionMethodRs",
            "entConnectionMethodRsMlscorm",
          ],
        },
        {
          id: "content-selection",
          name: t("contentSelection.title"),
          menuTreeId: 4333,
          entitlements: [
            "entConnectionSelectionAssessments",
            "entConnectionSelectionCourses",
            "entConnectionSelectionGoodscan",
            "entConnectionSelectionLessons",
            "entConnectionSelectionSingleActivities",
          ],
        },
      ],
    },
    {
      id: "recommend",
      name: t("dashboardMenu.recommend"),
      icon: GraduationCapIcon,
      menuTreeId: 4340,
      entitlements: ["entRecommendOrganisation", "entRecommendTeam"],
      items: [
        {
          id: "recommend-courses-organisation",
          name: t("dashboardMenu.recommend_courses_organisation"),
          menuTreeId: 4341,
          entitlements: ["entRecommendOrganisation"],
        },
        {
          id: "recommend-courses-groups",
          name: t("dashboardMenu.recommend_courses_groups"),
          menuTreeId: 4342,
          entitlements: ["entRecommendTeam"],
        },
      ],
    },
    {
      id: "resources",
      name: t("dashboardMenu.resources"),
      icon: BookIcon,
      menuTreeId: 4350,
      entitlements: [
        "entResourcesPromoStudio",
        "entResourcesManualsDocuments",
        "entResourcesCoursesImagesLeafletsWorkouts",
        "entResourcesHelpcenter",
      ],
      items: [
        {
          id: "resources-manuals-documentation",
          name: t("dashboardMenu.resources_manuals_documentation"),
          menuTreeId: 4351,
          entitlements: ["entResourcesManualsDocuments"],
        },
        {
          id: "resources-manuals-icons-leaflets",
          name: t("dashboardMenu.resources_icons_leaflets"),
          menuTreeId: 4352,
          entitlements: ["entResourcesCoursesImagesLeafletsWorkouts"],
        },
        {
          id: "resources-manuals-promo-studio",
          name: t("dashboardMenu.resources_promo_studio"),
          menuTreeId: 4353,
          entitlements: ["entResourcesPromoStudio"],
        },
      ],
    },
    {
      id: "ai-config",
      name: t("dashboardMenu.ai"),
      icon: SparklesIcon,
      menuTreeId: 4380,
      isHidden: !learningLabzFlag.value,
      items: [
        {
          id: "organisation-settings",
          name: t("dashboardMenu.ai_organisation_settings"),
          menuTreeId: 4381,
        },
      ],
    },
    {
      id: "/admin-dashboard/assessments",
      name: t("navigation.assessments"),
      icon: LightbulbIcon,
      menuTreeId: 4360,
      isHidden: !asmStore.isAssessmentsUser,
      entitlements: ["entReportAssessment"],
    },
    {
      id: "/admin-dashboard/launch",
      name: t("dashboardMenu.launch"),
      icon: RocketLaunchIcon,
      menuTreeId: 4370,
      isHidden: !smeOnboardingFlag.value,
    },
  ]);

  const filteredMenuItemsByEntitlements = computed(() => {
    const { checkEntitlementEnabled } = useEntitlementsStore();

    const filterItemsWithEntitlements = (items: DashMenuItem[]) =>
      items.filter((item) => {
        const hasEntitlements = item.entitlements?.length > 0;
        const isEntitled = hasEntitlements
          ? item.entitlements.some(checkEntitlementEnabled)
          : true;

        if (item.items && item.items.length > 0) {
          item.items = filterItemsWithEntitlements(item.items);
        }
        return isEntitled;
      });

    return filterItemsWithEntitlements(menuItems.value);
  });

  const menuItemsFilteredByUserAccess = computed(() => {
    const itemsWithUserAccess: DashMenuItem[] = [];
    filteredMenuItemsByEntitlements.value.forEach((item) => {
      if (!isInMenuTree(item) || item.isHidden === true) null;
      else if (!item.items) {
        item.link = getDashboardPageUrl(item.menuTreeId);
        itemsWithUserAccess.push(item);
      } else {
        const subItemsWithUserAccessAndLinks: DashMenuItem[] = [];
        item.items.forEach((subItem) => {
          if (isInMenuTree(subItem) && subItem.isHidden !== true) {
            subItem.link = getDashboardPageUrl(subItem.menuTreeId);
            subItemsWithUserAccessAndLinks.push(subItem);
          }
        });
        const nestedItem = { ...item, items: subItemsWithUserAccessAndLinks };
        itemsWithUserAccess.push(nestedItem);
      }
    });
    return itemsWithUserAccess;
  });

  return menuItemsFilteredByUserAccess;
};
