import { add } from "date-fns";
export function setCookie({
  name,
  value,
  expiryInDays,
}: {
  name: string;
  value: string;
  expiryInDays: number;
}) {
  const expiryInMilliseconds = add(new Date(), {
    days: expiryInDays,
  }).getMilliseconds();

  const newCookieValue =
    name === "gdpr_analytics" ? value : encodeURIComponent(value);
  const domain =
    window.location.hostname === "localhost" ? "localhost" : ".goodhabitz.com";

  document.cookie = `${name}=${newCookieValue}; expires=${expiryInMilliseconds}; path=/; domain=${domain}; SameSite=None; secure`;
}

function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((c) => c.trim().startsWith(`${name}=`));
  if (cookie) {
    return cookie.split(`${name}=`)[1];
  }
  return "";
}

const searchParams = new URLSearchParams(window.location.search);

export function saveGDPRCookie(analyticalCookiesStatus: boolean) {
  const cookieData = {
    AcceptDate: Date.now(),
    FC: 1,
    AC: analyticalCookiesStatus ? 1 : 0,
    CC: 1,
    SC: 0,
  };

  setCookie({
    name: "gdpr_analytics",
    value: JSON.stringify(cookieData),
    expiryInDays: 365,
  });

  searchParams.set("gdpr", cookieData.AC.toString());

  window.location.search = searchParams.toString();
}

export function isCookieConsentNeeded() {
  const gdprParam = searchParams.get("gdpr");

  const gdprCookie = getCookie("gdpr_analytics");

  return !gdprCookie && gdprParam !== "1";
}

export function isAnalyticsEnabled() {
  const gdprAnalyticsCookie = getCookie("gdpr_analytics");
  if (gdprAnalyticsCookie) {
    try {
      const { AC } = JSON.parse(gdprAnalyticsCookie) || { AC: 0 };
      return AC === 1;
    } catch (error) {
      return false;
    }
  }
  return false;
}
