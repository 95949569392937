<script setup lang="ts">
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

import { PageLayout, GTabs, type Tab } from "@/components";
import { useLocale } from "@/composables";
import { useEntitlementsStore, useFlagStore, useUserStore } from "@/stores";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const { locale } = useLocale();

const { checkEntitlementEnabled } = useEntitlementsStore();
const { isAuthorizedForRoute } = useUserStore();
const flagStore = useFlagStore();
const { lti13ConfigPageFlag } = storeToRefs(flagStore);

const tabs: ComputedRef<Tab[]> = computed(() => {
  return [
    {
      label: "LTI 1.1",
      id: "lti11",
      href: "lti11",
      isHidden: !(
        checkEntitlementEnabled("entConnectionMethodLti11") &&
        isAuthorizedForRoute("lti11")
      ),
    },
    {
      label: "LTI 1.3",
      id: "lti13",
      href: "lti13",
      isHidden:
        lti13ConfigPageFlag.value === false ||
        !(
          checkEntitlementEnabled("entConnectionMethodLti13") &&
          isAuthorizedForRoute("lti13")
        ),
      hasBetaBadge: true,
    },
    {
      label: "Remote SCORM",
      id: "scorm",
      href: "scorm",
      isHidden: !(
        checkEntitlementEnabled("entConnectionMethodRs") &&
        isAuthorizedForRoute("scorm")
      ),
    },
  ];
});
</script>
<template>
  <PageLayout
    :title="t('connectionMethods.title')"
    :heading="t('connectionMethods.title')"
  >
    <template #subHeading>
      {{ t("connectionMethods.sub_heading") + " " }}
      <i18n-t
        keypath="connectionMethods.refer_manual"
        tag="span"
        for="connectionMethods.refer_manual_link"
      >
        <a
          :href="`https://my.goodhabitz.com/${locale}/dashboard/resources-manuals-documentation.html`"
          target="_blank"
          class="link text-primary"
          >{{ $t("connectionMethods.refer_manual_link") }}</a
        >
      </i18n-t>
    </template>
    <div class="py-4 md:py-12">
      <GTabs :tabs="tabs" />
    </div>
  </PageLayout>
</template>
