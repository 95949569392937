import {
  breakpointsTailwind,
  useBreakpoints as vueUseBreakpoints,
} from "@vueuse/core";

export const useBreakpoints = (
  { includeSmallerBreakpoints } = { includeSmallerBreakpoints: true },
) => {
  const breakpoints = vueUseBreakpoints(breakpointsTailwind);

  const exactBreakpoints = {
    isSmall: breakpoints.smaller("sm"),
    isMedium: breakpoints.between("sm", "md"),
    isLarge: breakpoints.between("md", "lg"),
    isXLarge: breakpoints.between("lg", "xl"),
    isXXLarge: breakpoints.between("xl", "2xl"),
  };

  const breakPointsAndSmaller = {
    isSmall: breakpoints.smaller("md"),
    isMedium: breakpoints.smaller("lg"),
    isLarge: breakpoints.smaller("xl"),
    isXLarge: breakpoints.smaller("2xl"),
  };

  return includeSmallerBreakpoints ? breakPointsAndSmaller : exactBreakpoints;
};
