import { COURSE_PAGE_URL_STORAGE_KEY } from "@/constants";
import { getLocaleFromUrl } from "./i18n";

export const redirectToLogin = () => {
  const loginUrl = `/${getLocaleFromUrl()}/logoff.html`;

  sessionStorage.setItem(COURSE_PAGE_URL_STORAGE_KEY, window.location.href);
  window.location.href = loginUrl;
};

export const redirectToDashboardHomePage = () => {
  const dashboardUrl = `/${getLocaleFromUrl()}/dashboard/dashboard.html`;
  window.location.href = dashboardUrl;
};
