<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

import ChevronDownIcon from "@/assets/images/icons/chevron-down.svg";

const props = withDefaults(
  defineProps<{
    subtitle: string;
    title: string;
    isOpen?: boolean;
    hasOpenIndicator?: boolean;
    alwaysOpen?: boolean;
  }>(),
  {
    isOpen: false,
    hasOpenIndicator: true,
  }
);
</script>
<template>
  <Disclosure
    as="div"
    v-slot="{ open }"
    :defaultOpen="isOpen"
    class="overflow-hidden rounded-lg shadow-lg"
  >
    <DisclosureButton
      class="flex w-full justify-between bg-primary px-8 pt-3.5 pb-5 text-white"
      :class="{ 'cursor-default': props.alwaysOpen }"
    >
      <div class="text-start">
        <p class="text-lg font-semibold">{{ props.title }}</p>
        <p class="text-sm">{{ props.subtitle }}</p>
      </div>
      <div class="flex items-center gap-3 self-center">
        <slot name="headerStatus" :isOpen="open"></slot>
        <ChevronDownIcon
          class=""
          :class="{ 'rotate-180 fill-white text-white': open }"
          v-if="props.hasOpenIndicator"
        />
      </div>
    </DisclosureButton>
    <Transition
      enter-from-class="grid-rows-[0fr]"
      enter-active-class="transition-[grid-template-rows] grid duration-300 ease-in-out"
      enter-to-class="grid-rows-[1fr]"
      leave-active-class="transition-[grid-template-rows] duration-200 grid ease-in-out"
      leave-from-class="grid-rows-[1fr]"
      leave-to-class="grid-rows-[0fr]"
    >
      <DisclosurePanel class="rounded-b-lg bg-white" :static="props.alwaysOpen">
        <div class="overflow-hidden">
          <slot></slot>
        </div>
      </DisclosurePanel>
    </Transition>
  </Disclosure>
</template>
