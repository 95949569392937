<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getTime, sub, startOfDay, endOfDay } from "date-fns";

import { isDateTuple } from "@/utils/typeGuards";
import { GDatePicker } from "@/components";
import { PresetId } from "./useDatePicker";

const { t } = useI18n();

const emit = defineEmits<{ (event: "change", value: number[]): void }>();

const endOfToday = endOfDay(new Date(Date.now()));
const startDate = ref(startOfDay(sub(endOfToday, { days: 6 })));
const endDate = ref(endOfToday);

const startDateTimestamp = computed<number>(() => getTime(startDate.value));
const endDateTimestamp = computed<number>(() => getTime(endDate.value));

const setDateInterval = (
  value: Date | [startDate: Date, endDate: Date] | null
) => {
  if (isDateTuple(value)) {
    startDate.value = startOfDay(value[0]);
    endDate.value = endOfDay(value[1]);
    emit("change", [startDateTimestamp.value, endDateTimestamp.value]);
  }
};

emit("change", [startDateTimestamp.value, endDateTimestamp.value]);
</script>
<template>
  <GDatePicker
    :label="t('common.choose_date_interval')"
    :modelValue="[startDate, endDate]"
    @update:modelValue="setDateInterval"
    :presetRanges="[
      PresetId.LAST_12_MONTHS,
      PresetId.LAST_30_DAYS,
      PresetId.LAST_7_DAYS,
    ]"
    withButtonPresets
    withCustomRangeLabel
    :noToday="false"
  ></GDatePicker>
</template>
