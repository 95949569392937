<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { GCard } from "@/components";
import { useFetch } from "@/composables";
import ConnectionCardList from "./ConnectionCardList.vue";
import { useConnection } from "./useConnection";
import { ref } from "vue";
import ScormConnectionCard from "./ScormConnectionCard.vue";

export type ScormKey = {
  Id: number;
  Domain: string;
  ContentWeighing: number;
  TestWeighing: number;
  CompanyId: number;
};
const { t } = useI18n();
const isDeleteModalOpen = ref(false);

const keyListURL = `${import.meta.env.VITE_GH_API}/api/RemoteScormKey/`;

const {
  execute: fetchScormKeys,
  keyList,
  isFetching,
  listFetchingErrorMessage,
  keyToEdit,
  deleteKey,
  getToastForSave,
  getToastForAdd,
} = useConnection<ScormKey>(keyListURL);

const addNewConnection = async () => {
  const result = await useFetch(keyListURL).post({}).json();
  getToastForAdd(result.response.value?.ok);
  fetchScormKeys();
};

const saveConnection = async (editedKey: ScormKey) => {
  keyToEdit.value = null;
  const result = await useFetch(keyListURL)
    .put({
      ...editedKey,
      ContentWeighing: 100 - editedKey.TestWeighing,
    })
    .json();
  getToastForSave(result.response.value?.ok, editedKey.Domain);
  fetchScormKeys();
};
const cancelEditConnection = async () => {
  keyToEdit.value?.Id == -1 && keyList.value?.pop();
  keyToEdit.value = null;
};

const deleteConnection = async () => {
  await deleteKey(keyToEdit.value?.Id ?? -1);
  isDeleteModalOpen.value = false;
};
</script>
<template>
  <GCard>
    <ConnectionCardList
      :canAddNew="!isFetching && !keyToEdit"
      :isDeleteModalOpen="isDeleteModalOpen"
      :isFetchingKeyList="isFetching"
      :errorMessage="listFetchingErrorMessage"
      @addNew="addNewConnection"
      @closeDeleteModal="isDeleteModalOpen = false"
      @confirmDelete="deleteConnection()"
    >
      <template #description>
        {{ t("connectionMethods.scorm_description") }}
      </template>
      <ScormConnectionCard
        v-for="key in keyList"
        :key="key.Id"
        :connectionKey="key"
        :isInEditMode="keyToEdit?.Id === key.Id"
        @save="saveConnection"
        @delete="isDeleteModalOpen = true"
        @edit="keyToEdit = key"
        @cancel="cancelEditConnection"
      />
    </ConnectionCardList>
  </GCard>
</template>
