<script lang="ts" setup>
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";

import ConnectionCard from "./ConnectionCard.vue";
import type { Lti11Key } from "./Lti11Tab.vue";
import { useI18n } from "vue-i18n";
import { z } from "zod";
import { TextInput, GRange } from "@/components";

const { t } = useI18n();
const schemaDefinition = z.object({
  Name: z.string().min(1, t("validationMessages.required")),
  KeyValue: z.string().min(1, t("validationMessages.required")),
  SecretValue: z.string().min(1, t("validationMessages.required")),
  TestWeighing: z.number().min(0).max(100),
});

type SchemaDefinition = z.infer<typeof schemaDefinition>;

const props = defineProps<{
  connectionKey: Lti11Key;
  isInEditMode: boolean;
}>();

const emit = defineEmits<{
  (e: "save", value: Lti11Key): void;
  (e: "delete", value: number): void;
  (e: "edit", key: Lti11Key): void;
  (e: "cancel"): void;
}>();

const validationSchema = toTypedSchema(schemaDefinition);

const { handleSubmit, isSubmitting, setErrors, values, setFieldValue } =
  useForm<SchemaDefinition>({
    validationSchema: validationSchema,
    initialValues: props.connectionKey,
  });

const handleAddLti11Key = handleSubmit(
  (data) => {
    emit("save", {
      ...props.connectionKey,
      ...data,
    });
  },
  ({ errors }) => {
    setErrors(errors);
  }
);

const LTI11Fields: ReadonlyArray<keyof SchemaDefinition> = [
  "Name",
  "KeyValue",
  "SecretValue",
  "TestWeighing",
];

const fieldLabels: Record<keyof SchemaDefinition, string> = {
  Name: t("connectionMethods.name"),
  KeyValue: t("connectionMethods.key"),
  SecretValue: t("connectionMethods.secret"),
  TestWeighing: t("connectionMethods.weight"),
};
</script>
<template>
  <ConnectionCard
    :title="connectionKey.Name"
    :isInEditMode="props.isInEditMode"
    :isSubmitting="isSubmitting"
    @edit="emit('edit', connectionKey)"
    @cancel="emit('cancel')"
    @delete="emit('delete', connectionKey.Id)"
    @save="handleAddLti11Key"
  >
    <TextInput
      v-for="fieldName in LTI11Fields.filter((f) => f !== 'TestWeighing')"
      :key="fieldName"
      :name="fieldName"
      :readonly="!props.isInEditMode"
      :label="fieldLabels[fieldName]"
      :modelValue="values[fieldName]"
      @update:modelValue="setFieldValue(fieldName, $event)"
      containerClasses="w-full"
    />
    <GRange
      :id="`${props.connectionKey.Id}-range`"
      :label="fieldLabels.TestWeighing"
      :weight-labels="[
        t('connectionMethods.test'),
        t('connectionMethods.lessons'),
      ]"
      class="w-full"
      :modelValue="values['TestWeighing']"
      @update:modelValue="setFieldValue('TestWeighing', $event)"
      :disabled="!props.isInEditMode"
    />
  </ConnectionCard>
</template>
