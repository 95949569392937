<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { PageLayout } from "@/components";

const { t } = useI18n();
</script>
<template>
  <PageLayout
    :title="t('contentSelection.title')"
    :heading="t('contentSelection.title')"
  >
  </PageLayout>
</template>
