export const GH_TOKEN_STORAGE_KEY = "token";
export const GH_ASM_TOKEN_STORAGE_KEY = "asmToken";
export const WEB_SESSION_STORAGE_KEY = "websession";
export const COURSE_PAGE_URL_STORAGE_KEY = "CoursePageUrl";
export const IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY = "isOptingInNewDashboard";
export const IS_FIRST_VISIT_TO_CONNECT_PAGES_STORAGE_KEY =
  "isFirstVisitToConnectPages";
export const SME_ONBOARDING_STATE_STORAGE_KEY = "smeOnboardingState";
export const GH_COMPANY_ID = 999999;
export const PAGE_TOGGLE_STATE_MAP_STORAGE_KEY = "pageToggleStateMap";
