<script setup lang="ts">
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useUiStore } from "@/stores/ui";
import { useDashboardMenuItems } from "./useDashboardMenuItems";
import { AppLink } from "@/components";

import ChevronDownIcon from "@/assets/images/icons/chevron-down.svg";
import { useI18n } from "vue-i18n";

const openMenuItemIds = ref<string[]>([]);
const uiStore = useUiStore();
const { isMenuExpanded, isMenuCollapsible } = storeToRefs(uiStore);
const dashboardNavRef = ref();

const route = useRoute();
const { t } = useI18n();

const toggleOpenItem = (itemId: string) => {
  const idIndex = openMenuItemIds.value.findIndex((id) => id === itemId);
  idIndex > -1
    ? openMenuItemIds.value.splice(idIndex, 1)
    : openMenuItemIds.value.push(itemId);
};

const menuItems = useDashboardMenuItems();

watch(isMenuExpanded, () => {
  if (isMenuCollapsible.value && !isMenuExpanded.value) {
    const currentMenuItem = menuItems.value.find((item) =>
      item.items?.some((subItem) => route.path.includes(subItem.id)),
    );
    if (currentMenuItem) openMenuItemIds.value = [currentMenuItem.id];
  }
});

const onMenuLeave = () => {
  if (isMenuCollapsible.value) uiStore.toggleDashboardMenu(false);
};
</script>
<template>
  <nav
    class="dashboard-menu sticky top-0 max-h-screen overflow-y-hidden bg-white will-change-transform lg:h-full lg:overflow-y-clip"
    :aria-expanded="isMenuCollapsible ? isMenuExpanded : true"
    :aria-label="t('common.dashboard_menu')"
    ref="dashboardNavRef"
  >
    <menu class="flex h-[calc(100%-3.5rem)] w-full flex-col gap-2 p-3 lg:w-60">
      <template v-for="item in menuItems" :key="item.id">
        <!-- main item - link -->
        <li v-if="!item.items" @click="onMenuLeave">
          <AppLink
            :href="item.link"
            class="dashboard-menu-item grid-cols-[auto_1fr] gap-2"
            :class="[route.path.includes(item.id) && 'bg-blue-10 text-primary']"
            :aria-label="item.name"
            role="menuitem"
          >
            <component v-bind:is="item.icon" class="h-7 w-7"></component>

            <span class="w-full overflow-hidden">
              {{ item.name }}
            </span>
          </AppLink>
        </li>
        <!-- main item - category title -->
        <li
          class="grid w-full transition-[grid-template-rows] duration-200"
          :class="
            openMenuItemIds.includes(item.id)
              ? 'grid-rows-[auto_1fr] delay-150'
              : 'grid-rows-[auto_0fr]'
          "
          role="group"
          v-else
        >
          <button
            class="dashboard-menu-item group grid-cols-[auto_1fr_auto] gap-2"
            :class="[
              openMenuItemIds.includes(item.id) && 'text-primary',
              item.items.some((item) => route.path.includes(item.id)) &&
                'bg-blue-10 text-primary',
            ]"
            @click="toggleOpenItem(item.id)"
            :id="item.name"
            :aria-label="item.name"
            :aria-expanded="openMenuItemIds.includes(item.id)"
            role="menuitem"
          >
            <component v-bind:is="item.icon" class="h-7 w-7"></component>
            <div
              class="flex w-full items-center justify-between overflow-hidden"
            >
              <span>
                {{ item.name }}
              </span>
              <ChevronDownIcon
                class="ml-2 h-3 w-3 overflow-hidden group-hover:text-primary"
                :class="[
                  openMenuItemIds.includes(item.id)
                    ? 'rotate-180 text-primary'
                    : 'text-gray-400',
                ]"
              />
            </div>
          </button>
          <!-- sub-items -->
          <menu
            class="ml-5 overflow-hidden border-l-2 border-gray-200 pl-4"
            @click="onMenuLeave"
          >
            <AppLink
              :href="subItem.link"
              v-for="subItem in item.items"
              :key="subItem.id"
              class="dashboard-menu-item text-base font-normal"
              :class="[route.path.includes(subItem.id) && 'text-primary']"
              role="menuitem"
            >
              {{ subItem.name }}
            </AppLink>
          </menu>
        </li>
      </template>
    </menu>
  </nav>
</template>
<style>
.dashboard-menu-item {
  @apply grid w-full cursor-pointer items-center justify-items-start rounded-lg p-2 text-start font-medium transition-[grid-template-cols] duration-300 hover:text-primary;
}
</style>
