<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    disabled?: boolean;
    labelPlacement?: "start" | "end" | "top" | "bottom";
  }>(),
  {
    labelPlacement: "start",
  },
);

defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();

const stateStyles = {
  default: {
    checked:
      "peer-checked:border-blue peer-checked:bg-blue peer-checked:after:bg-white",
    notChecked: "border-grey-30 bg-white after:bg-blue",
  },
  hover: {
    checked:
      "peer-checked:hover:border-blue-120 peer-checked:hover:bg-blue-120",
    notChecked: "hover:after:bg-blue-120 hover:border-blue",
  },
  focus: {
    checked:
      "peer-checked:peer-focus:after:bg-white peer-checked:peer-focus:bg-blue-80 peer-checked:peer-focus:border-blue-80",
    notChecked: "peer-focus:border-blue-80 peer-focus:after:bg-blue-80",
  },
  disabled: {
    checked:
      "peer-checked:peer-disabled:after:bg-white peer-checked:peer-disabled:bg-grey-10 peer-checked:peer-disabled:border-grey-10 peer-checked:peer-disabled:hover:border-grey-10",
    notChecked:
      "peer-disabled:cursor-not-allowed peer-disabled:bg-grey-5 peer-disabled:border-grey-10 peer-disabled:after:bg-grey-10",
  },
};
</script>
<template>
  <label
    class="flex w-fit cursor-pointer"
    :class="{
      'items-center':
        props.labelPlacement === 'start' || props.labelPlacement === 'end',
      'flex-row-reverse': props.labelPlacement === 'end',
      'flex-col items-start': props.labelPlacement === 'top',
      'flex-col-reverse items-start': props.labelPlacement === 'bottom',
    }"
  >
    <span
      class="relative"
      :class="{
        'mr-2': props.labelPlacement === 'start',
        'ml-2': props.labelPlacement === 'end',
        'mb-2': props.labelPlacement === 'top',
        'mt-2': props.labelPlacement === 'bottom',
      }"
    >
      <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
      <slot name="label">
        GToggle requires a label slot. You can hide the label by adding .sr-only
        to the slot.</slot
      >
    </span>
    <div class="relative inline-flex items-center">
      <input
        type="checkbox"
        role="switch"
        :checked="props.modelValue"
        class="peer sr-only"
        :disabled="props.disabled"
        @change="
          $emit(
            'update:modelValue',
            ($event.target as HTMLInputElement).checked,
          )
        "
      />
      <div
        :class="`
        peer h-6 w-12 rounded-full border-2 after:absolute after:left-1.5 after:top-1.5 after:h-3 after:w-3 after:rounded-full after:transition-all after:content-[''] peer-checked:after:translate-x-6  
        ${stateStyles.hover.notChecked} 
        ${stateStyles.hover.checked} 
        ${stateStyles.focus.notChecked} 
        ${stateStyles.focus.checked} 
        ${stateStyles.disabled.notChecked} 
        ${stateStyles.disabled.checked} 
        ${stateStyles.default.notChecked} 
        ${stateStyles.default.checked}`"
      ></div>
    </div>
  </label>
</template>
