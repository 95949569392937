<script setup lang="ts">
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

import { GTabs, PageLayout, type Tab } from "@/components";

const { t } = useI18n();

const contentTabs: ComputedRef<Tab[]> = computed(() => {
  return [
    {
      label: t("dashboardMenu.resources_manuals_documentation"),
      id: "manuals-docs",
      href: "manuals-docs",
    },
    {
      label: t("dashboardMenu.resources_icons_leaflets"),
      id: "icons-leaflets",
      href: "icons-leaflets",
    },
    {
      label: t("dashboardMenu.resources_promo_studio"),
      id: "promo-studio",
      href: "promo-studio",
    },
  ];
});
</script>
<template>
  <PageLayout
    :title="t('dashboardMenu.resources')"
    :heading="t('dashboardMenu.resources')"
  >
    <GTabs :tabs="contentTabs" />
  </PageLayout>
</template>
